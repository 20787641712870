import React, { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { KiteAlert, KiteDialog, KiteMenu, KiteMenuItem, KiteTooltip } from '@kite/react-kite'
import {
  formatDateWithCustomFormat,
  formatDateWithTimeNoSecs,
  subDaysFromDate,
} from '../../utils/utils'
import CustomToast from '../../components/toast/CustomToast'
import CustomTable, { Spacing } from '../../components/table/Table'
import type { AppDispatch, RootState } from '../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchData,
  setError,
  updateFilters,
  updatePagination,
  updateSort,
} from '../../redux/AuditLog/AuditLogSlice'
import { PATHS } from '../../routes/Routes'
import AuditLogDetailsModel from './AuditLogDetailsModel/AuditLogDetailsModel'

interface ITableProps {
  spacing: Spacing
}

const AuditLogTable = ({ spacing = Spacing.Default }: ITableProps) => {
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const history = useNavigate()
  const isInitialMount = useRef(true)
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [selectedAuditItem, setSelectedAuditItem] = React.useState<any>({})
  const { filtersData } = useSelector((state: RootState) => state.filtersData)

  const {
    auditLogItems: { AuditLogList = [], MaxCount = 0 },
    error,
    status,
    tableFilters,
    tablePagination,
    tablePagination: { PageNumber, RecordsPerPage },
    tableSort,
  } = useSelector((state: RootState) => state.auditLogData)

  const modifiedAuditLogItems = (AuditLogList || []).map((auditLogItem) => {
    const msmInstanceData = (filtersData?.MSMInstance || []).find(
      (msmInstanceData) => msmInstanceData.IndexValue === auditLogItem?.MsmInstanceId,
    )
    const timeZoneData = (filtersData?.AuditTimeZones || []).find(
      (timeZoneData) => timeZoneData.IndexValue === auditLogItem?.TimeZoneID,
    )
    return {
      ...auditLogItem,
      MsmInstanceId: msmInstanceData?.Value || auditLogItem.MsmInstanceId,
      TimeZoneID: timeZoneData?.Value || auditLogItem.TimeZoneID,
    }
  })

  const columns = [
    {
      label: 'Start Time',
      sortKey: 'StartTime',
      accessorKey: 'StartTime',
      width: '100px',
      render: (row: any) => <div>{formatDateWithTimeNoSecs(row?.StartTime)}</div>,
    },
    {
      label: 'End Time',
      sortKey: 'EndTime',
      accessorKey: 'EndTime',
      width: '100px',
      render: (row: any) => <div>{formatDateWithTimeNoSecs(row?.EndTime)}</div>,
    },
    {
      label: 'Status',
      sortKey: 'Status',
      accessorKey: 'Status',
      width: '80px',
      render: (row: any) => <div className={'cell-status ' + row?.Status}>{row?.Status}</div>,
    },
    {
      label: 'TimeZone',
      sortKey: 'TimeZoneID',
      accessorKey: 'TimeZoneID',
      width: '150px',
    },
    {
      label: 'Network',
      sortKey: '',
      accessorKey: 'NetCodes',
      width: '100px',
      render: (row: any) => (
        <div className='cell-link'>
          <KiteTooltip text={row?.NetCodes} direction='bottom' type='icon' id='cellPopOver'>
            <span className='link-text'>{row?.NetCodeCount} Selected</span>
          </KiteTooltip>
        </div>
      ),
    },
    {
      label: 'Zone',
      sortKey: '',
      accessorKey: 'ZoneCodes',
      width: '100px',
    },
    {
      label: 'Data Center',
      sortKey: 'MsmInstanceId',
      accessorKey: 'MsmInstanceId',
      width: '100px',
    },
    {
      label: 'User',
      sortKey: 'Pid',
      accessorKey: 'User',
      width: '100px',
    },
    {
      label: 'Actions',
      sortKey: 'actions',
      fixed: 'right',
      accessorKey: 'actions',
      width: '20px',
      render: (row: any) => {
        const handleEditDetails = () => {
          history(PATHS.EDIT_AUDIT_LOG + '/' + row?.AuditLogId)
        }
        const handleDeleteDetails = () => {
          history(PATHS.DELETE_AUDIT_LOG + '/' + row?.AuditLogId)
        }
        const handleViewDetails = () => {
          setSelectedAuditItem(row)
          setDialogStatus(true)
        }
        return (
          <KiteMenu variant='flow'>
            <KiteMenuItem onSelect={handleViewDetails}>
              <span className='flex-center'>View Details</span>
            </KiteMenuItem>
            <KiteMenuItem onSelect={handleEditDetails}>
              <span className='flex-center'>Edit Details</span>
            </KiteMenuItem>
            <KiteMenuItem onSelect={handleDeleteDetails}>
              <span className='flex-center'>Delete</span>
            </KiteMenuItem>
          </KiteMenu>
        )
      },
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    dispatch(updateSort({ SortBy: column, SortOrder: newSortDirection }))
  }

  useEffect(() => {
    // Default values
    const currentFiltersData: { [x: string]: any } = { ...tableFilters }
    const currentSortData: { [x: string]: any } = { ...tableSort }
    const currentPaginationData: { [x: string]: any } = { ...tablePagination }
    // Values From URl
    const paramEntries: any = searchParams.entries()
    for (const entry of paramEntries) {
      const [param, value] = entry
      if (value) {
        if (param === 'PageNumber' || param === 'RecordsPerPage') {
          currentPaginationData[param] = value
        } else if (param === 'SortBy' || param === 'SortOrder') {
          currentSortData[param] = value
        } else if (param === 'StartTime' || param === 'EndTime') {
          currentFiltersData[param] = new Date(value)
        } else if (param === 'NetCode' || param === 'ZoneCode') {
          currentFiltersData[param] = value ? value.split(',') : []
        } else {
          currentFiltersData[param] = value
        }
      }
    }
    // update to store
    dispatch(updateFilters({ ...currentFiltersData }))
    dispatch(updateSort({ ...currentSortData }))
    dispatch(updatePagination({ ...currentPaginationData }))
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const reqPayload: { [x: string]: any } = {
      ...tableFilters,
      NetCode: tableFilters?.NetCode?.length ? tableFilters.NetCode.join(',') : null,
      ZoneCode: tableFilters?.ZoneCode?.length ? tableFilters.ZoneCode.join(',') : null,
      StartTime: tableFilters?.StartTime
        ? formatDateWithCustomFormat(tableFilters?.StartTime, 'yyyy-MM-dd HH:mm:ss')
        : null,
      EndTime: tableFilters?.EndTime
        ? formatDateWithCustomFormat(tableFilters?.EndTime, 'yyyy-MM-dd HH:mm:ss')
        : null,
      ...tablePagination,
      ...tableSort,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() }, { replace: true })
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fetchData({
        ...reqPayload,
        ShowLastDays: reqPayload?.ShowLastDays ? subDaysFromDate(reqPayload?.ShowLastDays) : null,
      }),
    )
  }, [
    JSON.stringify({
      ...tableFilters,
      ...tablePagination,
      ...tableSort,
    }),
    isInitialMount.current,
  ])

  const handleClearError = () => {
    dispatch(setError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    dispatch(
      updatePagination({
        ...tablePagination,
        [name]: value,
        ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
      }),
    )
  }

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  const handleDialogClose = () => {
    setSelectedAuditItem(null)
    setDialogStatus(false)
  }

  const isLoading = status === 'loading'

  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={modifiedAuditLogItems || []}
        spacing={spacing}
        loading={isLoading}
        currentPage={PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={MaxCount}
      />
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
      <KiteDialog
        id='detailsDialog'
        className='details-dialog'
        open={dialogStatus}
        title='Audit Log Details'
        onClose={handleDialogClose}
        onPrimaryBtnClick={() => ''}
        primaryBtnLabel=''
      >
        <AuditLogDetailsModel auditLogId={selectedAuditItem?.AuditLogId} />
      </KiteDialog>
    </>
  )
}

export default AuditLogTable
