import { IBulkConfigState } from './BulkConfigModels'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiService from '../../services/ApiService'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import { IConfigData } from '../Configurations/ConfigurationsModels'

const initialState: IBulkConfigState = {
  uploadTempStatus: 'idle',
  uploadTempError: '',
  validateStatus: 'idle',
  validationErrors: [],
  submitStatus: 'idle',
  changeHistoryData: [],
  changeHistoryStatus: 'idle',
  changeHistoryError: '',
  tableFilters: {},
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}
export const uploadFileData = createAsyncThunk(
  'bulkConfigData/uploadFileData',
  async (data: IConfigData, { rejectWithValue }) => {
    try {
      const presignedUrl = (await fetchPresignedUrl(data))?.presignedURL;
      if(!presignedUrl) { 
        return;
      }
    
      const uploadFileResponse = await uploadFileUsingPresignedUrl(data, presignedUrl);
      if(!uploadFileResponse){
        return;
      }

      return 'success';
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)
export const validateOrSubmitFileData = createAsyncThunk(
  'bulkConfigData/validaterSubmitFileData',
  async (data) => {
    const response = await ApiService.postData(
      appApiEndPoints.bulkConfigurationsValidateOrSubmitURL,
      {},
      data,
    )
    return response.data
  },
)

const uploadFileUsingPresignedUrl = async (data: IConfigData, presignedUrl: string)=>{
  return await ApiService.putData(presignedUrl, null, data?.FileData?.slice(0));
};

const fetchPresignedUrl = async (data: IConfigData) => {
  const params = new URLSearchParams();
  params.append('filename', data?.FileName ?? '');
  const response = await ApiService.getData(appApiEndPoints.configurationFilePresignedUrl, params);
  return response?.data;
};

export const fetchConfigChangeHistoryData = createAsyncThunk(
  'bulkConfigData/changeHistoryData',
  async (params) => {
    const response = await ApiService.getData(
      appApiEndPoints.bulkConfigEditChangeHistoryURL,
      params,
    )
    return response.data
  },
)

export const BulkConfigSlice = createSlice({
  name: 'bulkConfigData',
  initialState,
  reducers: {
    resetConfigDetails: (state, action) => {
      state.uploadTempStatus = 'idle'
      state.uploadTempError = ''
      state.validationErrors = []
      state.validateStatus = 'idle'
      state.changeHistoryData = []
      state.changeHistoryStatus = 'idle'
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
    setChangeHistoryError: (state, action) => {
      state.changeHistoryError = action?.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadFileData.pending, (state) => {
        state.uploadTempStatus = 'loading'
        state.uploadTempError = ''
      })
      .addCase(uploadFileData.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase().includes('success')) {
          state.uploadTempStatus = 'succeeded'
        } else {
          state.uploadTempError = result || 'Failed to upload file data'
        }
      })
      .addCase(uploadFileData.rejected, (state, action) => {
        state.uploadTempStatus = 'failed'
        state.uploadTempError = action?.error?.message || 'Failed to upload file data'
      })
      .addCase(validateOrSubmitFileData.pending, (state) => {
        state.validateStatus = 'loading'
      })
      .addCase(validateOrSubmitFileData.fulfilled, (state, action) => {
        if (!action?.payload?.length) {
          state.validateStatus = 'succeeded'
        } else {
          state.validateStatus = 'failed'
        }
        state.validationErrors = action?.payload
      })
      .addCase(validateOrSubmitFileData.rejected, (state, action) => {
        state.validateStatus = 'failed'
        state.validationErrors = [
          {
            Type: 'Failed',
            Message: action?.error?.message,
          },
        ]
      })
      .addCase(fetchConfigChangeHistoryData.pending, (state) => {
        state.changeHistoryStatus = 'loading'
      })
      .addCase(fetchConfigChangeHistoryData.fulfilled, (state, action) => {
        state.changeHistoryStatus = 'succeeded'
        state.changeHistoryData = action?.payload
      })
      .addCase(fetchConfigChangeHistoryData.rejected, (state, action) => {
        state.changeHistoryStatus = 'failed'
        state.changeHistoryError = 'Failed to fetch history data'
      })
  },
})
export const {
  resetConfigDetails,
  setValidationErrors,
  updateFilters,
  updatePagination,
  updateSort,
  setChangeHistoryError,
} = BulkConfigSlice.actions
export default BulkConfigSlice.reducer
