import React from 'react'
import { KiteToast } from '@kite/react-kite'

interface ICustomToastProps {
  status: boolean
  message: string
  onExpire: () => void
}

const CustomToast = ({ status, message, onExpire }: ICustomToastProps) => {
  return (
    <KiteToast
      icon='ki-checkmark-circle-f'
      visible={status}
      onExpire={onExpire}
      bottomOffset={60}
      position='bottom-center'
      duration='custom'
      milliseconds={700}
    >
      {message}
    </KiteToast>
  )
}
export default CustomToast
