import * as React from 'react'
import { styled } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { KiteIcon } from '@kite/react-kite'
import { Spacing } from '../table/Table'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: '1px solid var(--kite-border-color)',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--kite-color-dark-blue-20)',
      color: 'var(--kite-color-white)',
      borderColor: 'var(--kite-color-dark-blue-20)',
      '&:hover': {
        backgroundColor: 'var(--kite-color-dark-blue-20)',
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: '0 4px 4px 0',
      borderLeft: 'none',
    },
    '&:first-of-type': {
      borderRadius: '4px 0 0 4px',
      borderRight: 'none',
    },
  },
}))

interface ITableViewType {
  viewType: Spacing
  setViewType: (value: Spacing) => void
}

const TableViewType = ({ viewType, setViewType }: ITableViewType) => {
  const handleViewType = (event: React.MouseEvent<HTMLElement>, newViewType: Spacing) => {
    if (newViewType !== null) {
      setViewType(newViewType)
    }
  }

  return (
    <div>
      <StyledToggleButtonGroup
        size='medium'
        value={viewType}
        exclusive
        onChange={handleViewType}
        aria-label='view type'
      >
        <ToggleButton disableTouchRipple value={Spacing.Default} aria-label='centered'>
          <KiteIcon ariaLabel='' icon='ki-menu' title='Default' />
        </ToggleButton>
        <ToggleButton disableTouchRipple value={Spacing.Compact} aria-label='left aligned'>
          <KiteIcon ariaLabel='' icon='ki-align-justify' title='Compact' />
        </ToggleButton>
      </StyledToggleButtonGroup>
    </div>
  )
}
export default TableViewType
