import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ISourceAssociatedPathsData, ISourceDetailsState, ISourcesData } from './SourcesModels'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import ApiService from '../../services/ApiService'

const successResultMessage = 'success'

const initialState: ISourceDetailsState = {
  sourceDetails: [],
  status: 'idle',
  error: null,
  associatePathsStatus: 'idle',
  associatePathsData: [],
  createEditSourceStatus: 'idle',
}

export const fetchSourceDetails = createAsyncThunk('sources/fetchDetails', async (params) => {
  const response = await ApiService.getData(appApiEndPoints.sourcesDataURL, params)
  return response.data
})
export const fetchSourceAssociatedDetails = createAsyncThunk(
  'sources/fetchAssociatedPaths',
  async (params) => {
    const response = await ApiService.getData(appApiEndPoints.sourceAssociatedPathsURL, params)
    return response.data
  },
)
export const createSource = createAsyncThunk('sources/createSource', async (data) => {
  const response = await ApiService.postData(appApiEndPoints.createEditSourceDetailsURL, {}, data)
  return response.data
})
export const updateSource = createAsyncThunk('sources/updateSource', async (data) => {
  const response = await ApiService.putData(appApiEndPoints.createEditSourceDetailsURL, {}, data)
  return response.data
})
export const deleteSource = createAsyncThunk('sources/deleteSource', async (params) => {
  const response = await ApiService.deleteData(appApiEndPoints.createEditSourceDetailsURL, params)
  return response.data
})

export const SourceDetailsSlice = createSlice({
  name: 'sourceDetails',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    setCreateEditStatus: (state, action) => {
      state.createEditSourceStatus = action?.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSourceDetails.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSourceDetails.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.sourceDetails = action?.payload as ISourcesData[]
      })
      .addCase(fetchSourceDetails.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch source details'
      })
      .addCase(fetchSourceAssociatedDetails.pending, (state) => {
        state.associatePathsStatus = 'loading'
      })
      .addCase(fetchSourceAssociatedDetails.fulfilled, (state, action) => {
        state.associatePathsStatus = 'succeeded'
        state.associatePathsData = action?.payload as ISourceAssociatedPathsData[]
      })
      .addCase(fetchSourceAssociatedDetails.rejected, (state, action) => {
        state.associatePathsStatus = 'failed'
        state.error = action?.error?.message || 'Failed to fetch source associated paths'
      })
      .addCase(createSource.pending, (state) => {
        state.error = ''
        state.createEditSourceStatus = 'loading'
      })
      .addCase(createSource.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase()?.includes(successResultMessage)) {
          state.createEditSourceStatus = 'succeeded'
        } else {
          state.createEditSourceStatus = 'failed'
          state.error = result || 'Failed to create source'
        }
      })
      .addCase(createSource.rejected, (state, action) => {
        state.createEditSourceStatus = 'failed'
        state.error = action?.error?.message || 'Failed to create source'
      })
      .addCase(updateSource.pending, (state) => {
        state.error = ''
        state.createEditSourceStatus = 'loading'
      })
      .addCase(updateSource.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase().includes(successResultMessage)) {
          state.createEditSourceStatus = 'succeeded'
        } else {
          state.createEditSourceStatus = 'failed'
          state.error = result || 'Failed to save source'
        }
      })
      .addCase(updateSource.rejected, (state, action) => {
        state.createEditSourceStatus = 'failed'
        state.error = action?.error?.message || 'Failed to save source'
      })
      .addCase(deleteSource.pending, (state) => {
        state.error = ''
        state.createEditSourceStatus = 'loading'
      })
      .addCase(deleteSource.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase().includes(successResultMessage)) {
          state.createEditSourceStatus = 'succeeded'
        } else {
          state.createEditSourceStatus = 'failed'
          state.error = result || 'Failed to delete source'
        }
      })
      .addCase(deleteSource.rejected, (state, action) => {
        state.createEditSourceStatus = 'failed'
        state.error = action?.error?.message || 'Failed to delete source'
      })
  },
})
export const { setError, setCreateEditStatus } = SourceDetailsSlice.actions

export default SourceDetailsSlice.reducer
