import React from 'react'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { KiteButton } from '@kite/react-kite'
import { Navigate, useLocation } from 'react-router-dom'
import { PATHS } from '../../routes/Routes'
import { getCurrentUser, isAuthenticated } from './AuthUtils'
import storageService, { loggedInUserKey } from '../../services/StorageService'
import { AmplifyUser } from '@aws-amplify/ui/dist/types/types/authenticator/user'

import '@aws-amplify/ui-react/styles.css'
import './AuthStyles.scss'

const Auth = () => {
  const location = useLocation()
  const currentUser: AmplifyUser = getCurrentUser()
  const components = {
    SignIn: {
      Header() {
        return <div className='auth-header'>Sign in to your account</div>
      },
      Footer() {
        const { toResetPassword } = useAuthenticator()
        return (
          <div className='auth-footer'>
            <KiteButton variant='borderless' onClick={toResetPassword}>
              Reset Password
            </KiteButton>
          </div>
        )
      },
    },
    ResetPassword: {
      Header() {
        return <div className='auth-header reset-header'>Reset your password</div>
      },
      Footer() {
        const { toSignIn } = useAuthenticator()
        return (
          <div className='auth-footer'>
            <KiteButton variant='borderless' onClick={toSignIn}>
              Back to Sign In
            </KiteButton>
          </div>
        )
      },
    },
    ConfirmResetPassword: {
      Header() {
        return <div className='auth-header reset-header'>Reset your password</div>
      },
      Footer() {
        const { toSignIn } = useAuthenticator()
        return (
          <div className='auth-footer'>
            <KiteButton variant='borderless' onClick={toSignIn}>
              Back to Sign In
            </KiteButton>
          </div>
        )
      },
    },
  }
  if (isAuthenticated()) {
    storageService.setItem(loggedInUserKey, currentUser?.username)
    return <Navigate to={location?.state?.prevURL || PATHS.BATCH_ACTIVITY} />
  }
  return (
    <div className='auth-page-container'>
      <div className='auth-container'>
        <Authenticator components={components}></Authenticator>
      </div>
    </div>
  )
}

export default Auth
