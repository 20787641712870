import CustomButton from '../../../../components/customFormControls/button/Button'
import { Sizes } from '../../../../types'
import ConfigurationExport from '../Export/ConfigurationExport'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../../routes/Routes'

const ConfigurationActions = () => {
  const navigate = useNavigate()
  const handleAddNew = () => {
    navigate(PATHS.CREATE_CONFIGURATIONS)
  }
  return (
    <>
      <div className='action'>
        <CustomButton
          onClick={handleAddNew}
          size={Sizes.Medium}
          variant='secondary'
          icon='ki-plus'
          label='Add New'
        ></CustomButton>
      </div>
      <div className='action'>
        <ConfigurationExport />
      </div>
    </>
  )
}
export default ConfigurationActions
