import axios from 'axios'
import { Auth } from 'aws-amplify'

// Request interceptor
axios.interceptors.request.use(function (config: any) {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration()
        const refreshToken = session.getRefreshToken()
        const currentTimeSeconds = Math.round(+new Date() / 1000)
        if (idTokenExpire < currentTimeSeconds) {
          Auth.currentAuthenticatedUser().then((res) => {
            res.refreshSession(refreshToken, (err: any, data: any) => {
              if (err) {
                Auth.signOut()
              } else {
                if (!config?.url?.includes('s3.amazonaws.com')) {
                  config.headers.Authorization = session.getIdToken().getJwtToken()
                }
                resolve(config)
              }
            })
          })
        } else {
          if (!config?.url?.includes('s3.amazonaws.com')) {
            config.headers.Authorization = session.getIdToken().getJwtToken()
          }
          resolve(config)
        }
      })
      .catch(() => {
        Auth.signOut()
      })
  })
})
export const handleError = (err: any) => {
  return new Promise((resolve, reject) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      return reject(err?.response?.data)
    }
    if (err.request) {
      // client never received a response, or request never left
      return reject(err.request)
    }
    // anything else
    return reject(err)
  })
}

axios.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
)

// HTTP Methods
export default {
  getData: (url: string, params: any = null, headers = {}) =>
    axios({
      method: 'GET',
      url,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
  postData: (url: string, params: any = null, data: any = {}, headers = {}) =>
    axios({
      method: 'POST',
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
  putData: (url: string, params: any = null, data: any = {}, headers = {}) =>
    axios({
      method: 'PUT',
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
  deleteData: (url: string, params: any = null, data: any = {}, headers = {}) =>
    axios({
      method: 'DELETE',
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
}
