import React from 'react'
import { IAuditLogLink, RELATED_LINKS_STATUS } from '../../redux/AuditLog/AuditLogModels'
import { Sizes } from '../../types'
import CustomTextInput from '../../components/customFormControls/textInput/TextInput'
import CustomButton from '../../components/customFormControls/button/Button'
import { v4 as uuidv4 } from 'uuid'
import { KiteIcon } from '@kite/react-kite'

import './AuditLogStyles.scss'

interface IAuditLogRelatedLinksProps {
  links: IAuditLogLink[]
  onChange: (data: any) => void
  error: string
  name: string
  enableAdd?: boolean
  enableRemove?: boolean
}

const AuditLogRelatedLinks = ({
  links,
  onChange,
  error,
  name,
  enableAdd = true,
  enableRemove = true,
}: IAuditLogRelatedLinksProps) => {
  const handleChange = (event: any) => {
    const fieldId = event?.target?.name
    const value = event?.target?.value || ''
    const updatedLinks = links.map((link) => {
      if (link?.AuditLogItemId === fieldId) {
        return {
          ...link,
          Address: value,
        }
      }
      return link
    })
    onChange({ target: { name, value: updatedLinks } })
  }
  const handleAddNew = () => {
    const updatedLinks = [...links]
    updatedLinks.push({
      AuditLogItemId: uuidv4(),
      Address: '',
      IsDeleted: 0,
      Status: RELATED_LINKS_STATUS.NEW,
    })
    onChange({ target: { name, value: updatedLinks } })
  }
  const handleRemove = (fieldId: string) => () => {
    const updatedLinks = [...links]
    const linkIndex = updatedLinks.findIndex((link) => link.AuditLogItemId === fieldId)
    if (updatedLinks[linkIndex].Status === RELATED_LINKS_STATUS.OLD) {
      updatedLinks[linkIndex].IsDeleted = 1
    } else {
      updatedLinks.splice(linkIndex, 1)
    }
    onChange({ target: { name, value: updatedLinks } })
  }
  return (
    <div>
      <div className='side-heading'>Related Links</div>
      {links.map((link) => {
        return (
          <div className='each-field' key={link?.AuditLogItemId}>
            <CustomTextInput
              name={link?.AuditLogItemId}
              size={Sizes.Small}
              label=''
              placeholder='Enter Address'
              onChange={handleChange}
              value={link?.Address || ''}
            />
            {enableRemove && (
              <div onClick={handleRemove(link?.AuditLogItemId)}>
                <KiteIcon className='clear-icon' icon='ki-x' size='1rem' />
              </div>
            )}
          </div>
        )
      })}
      {error && <div className='kite-form-control__error'>{error}</div>}
      {enableAdd && (
        <div className='action-btns'>
          <CustomButton
            onClick={handleAddNew}
            size={Sizes.Medium}
            variant='secondary'
            icon='ki-plus'
            label='Add Link'
          ></CustomButton>
        </div>
      )}
    </div>
  )
}

export default AuditLogRelatedLinks
