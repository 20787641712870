import React, { useState } from 'react'
import BatchActivityFilters from './BatchActivityFilters'
import BatchActivityTable from './BatchActivityTable/BatchActivityTable'
import TableViewType from '../../components/tableViewType/TableViewType'
import { Spacing } from '../../components/table/Table'
import PageHeader from '../../components/pageHeader/PageHeader'

const BatchActivity = () => {
  const [viewType, setViewType] = useState<Spacing>(Spacing.Default)
  return (
    <div className='page-container'>
      <PageHeader header='Batch Activity' />
      <div className='page-content'>
        <div className='table-filters'>
          <div className='filters-container'>
            <BatchActivityFilters />
          </div>
          <div className='table-view-type'>
            <TableViewType viewType={viewType} setViewType={setViewType} />
          </div>
        </div>
        <BatchActivityTable spacing={viewType} />
      </div>
    </div>
  )
}
export default BatchActivity
