import React, { MouseEventHandler } from 'react'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { LabelPositions } from '../../../types'

import './AutoComplete.scss'

interface ICustomAutoCompleteProps {
  name?: string
  label?: any
  value: any
  onChange?: any
  placeholder?: string
  onClick?: MouseEventHandler<any> | undefined
  limitTags?: number
  size?: 'small' | 'medium' | undefined
  readOnly?: boolean
  disabled?: boolean
  valueLength?: number | undefined
  type?: string
  labelPos?: LabelPositions
  errorMessage?: string
}

const CustomAutoComplete = ({
  name,
  label,
  value,
  onChange,
  placeholder = '',
  size = 'small',
  readOnly = false,
  disabled = false,
  valueLength,
  type,
  labelPos = LabelPositions.Top,
  limitTags,
  errorMessage = '',
}: ICustomAutoCompleteProps) => {
  const [inputValue, setInputValue] = React.useState('')
  const [error, setError] = React.useState('')
  return (
    <div className={`custom-form-control-input ${labelPos} ${size}`}>
      {label && <label>{label}</label>}
      <Autocomplete
        multiple
        options={[]}
        defaultValue={[]}
        freeSolo
        readOnly={readOnly}
        disabled={disabled}
        size={size}
        disableClearable
        clearOnBlur
        value={value}
        onChange={(event, newValue: any, reason) => {
          onChange({ ...event, target: { ...event.target, name: name, value: newValue } })
        }}
        className='custom-auto-complete'
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
          setError('')
        }}
        renderTags={(
          value: any[],
          getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes,
        ) =>
          value.map((option: any, index: any) => {
            return (
              <Chip key={index} variant='outlined' label={option} {...getTagProps({ index })} />
            )
          })
        }
        renderInput={(params: any) => (
          <TextField
            {...params}
            name={name}
            label=''
            type={type}
            placeholder={placeholder}
            error={!!(errorMessage || error)}
            onKeyDown={(e: any) => {
              setError('')
              if (e.which === 13) {
                const enterValue = e.target.value
                if (value.indexOf(enterValue) > -1) {
                  setError('Value already existed')
                  e.preventDefault()
                  e.stopPropagation()
                }
                if (valueLength && enterValue.length !== valueLength) {
                  setError('Value should be ' + valueLength + ' digits')
                  e.preventDefault()
                  e.stopPropagation()
                }
                if (limitTags && value.length >= limitTags) {
                  setError('You can select up to ' + limitTags + ' ')
                  e.preventDefault()
                  e.stopPropagation()
                }
              }
            }}
          />
        )}
      />
      {(error || errorMessage) && (
        <div className='kite-form-control__error'>{error || errorMessage}</div>
      )}
    </div>
  )
}
export default CustomAutoComplete
