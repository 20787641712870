import React from 'react'
import CustomButton from '../customFormControls/button/Button'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/Routes'

import './FooterStyles.scss'

interface IFooterProps {
  links: Array<{ link: string; label: string }>
}

const Footer = ({ links }: IFooterProps) => {
  const history = useNavigate()
  const currentYear = new Date()?.getFullYear()
  const navigateToHelp = () => {
    history(PATHS.HELP)
  }
  return (
    <footer>
      <div className='left'>
        <div className='links-container'>
          {links.map((link, index) => {
            return (
              <a
                className='external-link'
                key={index}
                target='_blank'
                href={link.link}
                rel='noreferrer'
              >
                {link?.label}
              </a>
            )
          })}
        </div>
        <div className='copy-right'>© {currentYear} Spectrum Reach. (v1.0.1)</div>
      </div>
      <div className='right'>
        <CustomButton
          onClick={navigateToHelp}
          rounded
          label='Get Help'
          icon='ki-question-circle-f'
        ></CustomButton>
      </div>
    </footer>
  )
}
export default Footer
