import React, { useState } from 'react'
import DuplicateActivityFilters from './DuplicateActivityFilters'
import DuplicateActivityTable from './DuplicateActivityTable'
import { Spacing } from '../../../components/table/Table'
import TableViewType from '../../../components/tableViewType/TableViewType'
import { ILink } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import PageHeader from '../../../components/pageHeader/PageHeader'

const DuplicateActivity = () => {
  const [viewType, setViewType] = useState<Spacing>(Spacing.Default)
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Duplicate Activity',
      link: PATHS.DUPLICATE_ACTIVITY,
    },
  ]
  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader header='Duplicate Activity' navLinks={navLinks}></PageHeader>
        <div className='table-filters'>
          <div className='filters-container'>
            <DuplicateActivityFilters />
          </div>
          <div className='table-view-type'>
            <TableViewType viewType={viewType} setViewType={setViewType} />
          </div>
        </div>
        <DuplicateActivityTable spacing={viewType} />
      </div>
    </div>
  )
}
export default DuplicateActivity
