import React, { useEffect, useRef } from 'react'
import { KiteAlert, KiteIcon, KiteMenu, KiteMenuItem } from '@kite/react-kite'
import CustomTable, { Spacing } from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchData,
  setError,
  updateFilters,
  updatePagination,
  updateSort,
} from '../../../redux/ImportSchedules/ImportSchedulesSlice'
import CustomToast from '../../../components/toast/CustomToast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { copyToClipBoard } from '../../../utils/utils'
import { PATHS } from '../../../routes/Routes'

interface ITableProps {
  spacing: Spacing
}

const ImportSchedulesTable = ({ spacing = Spacing.Default }: ITableProps) => {
  const isInitialMount = useRef(true)
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const history = useNavigate()
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const {
    schedulesData = [],
    error,
    status,
    tableFilters,
    tablePagination,
    tablePagination: { PageNumber, RecordsPerPage },
    tableSort,
  } = useSelector((state: RootState) => state.importSchedulesData)

  const columns = [
    {
      label: 'Batch',
      sortKey: 'BatchID',
      accessorKey: 'BatchID',
      fixed: 'left',
      width: '30px',
    },
    {
      label: 'Source Title',
      sortKey: 'SourceTitle',
      accessorKey: 'SourceTitle',
      width: '150px',
    },
    {
      label: 'File In',
      sortKey: 'FileName',
      accessorKey: 'FileName',
      width: '150px',
    },
    {
      label: 'Network',
      sortKey: 'FromNetCode',
      accessorKey: 'FromNetCode',
      width: '150px',
    },
    {
      label: 'Zone',
      sortKey: 'FromZoneCode',
      accessorKey: 'FromZoneCode',
      width: '150px',
    },
    {
      label: 'LOI',
      sortKey: 'LOICount',
      accessorKey: 'LOICount',
      width: '50px',
    },
    {
      label: 'File Modified',
      sortKey: 'FileModified',
      accessorKey: 'FileModified',
      width: '150px',
    },
    {
      label: 'Import Status',
      sortKey: 'DidImport',
      accessorKey: 'DidImport',
      width: '100px',
      render: (row: any) => {
        return <div>{row?.DidImport === '0' ? 'False' : 'True'}</div>
      },
    },
    {
      label: 'Actions',
      sortKey: 'actions',
      fixed: 'right',
      accessorKey: 'actions',
      width: '20px',
      render: (row: any) => {
        const handleSelect = (key: string) => () => {
          let fileType = ''
          if (row?.FileType === 'Schedules') {
            fileType = 'S'
          }
          if (row?.FileType === 'Verifications') {
            fileType = 'V'
          }
          if (key === 'SourcePath') {
            copyToClipBoard(row?.SourcePath)
            setToastStatus(true)
            setToastMsg('Copied Source Path')
          }
          if (key === 'GUID') {
            copyToClipBoard(row?.BatchActivityID)
            setToastStatus(true)
            setToastMsg('Copied GUID')
          }
          if (key === 'ViewContents') {
            history(PATHS.SCHEDULES_IMPORT_CONTENT, { state: row })
          }
          if (key === 'ExportVersion') {
            history(
              `${PATHS.SCHEDULES_EXPORT}?BatchID=${row?.BatchID}&FileType=${row?.FileType}&FromNetCode=${row?.FromNetCode}&FromZoneCode=${row?.FromZoneCode}&DataCenter=${row?.DataCenter}`,
            )
          }
          if (key === 'Configurations') {
            history(
              `${PATHS.VIEW_CONFIGURATIONS}?FileType=${fileType}&SourceID=${row?.SourceID}&FromNetCode=${row?.FromNetCode}&FromZoneCode=${row?.FromZoneCode}`,
            )
          }
        }
        return (
          <div>
            <KiteMenu variant='flow'>
              <KiteMenuItem onSelect={handleSelect('SourcePath')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-copy' />
                  Copy Source Path
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleSelect('GUID')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-copy' />
                  Copy GUID
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleSelect('ViewContents')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-folder' /> View Contents
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleSelect('Configurations')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-settings' /> Configurations
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleSelect('ExportVersion')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-swap' /> Export Version
                </span>
              </KiteMenuItem>
            </KiteMenu>
          </div>
        )
      },
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    dispatch(updateSort({ SortBy: column, SortOrder: newSortDirection }))
  }

  useEffect(() => {
    // Default values
    const currentFiltersData: { [x: string]: any } = {
      ...tableFilters,
    }
    const currentSortData: { [x: string]: any } = { ...tableSort }
    const currentPaginationData: { [x: string]: any } = { ...tablePagination }
    // Values From URl
    const paramEntries: any = searchParams.entries()
    for (const entry of paramEntries) {
      const [param, value] = entry
      if (value) {
        if (param === 'PageNumber' || param === 'RecordsPerPage') {
          currentPaginationData[param] = value
        } else if (param === 'SortBy' || param === 'SortOrder') {
          currentSortData[param] = value
        } else {
          currentFiltersData[param] = value
        }
      }
    }
    dispatch(updateFilters({ ...currentFiltersData }))
    dispatch(updateSort({ ...currentSortData }))
    dispatch(updatePagination({ ...currentPaginationData }))
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const reqPayload: { [x: string]: any } = {
      ...tableFilters,
      ...tableSort,
      ...tablePagination,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() }, { replace: true })
    dispatch(
      fetchData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reqPayload,
      ),
    )
  }, [
    JSON.stringify({
      ...tableFilters,
      ...tablePagination,
      ...tableSort,
    }),
    isInitialMount.current,
  ])

  const handleClearError = () => {
    dispatch(setError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    dispatch(
      updatePagination({
        ...tablePagination,
        [name]: value,
        ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
      }),
    )
  }

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  const totalItems = Number(schedulesData[0]?.MaxCount || 0)
  const isLoading = status === 'loading'

  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={schedulesData || []}
        spacing={spacing}
        loading={isLoading}
        currentPage={PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={totalItems}
        paginationVariant='label'
      />
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </>
  )
}

export default ImportSchedulesTable
