import React, { useEffect, useRef } from 'react'
import { KitePagination } from '@kite/react-kite'
import { RkPaginationVariant } from '@kite/react-kite/dist/pagination/KitePagination/KitePagination'

import './PaginationStyles.scss'

interface IPagination {
  currentPage: number
  onPageChange: (page: number) => void
  itemsPerPage: number
  onItemsPerPageChange: (page: number) => void
  pageOptions?: number[]
  totalItems: number
  variant?: RkPaginationVariant
}

const Pagination = ({
  currentPage = 1,
  onPageChange,
  itemsPerPage = 25,
  onItemsPerPageChange,
  pageOptions = [25, 50, 100],
  totalItems,
  variant,
}: IPagination) => {
  const [totalPages, setTotalPages] = React.useState(1)
  const isInitialMount = useRef(true)

  const handleNextPageClick = () => {
    onPageChange(currentPage + 1)
  }

  const handlePrevPageClick = () => {
    onPageChange(currentPage - 1)
  }

  const handlePageSelect = (pageNumber: number) => {
    // Workaround to stop emitting event on load time
    if (isInitialMount.current) {
      return
    }
    onPageChange(pageNumber)
  }

  const handleItemsPerPageChange = (newItemCount: number) => {
    // Workaround to stop emitting event on load time
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    onPageChange(1)
    onItemsPerPageChange(newItemCount)
    setTotalPages(Math.ceil(totalItems / newItemCount))
  }

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage))
  }, [totalItems])

  return (
    <KitePagination
      expandUp
      variant={variant}
      currentPage={currentPage}
      itemsPerPage={itemsPerPage}
      itemsPerPageOptions={pageOptions}
      totalItems={totalItems}
      totalPages={totalPages}
      onNextPage={handleNextPageClick}
      onPrevPage={handlePrevPageClick}
      onPageSelect={handlePageSelect}
      onItemsPerPageChange={handleItemsPerPageChange}
    />
  )
}
export default Pagination
