import React, { useEffect } from 'react'
import PageHeader from '../../../components/pageHeader/PageHeader'
import { ILink, LabelPositions, Sizes } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import CustomSelect from '../../../components/customFormControls/select/Select'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'
import {
  KiteAlert,
  KiteCheckbox,
  KiteDialog,
  KiteGrid,
  KiteGridCell,
  KiteProgressIndicator,
} from '@kite/react-kite'
import {
  IConfigurationVersion,
  IConfigureCreateModifyPayload,
} from '../../../redux/Configurations/ConfigurationsModels'
import CustomDatePicker from '../../../components/customFormControls/datePicker/DatePicker'
import CustomButton from '../../../components/customFormControls/button/Button'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  createConfiguration,
  deleteConfiguration,
  fetchConfigurationDetails,
  fetchConfigVersions,
  resetConfigDetails,
  updateConfigurationDetails,
} from '../../../redux/Configurations/ConfigurationsSlice'
import { formatDate } from '../../../utils/utils'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { getUserName } from '../../Auth/AuthUtils'

const configReqFields: Array<{ label: string; key: keyof IConfigureCreateModifyPayload }> = [
  {
    label: 'Schedule Source',
    key: 'SourceID',
  },
  {
    label: 'Destination Path',
    key: 'SchedToPath',
  },
  {
    label: 'Network',
    key: 'Network',
  },
  {
    label: 'Provider',
    key: 'Provider',
  },
  {
    label: 'HeadEnd',
    key: 'HeadEnd',
  },
  {
    label: 'From Net',
    key: 'FromNetCode',
  },
  {
    label: 'To Net',
    key: 'ToNetCode',
  },
  {
    label: 'Network Code Description',
    key: 'NetDescription',
  },
  {
    label: 'From Zone',
    key: 'FromZoneCode',
  },
  {
    label: 'To Zone',
    key: 'ToZoneCode',
  },
  {
    label: 'Zone Description',
    key: 'ZoneDescription',
  },
  {
    label: 'Video Type',
    key: 'VideoType',
  },
  {
    label: 'Schedule Platform',
    key: 'SchPlatform',
  },
  {
    label: 'Effective On',
    key: 'EffectiveOn',
  },
]

const ConfigurationDetails = ({ type }: { type: string }) => {
  const isCreateMode = type === 'add'
  const isEditMode = type === 'edit'
  const isDeleteMode = type === 'delete'
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { configId } = useParams()
  const params: any = {}
  const paramEntries: any = searchParams.entries()
  for (const entry of paramEntries) {
    const [param, value] = entry
    params[param] = value
  }
  const dispatch: AppDispatch = useDispatch()
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Configurations',
      link: PATHS.VIEW_CONFIGURATIONS,
    },
    ...(isCreateMode
      ? [
          {
            label: 'Add Configuration',
            link: PATHS.CREATE_CONFIGURATIONS,
          },
        ]
      : []),
    ...(isEditMode
      ? [
          {
            label: 'Edit Configuration',
            link: PATHS.EDIT_CONFIGURATIONS,
          },
        ]
      : []),
    ...(isDeleteMode
      ? [
          {
            label: 'Delete Configuration',
            link: PATHS.DELETE_CONFIGURATIONS,
          },
        ]
      : []),
  ]
  const { filtersData, status: filtersStatus } = useSelector(
    (state: RootState) => state.filtersData,
  )
  const {
    configModifyStatus,
    configDetailsError,
    configurationVersions,
    configVersionsStatus,
    selectedConfigurationDetails,
    configDetailsStatus,
  } = useSelector((state: RootState) => state.configurationsData)
  const configFieldsObject = configReqFields.reduce(
    (obj: any, item) => ((obj[item.key] = ''), obj),
    {},
  )
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState('')
  const [selectedVersion, setSelectedVersion] = React.useState<number | null>(null)
  const { user } = useAuthenticator()
  const [configData, setConfigData] = React.useState<IConfigureCreateModifyPayload>({
    ...configFieldsObject,
    HQ: '',
    IsClone: 'false',
    MSMInstanceID: '20',
    OperatorUserID: getUserName(user),
    Prefix: '',
    PrefixException: '',
    Suffix: '',
    VerifSourceID: '',
    VerToPath: '',
    Enabled: false,
    IsDualRail: 0,
  })
  const [configError, setConfigError] = React.useState(configFieldsObject)
  const versionDetails =
    configurationVersions.find((ver) => ver?.LogConfigurationID == selectedVersion) || null

  const handleChange = (event: any) => {
    const name = event?.target?.name
    let value = event?.target?.value || ''
    const updatedConfigErrorData = configError
    const updatedConfigData = configData
    if (name === 'Enabled') {
      value = event?.target?.checked
    }
    if (name === 'IsDualRail') {
      value = event?.target?.checked ? 1 : 0
    }
    if (name === 'VerifSourceID') {
      if (value && !updatedConfigData['VerToPath']) {
        updatedConfigErrorData['VerToPath'] = 'Please select Destination Path'
      } else if (!value && updatedConfigData['VerToPath']) {
        updatedConfigData['VerToPath'] = ''
        updatedConfigErrorData['VerToPath'] = ''
      } else {
        updatedConfigErrorData['VerToPath'] = ''
        updatedConfigErrorData['VerifSourceID'] = ''
      }
      setConfigData({ ...updatedConfigData, [name]: value })
      setConfigError({ ...updatedConfigErrorData })
      return
    }
    if (name === 'VerToPath') {
      if (value && !updatedConfigData['VerifSourceID']) {
        updatedConfigErrorData['VerifSourceID'] = 'Please select Verification Source'
      } else if (!value && updatedConfigData['VerifSourceID']) {
        updatedConfigErrorData['VerToPath'] = 'Please select Destination Path'
      } else {
        updatedConfigErrorData['VerifSourceID'] = ''
        updatedConfigErrorData['VerToPath'] = ''
      }
      setConfigData({ ...updatedConfigData, [name]: value })
      setConfigError({ ...updatedConfigErrorData })
      return
    }
    setConfigData({ ...updatedConfigData, [name]: value })
    setConfigError({ ...updatedConfigErrorData, [name]: '' })
  }

  const validateFields = () => {
    let valid = true
    const error = { ...configError }
    configReqFields.map((field) => {
      if (!configData[field?.key]) {
        error[field?.key] = 'Please select ' + field?.label
        valid = false
      }
    })
    if (configData?.FromNetCode && configData?.FromNetCode?.length !== 2) {
      error.FromNetCode = 'From Net should be 2 char'
      valid = false
    }
    if (configData?.ToNetCode && configData?.ToNetCode?.length !== 2) {
      error.ToNetCode = 'To Net should be 2 char'
      valid = false
    }
    if (configData?.FromZoneCode && configData?.FromZoneCode?.length !== 3) {
      error.FromZoneCode = 'From Zone should be 3 char'
      valid = false
    }
    if (configData?.ToZoneCode && configData?.ToZoneCode?.length !== 3) {
      error.ToZoneCode = 'To Zone should be 3 char'
      valid = false
    }
    if (configData?.Prefix && configData?.Prefix?.length > 2) {
      error.Prefix = 'Prefix length should be less than or equal to 2 char'
      valid = false
    }
    if (configData?.PrefixException && configData?.PrefixException?.length > 11) {
      error.Prefix = 'Prefix length should be less than or equal to 11 char'
      valid = false
    }
    if (configData?.Suffix && configData?.Suffix?.length > 2) {
      error.Suffix = 'Suffix length should be less than or equal to 2 char'
      valid = false
    }
    if (configData?.VerToPath || configData?.VerifSourceID) {
      if (!configData?.VerToPath) {
        error.VerToPath = 'Please select Destination Path'
        valid = false
      }
      if (!configData?.VerifSourceID) {
        error.VerifSourceID = 'Please select Verification Source'
        valid = false
      }
    }
    return { valid, error }
  }

  const handleSaveConfig = () => {
    const { valid, error } = validateFields()
    if (valid) {
      const payload: Partial<IConfigureCreateModifyPayload> = {
        ConfigurationID: configId,
        EffectiveOn: configData?.EffectiveOn,
        IsDualRail: configData?.IsDualRail,
        Enabled: configData?.Enabled,
        FromNetCode: configData?.FromNetCode,
        FromZoneCode: configData?.FromZoneCode,
        HeadEnd: configData?.HeadEnd,
        HQ: configData?.HQ,
        IsClone: configData?.IsClone,
        NetDescription: configData?.NetDescription,
        Network: configData?.Network,
        OperatorUserID: getUserName(user) || '',
        Prefix: configData?.Prefix,
        PrefixException: configData?.PrefixException,
        Provider: configData?.Provider,
        SchedToPath: configData?.SchedToPath,
        SchPlatform: configData?.SchPlatform,
        SourceID: configData?.SourceID,
        Suffix: configData?.Suffix,
        ToNetCode: configData?.ToNetCode,
        ToZoneCode: configData?.ToZoneCode,
        VerifSourceID: configData?.VerifSourceID,
        VerToPath: configData?.VerToPath,
        VideoType: configData?.VideoType,
        ZoneDescription: configData?.ZoneDescription,
      }
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateConfigurationDetails(payload),
      )
    } else {
      setConfigError({ ...error })
    }
  }
  const handleCreateConfig = () => {
    const { valid, error } = validateFields()
    if (valid) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        createConfiguration({ ...configData, EffectiveOn: formatDate(configData?.EffectiveOn) }),
      )
    } else {
      setConfigError({ ...error })
    }
  }
  const handleDeleteConfig = () => {
    const params = {
      ConfigurationID: configId,
      OperatorUserID: getUserName(user),
    }
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      deleteConfiguration(params),
    )
  }

  const handleVersionChange = (event: any) => {
    const value = event?.target?.value || ''
    setSelectedVersion(value)
  }

  const handleDialogClose = () => {
    setDialogStatus(false)
    setDialogMessage('')
    setTimeout(() => {
      navigate(PATHS.VIEW_CONFIGURATIONS)
    })
  }

  useEffect(() => {
    if (!isCreateMode && configId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchConfigVersions({ ConfigurationID: configId }))
    }
  }, [])

  useEffect(() => {
    if (selectedVersion) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fetchConfigurationDetails({
          ConfigurationID: versionDetails?.ConfigurationID,
          LOGConfigurationID: versionDetails?.LogConfigurationID,
        }),
      )
    }
  }, [selectedVersion])

  useEffect(() => {
    if (selectedConfigurationDetails) {
      const data: any = selectedConfigurationDetails
      setConfigData({
        ...data,
        EffectiveOn:
          versionDetails?.State === 'Current' ? new Date() : new Date(data?.EffectiveDate),
      })
    }
  }, [selectedConfigurationDetails])

  useEffect(() => {
    if (configurationVersions?.length && !isCreateMode) {
      const currentVersion: IConfigurationVersion =
        configurationVersions.find((ver) => ver?.State === 'Current') ||
        ({} as IConfigurationVersion)
      setSelectedVersion(currentVersion?.LogConfigurationID)
    }
  }, [configurationVersions?.length])

  useEffect(() => {
    if (configModifyStatus === 'succeeded') {
      setDialogStatus(true)
      let message = 'Created new configuration successfully'
      if (isEditMode) {
        message = 'Updated Configuration details successfully'
      }
      if (isDeleteMode) {
        message = 'Deleted Configuration successfully'
      }
      setDialogMessage(message)
    }
  }, [configModifyStatus])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(resetConfigDetails())
    }
  }, [])

  const isLoading =
    filtersStatus === 'loading' ||
    configModifyStatus === 'loading' ||
    configVersionsStatus === 'loading' ||
    configDetailsStatus === 'loading'

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader
          header={
            isCreateMode
              ? 'Add Configuration'
              : isEditMode
              ? 'Edit Configuration'
              : 'Delete Configuration'
          }
          navLinks={navLinks}
        ></PageHeader>
        <div className='setup-container'>
          <div className='setup-content-container'>
            {!isCreateMode && (
              <div className='section'>
                <div className='side-heading'>Select Version</div>
                <KiteGrid>
                  <KiteGridCell col={12}>
                    <CustomSelect
                      id='versions'
                      name='versions'
                      size={Sizes.Small}
                      label=''
                      value={selectedVersion}
                      onChange={handleVersionChange}
                    >
                      {(configurationVersions || []).map((option, index) => (
                        <option key={index} value={option?.LogConfigurationID}>
                          {option?.EffectiveDate} ({option?.State}) |{'  '}
                          {option?.From}
                          {'  '}
                          {option?.To}
                          {'  '},{option?.VideoType}
                        </option>
                      ))}
                    </CustomSelect>
                  </KiteGridCell>
                </KiteGrid>
              </div>
            )}
            <div className='section'>
              <KiteGrid>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='scheduledSource'
                    name='SourceID'
                    size={Sizes.Small}
                    label='Scheduled Source*'
                    value={configData?.SourceID}
                    onChange={handleChange}
                    errorMessage={configError?.SourceID}
                  >
                    {(filtersData?.SchedFromPath || []).map((option, index) => (
                      <option key={index} value={option?.ID}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='SchedToPath'
                    size={Sizes.Small}
                    label='Destination Path*'
                    onChange={handleChange}
                    value={configData?.SchedToPath || ''}
                    errorMessage={configError?.SchedToPath}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='provider'
                    name='Provider'
                    size={Sizes.Small}
                    label='Provider*'
                    value={configData?.Provider}
                    onChange={handleChange}
                    errorMessage={configError?.Provider}
                  >
                    {(filtersData?.Provider || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='network'
                    name='Network'
                    size={Sizes.Small}
                    label='Network*'
                    value={configData?.Network}
                    onChange={handleChange}
                    errorMessage={configError?.Network}
                  >
                    {(filtersData?.Network || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='headEnd'
                    name='HeadEnd'
                    size={Sizes.Small}
                    label='HeadEnd*'
                    value={configData?.HeadEnd}
                    onChange={handleChange}
                    errorMessage={configError?.HeadEnd}
                  >
                    {(filtersData?.MCLocation || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
              </KiteGrid>
            </div>
            <div className='section'>
              <div className='side-heading'>Network Code</div>
              <KiteGrid>
                <KiteGridCell col={6}>
                  <CustomTextInput
                    name='FromNetCode'
                    size={Sizes.Small}
                    label='From Net*'
                    onChange={handleChange}
                    value={configData?.FromNetCode || ''}
                    errorMessage={configError?.FromNetCode}
                  />
                </KiteGridCell>
                <KiteGridCell col={6}>
                  <CustomTextInput
                    name='ToNetCode'
                    size={Sizes.Small}
                    label='To Net*'
                    onChange={handleChange}
                    value={configData?.ToNetCode || ''}
                    errorMessage={configError?.ToNetCode}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='NetDescription'
                    name='NetDescription'
                    size={Sizes.Small}
                    label='Description*'
                    value={configData?.NetDescription}
                    onChange={handleChange}
                    errorMessage={configError?.NetDescription}
                  >
                    {(filtersData?.NetDescription || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
              </KiteGrid>
            </div>
            <div className='section'>
              <div className='side-heading'>Zone Code</div>
              <KiteGrid>
                <KiteGridCell col={6}>
                  <CustomTextInput
                    name='FromZoneCode'
                    size={Sizes.Small}
                    label='From*'
                    onChange={handleChange}
                    value={configData?.FromZoneCode || ''}
                    errorMessage={configError?.FromZoneCode}
                  />
                </KiteGridCell>
                <KiteGridCell col={6}>
                  <CustomTextInput
                    name='ToZoneCode'
                    size={Sizes.Small}
                    label='To*'
                    onChange={handleChange}
                    value={configData?.ToZoneCode || ''}
                    errorMessage={configError?.ToZoneCode}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='ZoneDescription'
                    name='ZoneDescription'
                    size={Sizes.Small}
                    label='Description*'
                    value={configData?.ZoneDescription}
                    onChange={handleChange}
                    errorMessage={configError?.ZoneDescription}
                  >
                    {(filtersData?.ZoneDescription || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
              </KiteGrid>
            </div>
            <div className='section'>
              <div className='side-heading'>Verification</div>
              <KiteGrid>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='VerifSourceID'
                    name='VerifSourceID'
                    size={Sizes.Small}
                    label='Verification Source'
                    value={configData?.VerifSourceID}
                    onChange={handleChange}
                    errorMessage={configError?.VerifSourceID}
                  >
                    {(filtersData?.VerifFromPath || []).map((option, index) => (
                      <option key={index} value={option?.ID}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='VerToPath'
                    size={Sizes.Small}
                    label='Destination Path'
                    onChange={handleChange}
                    value={configData?.VerToPath || ''}
                    errorMessage={configError?.VerToPath}
                  />
                </KiteGridCell>
              </KiteGrid>
            </div>
            <div className='section'>
              <div className='side-heading'>Other Details</div>
              <KiteGrid>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='videoType'
                    name='VideoType'
                    size={Sizes.Small}
                    label='VideoType*'
                    value={configData?.VideoType}
                    onChange={handleChange}
                    errorMessage={configError?.VideoType}
                  >
                    {(filtersData?.VideoType || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={4}>
                  <CustomTextInput
                    name='Prefix'
                    size={Sizes.Small}
                    label='Prefix'
                    onChange={handleChange}
                    value={configData?.Prefix || ''}
                    errorMessage={configError?.Prefix}
                  />
                </KiteGridCell>
                <KiteGridCell col={4}>
                  <CustomTextInput
                    name='PrefixException'
                    size={Sizes.Small}
                    label='Prefix Exception'
                    onChange={handleChange}
                    value={configData?.PrefixException || ''}
                    errorMessage={configError?.PrefixException}
                  />
                </KiteGridCell>
                <KiteGridCell col={4}>
                  <CustomTextInput
                    name='Suffix'
                    size={Sizes.Small}
                    label='Suffix'
                    onChange={handleChange}
                    value={configData?.Suffix || ''}
                    errorMessage={configError?.Suffix}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='SchPlatform'
                    name='SchPlatform'
                    size={Sizes.Small}
                    label='Schedule Platform*'
                    value={configData?.SchPlatform}
                    onChange={handleChange}
                    errorMessage={configError?.SchPlatform}
                  >
                    {(filtersData?.SchedPlatform || []).map((option, index) => (
                      <option key={index} value={option?.Value}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <KiteCheckbox
                    checked={!!configData?.Enabled}
                    id='enabled'
                    label='Enabled'
                    name='Enabled'
                    onChange={handleChange}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <KiteCheckbox
                    checked={!!configData?.IsDualRail}
                    id='IsDualRail'
                    label='Is Dual Rail'
                    name='IsDualRail'
                    onChange={handleChange}
                  />
                </KiteGridCell>
              </KiteGrid>
            </div>
            <div className='section'>
              <div className='side-heading'>Effective Date</div>
              <KiteGrid>
                <KiteGridCell col={12}>
                  <CustomDatePicker
                    onChange={handleChange}
                    labelPos={LabelPositions.Top}
                    label='Effective On*'
                    name='EffectiveOn'
                    minDate={new Date()}
                    value={configData?.EffectiveOn}
                    errorMessage={configError?.EffectiveOn}
                  />
                </KiteGridCell>
              </KiteGrid>
            </div>
            {configDetailsError && (
              <div className='alert-element'>
                <KiteAlert
                  autoFocus={false}
                  level='page'
                  type='error'
                  description={configDetailsError}
                />
              </div>
            )}
            {versionDetails?.State === 'Past' ? (
              <div className='alert-element'>
                <KiteAlert
                  autoFocus={false}
                  level='page'
                  type='info'
                  description='Editing not available for past configurations'
                />
              </div>
            ) : (
              <div className='setup-actions'>
                {isEditMode && (
                  <CustomButton
                    label={'Save Configuration'}
                    onClick={handleSaveConfig}
                    loading={isLoading}
                  ></CustomButton>
                )}
                {isCreateMode && (
                  <CustomButton
                    label={'Create Configuration'}
                    onClick={handleCreateConfig}
                    loading={isLoading}
                  ></CustomButton>
                )}
                {isDeleteMode && (
                  <CustomButton
                    label='Delete'
                    btnType='danger'
                    onClick={handleDeleteConfig}
                    loading={isLoading}
                  ></CustomButton>
                )}
              </div>
            )}
          </div>
        </div>
        <KiteDialog
          id='confirmDialog'
          className='confirm-dialog'
          open={dialogStatus}
          title='Success'
          onClose={handleDialogClose}
          primaryBtnLabel='Ok'
          onPrimaryBtnClick={handleDialogClose}
        >
          <div className='kite-dialog__content-group'>{dialogMessage}</div>
        </KiteDialog>
        {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
      </div>
    </div>
  )
}
export default ConfigurationDetails
