import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { LabelPositions } from '../../../types'
import { KiteIcon } from '@kite/react-kite'
import { FormControl, FormHelperText } from '@mui/material'

import './MultiSelectStyles.scss'

const MultiSelect = ({
  labelPos = LabelPositions.Top,
  children,
  label,
  size = 'small',
  errorMessage,
  ...rest
}: any) => {
  return (
    <FormControl className={`custom-form-control-select ${labelPos} ${size}`} error={!!errorMessage}>
      {label && <label>{label}</label>}
      <Select
        multiple
        input={<OutlinedInput />}
        renderValue={(selected: string[]) => selected.join(', ')}
        size={size}
        IconComponent={() => <KiteIcon className='kite-multi-select__icon' icon='ki-dropdown' />}
        {...rest}
      >
        {children}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}
export default MultiSelect
