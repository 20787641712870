import React from 'react'

import './ScheduleContentStyles.scss'

interface IContentRemarks {
  remarksData: Array<{ Remarks: string }>
}

const ScheduleContentRemarks = ({ remarksData = [] }: IContentRemarks) => {
  return (
    <div>
      <div className='remarks-container'>
        <div className='label'>Remarks</div>
        <section className='grey-container'>
          {remarksData.map((remarkData, index) => {
            return <div key={index}>{remarkData?.Remarks}</div>
          })}
        </section>
      </div>
    </div>
  )
}
export default ScheduleContentRemarks
