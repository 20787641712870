import React, { useEffect } from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import { ILink, LabelPositions, Sizes } from '../../types'
import { PATHS } from '../../routes/Routes'
import {
  KiteAlert,
  KiteCheckbox,
  KiteDialog,
  KiteGrid,
  KiteGridCell,
  KiteIcon,
  KiteProgressIndicator,
} from '@kite/react-kite'
import CustomDatePicker from '../../components/customFormControls/datePicker/DatePicker'
import CustomButton from '../../components/customFormControls/button/Button'
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import {
  IBulkConfigData,
  IBulkConfigValidateOrSubmitPayload,
} from '../../redux/BulkConfigurations/BulkConfigModels'

import './BulkConfigurationStyles.scss'
import { AppDispatch, RootState } from '../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchConfigChangeHistoryData,
  resetConfigDetails,
  setValidationErrors,
  uploadFileData,
  validateOrSubmitFileData,
} from '../../redux/BulkConfigurations/BulkConfigSlice'
import { formatDate } from '../../utils/utils'
import { useAuthenticator } from '@aws-amplify/ui-react'
import CustomTextInput from '../../components/customFormControls/textInput/TextInput'
import { getUserName } from '../Auth/AuthUtils'
import { IConfigData } from '../../redux/Configurations/ConfigurationsModels'

const BulkConfiguration = ({ type }: { type: string }) => {
  const isCreateMode = type === 'add'
  const isEditMode = type === 'edit'
  const isDeleteMode = type === 'delete'
  const dispatch: AppDispatch = useDispatch()
  const { user } = useAuthenticator()
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Configurations',
      link: PATHS.VIEW_CONFIGURATIONS,
    },
    ...(isCreateMode
      ? [
          {
            label: 'Bulk Add Configurations',
            link: PATHS.BULK_CREATE_CONFIGURATIONS,
          },
        ]
      : []),
    ...(isEditMode
      ? [
          {
            label: 'Bulk Edit Configurations',
            link: PATHS.BULK_EDIT_CONFIGURATIONS,
          },
        ]
      : []),
    ...(isDeleteMode
      ? [
          {
            label: 'Bulk Delete Configuration',
            link: PATHS.BULK_DELETE_CONFIGURATIONS,
          },
        ]
      : []),
  ]
  const [configData, setConfigData] = React.useState<IConfigData>({
    EffectiveDate: new Date(),
    IgnoreOptionRules: false,
    FileData: null,
    FileName: ''
  })
  const [configError, setConfigError] = React.useState({
    EffectiveDate: '',
    IgnoreOptionRules: '',
    FileData: '',
  })
  const [fileParsedData, setFileParsedData] = React.useState([])
  const [bulkRequestIDValue, setBulkRequestIDValue] = React.useState('')
  const [BulkOperation, setBulkOperation] = React.useState(isEditMode ? 1 : 0)
  const {
    uploadTempError,
    uploadTempStatus,
    validateStatus,
    validationErrors,
    changeHistoryStatus,
    changeHistoryData,
    changeHistoryError,
  } = useSelector((state: RootState) => state.bulkConfigData)
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [dialogMsg, setDialogMsg] = React.useState('')

  const handleChange = (event: any) => {
    const name = event?.target?.name
    let value = event?.target?.value || ''
    if (name === 'IgnoreOptionRules') {
      value = event?.target?.checked
    }
    setConfigData({ ...configData, [name]: value })
  }

  const handleSubmit = () => {
    const { valid, error } = validateFields()
    if (valid) {
      let bulkOperationId = 5
      if (isEditMode) {
        bulkOperationId = 4
      }
      if (isDeleteMode) {
        bulkOperationId = 6
      }
      setBulkOperation(bulkOperationId)
      handleValidate()
    } else {
      setConfigError(error)
    }
  }

  const handleFileChange = (event: any) => {
    handleResetBulkConfigDetails()
    setConfigError({ ...configError, FileData: '' })
    setFileParsedData([])
    const file = event.target.files[0]
    setConfigData({ ...configData, FileData: file })
    const reader = new FileReader()
    reader.onload = function (e: any) {
      const csv = Papa.parse(e.target.result, { header: true })
      const parsedData: any = csv?.data
      if (parsedData.length > 1) {
        parsedData.pop()
      }
      setFileParsedData(parsedData)
    }
    reader.readAsText(file)
    event.target.value = ''
  }

  const validateFields = () => {
    let valid = true
    const error = { ...configError }
    const { EffectiveDate, FileData } = configData
    if (!EffectiveDate) {
      valid = false
      error.EffectiveDate = 'Please select effective date'
    }
    if (!FileData) {
      valid = false
      error.FileData = 'Please select file'
    }
    return { error, valid }
  }

  const handleValidateOnly = () => {
    let bulkOperationId = 2
    if (isEditMode) {
      bulkOperationId = 1
    }
    if (isDeleteMode) {
      bulkOperationId = 3
    }
    setBulkOperation(bulkOperationId)
    handleValidate()
  }

  const handleValidate = () => {
    // validate size <5mb
    const fileSize = configData?.FileData?.size ?? 0;
    const fileMb = fileSize/(1024 * 1024);
    if(fileMb > 5) {
      dispatch(
        setValidationErrors([{ Type: 'Max File Size', Message: 'File must be under 5 MB' }]),
      )
      return
    }

    const { valid, error } = validateFields()
    if (valid) {
      const columns = Object.keys(fileParsedData[0])
      const { isValid, missingColumns } = validateFileColumns(columns)
      if (!isValid) {
        dispatch(
          setValidationErrors([{ Type: 'Missing Columns', Message: missingColumns.join(',') }]),
        )
        return
      }
      const bulkRequestIDValue = uuidv4();
      setBulkRequestIDValue(bulkRequestIDValue);
      configData.BulkRequestIDValue = bulkRequestIDValue;
      const fileName = ((configData?.FileData?.name?.split('.')[0] ?? '') + '_' + (configData.BulkRequestIDValue ?? '') + '.' + configData?.FileData?.name?.split('.')[1]);
      setConfigData({ ...configData, FileName: fileName });
      configData.FileName = fileName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(uploadFileData(configData))
    } else {
      setConfigError(error)
    }
  }

  const validateFileColumns = (columns: Array<string>) => {
    const reqColumns = [
      'ConfigurationID',
      'HeadEnd',
      'Provider',
      'SchedFromPath',
      'SchedToPath',
      'FromNetCode',
      'ToNetCode',
      'Network',
      'NetDescription',
      'FromZoneCode',
      'ToZoneCode',
      'ZoneDescription',
      'VerFromPath',
      'VerToPath',
      'VideoType',
      'Prefix',
      'PrefixException',
      'Suffix',
      'SchedulePlatform',
      'IsDualRail',
    ]
    const missingColumns: Array<string> = []
    let isValid = true
    reqColumns.forEach((column) => {
      if (!columns.includes(column)) {
        missingColumns.push(column)
        isValid = false
      }
    })
    return { isValid, missingColumns };
  }

  const handleDialogClose = () => {
    setDialogStatus(false)
    setDialogMsg('')
  }
  const handleResetBulkConfigDetails = () => {
    setConfigData({ ...configData, FileData: null })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resetConfigDetails())
  }

  useEffect(() => {
    if (uploadTempStatus === 'succeeded') {
      const payload: IBulkConfigValidateOrSubmitPayload = {
        BulkRequestID: bulkRequestIDValue,
        EffectiveDate: formatDate(configData?.EffectiveDate?.toString()),
        BulkOperation: BulkOperation,
        IgnoreOptionRules: configData?.IgnoreOptionRules ? 1 : 0,
        FileName: configData?.FileName ?? '',
        MSMInstanceId: 20,
        OperatorUserID: getUserName(user),
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(validateOrSubmitFileData(payload))
    }
  }, [uploadTempStatus])

  useEffect(() => {
    if (
      validateStatus === 'succeeded' &&
      (BulkOperation === 4 || BulkOperation === 6 || BulkOperation === 5)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchConfigChangeHistoryData({ BulkConfigChangeID: bulkRequestIDValue }))
    }
    if (
      validateStatus === 'succeeded' &&
      (BulkOperation === 1 || BulkOperation === 2 || BulkOperation === 3)
    ) {
      setDialogStatus(true)
      setDialogMsg('Validation has completed and no error found')
    }
  }, [validateStatus])

  useEffect(() => {
    handleResetBulkConfigDetails()
  }, [type])

  useEffect(() => {
    return () => handleResetBulkConfigDetails()
  }, [])

  const isLoading =
    uploadTempStatus === 'loading' ||
    validateStatus === 'loading' ||
    changeHistoryStatus === 'loading'

  const isError = uploadTempError || changeHistoryError

  const fileName = configData?.FileData?.name

  const applyHistoryData = changeHistoryData?.[0]
  let changeInfo = ''
  if (applyHistoryData?.ChangeType === 'Add') {
    changeInfo = 'Your bulk schedule configurations has been validated and created and is now live'
  }
  if (applyHistoryData?.ChangeType === 'Delete') {
    changeInfo =
      'Your bulk schedule configurations has been validated and deleted and changes now live'
  }
  if (applyHistoryData?.ChangeType === 'Update') {
    changeInfo = 'Your bulk schedule configurations has been validated and edited and is now live'
  }
  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader
          header={
            isCreateMode
              ? 'Bulk Add Configurations'
              : isEditMode
              ? 'Bulk Edit Configurations'
              : 'Bulk Delete Configurations'
          }
          navLinks={navLinks}
        ></PageHeader>
        <div className='setup-container'>
          <div className='setup-content-container'>
            {changeHistoryStatus === 'succeeded' ? (
              <KiteGrid>
                <div className='alert-element'>
                  <KiteAlert
                    autoFocus={false}
                    level='page'
                    type='success'
                    description={changeInfo}
                  />
                </div>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='ItemCount'
                    size={Sizes.Small}
                    label='Item Count'
                    onChange={handleChange}
                    disabled
                    value={applyHistoryData?.ItemCount || ''}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='CreatedByPID'
                    size={Sizes.Small}
                    label='By User'
                    onChange={handleChange}
                    disabled
                    value={applyHistoryData?.CreatedByPID || ''}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='EffectiveDate'
                    size={Sizes.Small}
                    label='Effective Date'
                    onChange={handleChange}
                    disabled
                    value={applyHistoryData?.EffectiveDate || ''}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='FileName'
                    size={Sizes.Small}
                    label='File Name'
                    onChange={handleChange}
                    disabled
                    value={applyHistoryData?.FileName || ''}
                  />
                </KiteGridCell>
              </KiteGrid>
            ) : (
              <>
                <div className='section'>
                  <div className='side-heading'>Upload Configurations File</div>
                  <div>
                    <label>Import CSV</label>
                    <div className='upload-container'>
                      {!fileName && <KiteIcon size='1rem' icon='ki-plus' className='upload-icon' />}
                      {!fileName && <span>Upload CSV file</span>}
                      {fileName && <span>{fileName}</span>}
                      <input
                        type='file'
                        accept='.csv'
                        className='file-input'
                        onChange={handleFileChange}
                      />
                    </div>
                    {configError?.FileData && (
                      <div className='kite-form-control__error'>{configError?.FileData}</div>
                    )}
                  </div>
                </div>
                <div className='section'>
                  <div className='side-heading'>{`Validation ${
                    validationErrors?.length ? 'Issues' : ''
                  }`}</div>
                  {validationErrors?.length ? (
                    <div>
                      {(validationErrors || []).map((validationError, index) => {
                        return (
                          <div key={index} className='alert-element'>
                            <KiteAlert
                              autoFocus={false}
                              level='page'
                              type='error'
                              description={validationError?.Type + ': ' + validationError?.Message}
                            />
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='display-inline'>
                      <CustomButton
                        variant='secondary'
                        label='Check Validations Only'
                        onClick={handleValidateOnly}
                      />
                    </div>
                  )}
                  <div className='field'>
                    <KiteCheckbox
                      checked={!!configData?.IgnoreOptionRules}
                      id='IgnoreOptionRules'
                      label='Ignore Optional Validation Rules'
                      name='IgnoreOptionRules'
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='section'>
                  <div className='side-heading'>Select Effective Date</div>
                  <CustomDatePicker
                    onChange={handleChange}
                    labelPos={LabelPositions.Top}
                    label='Effective Date*'
                    name='EffectiveDate'
                    minDate={new Date()}
                    value={configData?.EffectiveDate}
                    errorMessage={configError?.EffectiveDate}
                  />
                </div>
                {isError && (
                  <div className='alert-element'>
                    <KiteAlert autoFocus={false} level='page' type='error' description={isError} />
                  </div>
                )}
                <div className='setup-actions'>
                  <CustomButton
                    disabled={isLoading}
                    label='Submit'
                    onClick={handleSubmit}
                  ></CustomButton>
                </div>
              </>
            )}
          </div>
          {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
          <KiteDialog
            id='bulkConfigDialog'
            className='custom-dialog'
            open={dialogStatus}
            title='Bulk Configurations'
            onClose={handleDialogClose}
            onPrimaryBtnClick={handleDialogClose}
            primaryBtnLabel='Ok'
          >
            <div className='kite-dialog__content-group'>{dialogMsg}</div>
            <div className='dialog-actions'>
              <CustomButton
                onClick={handleDialogClose}
                size={Sizes.Large}
                label='Ok'
                style={{ width: '100px' }}
              ></CustomButton>
            </div>
          </KiteDialog>
        </div>
      </div>
    </div>
  )
}
export default BulkConfiguration
