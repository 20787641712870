import React, { useEffect, useRef } from 'react'
import { FILE_PROGRESS_STATUS, IAuditLogAttachment } from '../../../redux/AuditLog/AuditLogModels'
import { Sizes } from '../../../types'
import CustomButton from '../../../components/customFormControls/button/Button'
import { v4 as uuidv4 } from 'uuid'
import { KiteIcon } from '@kite/react-kite'
import EachAttachment from './EachAttachment'

import '../AuditLogStyles.scss'

interface IAuditLogAttachmentProps {
  auditLogId: string
  attachments: IAuditLogAttachment[]
  onChange: (data: any) => void
  error: string
  enableAdd?: boolean
  enableRemove?: boolean
}

const AuditLogAttachments = ({
  auditLogId,
  attachments,
  onChange,
  error,
  enableAdd = true,
  enableRemove = true,
}: IAuditLogAttachmentProps) => {
  const attachmentsRef = useRef(attachments)

  const handleAddNew = () => {
    const updatedAttachments = [...attachments]
    updatedAttachments.push({
      AuditLogItemId: uuidv4(),
      DocName: '',
      IsDeleted: 0,
      Status: FILE_PROGRESS_STATUS.NEW,
    })
    onChange(updatedAttachments)
  }
  const handleRemove = (fieldId: string) => () => {
    const updatedAttachments = [...attachments]
    const linkIndex = updatedAttachments.findIndex(
      (attachment) => attachment.AuditLogItemId === fieldId,
    )
    if (updatedAttachments[linkIndex].Status === FILE_PROGRESS_STATUS.OLD) {
      updatedAttachments[linkIndex].IsDeleted = 1
    } else {
      updatedAttachments.splice(linkIndex, 1)
    }
    onChange(updatedAttachments)
  }

  const handleChange = (fieldId: string) => (updatedAttachment: any) => {
    const updatedAttachments = (attachmentsRef.current || []).map((attachment) => {
      if (attachment?.AuditLogItemId === fieldId) {
        return {
          ...updatedAttachment,
        }
      }
      return attachment
    })
    onChange(updatedAttachments)
  }

  useEffect(() => {
    attachmentsRef.current = attachments
  }, [JSON.stringify(attachments)])

  return (
    <div>
      <div className='side-heading'>Attachments</div>
      {attachments.map((attachment) => {
        return (
          <div className='each-field' key={attachment?.AuditLogItemId}>
            <EachAttachment
              auditLogId={auditLogId}
              attachment={attachment}
              onChange={handleChange(attachment?.AuditLogItemId)}
            />
            {enableRemove && (
              <div onClick={handleRemove(attachment?.AuditLogItemId)}>
                <KiteIcon className='clear-icon' icon='ki-x' size='1rem' />
              </div>
            )}
          </div>
        )
      })}
      {error && <div className='kite-form-control__error'>{error}</div>}
      {enableAdd && (
        <div className='action-btns'>
          <CustomButton
            onClick={handleAddNew}
            size={Sizes.Medium}
            variant='secondary'
            icon='ki-plus'
            label='Add Attachment'
          ></CustomButton>
        </div>
      )}
    </div>
  )
}

export default AuditLogAttachments
