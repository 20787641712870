import React, { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { KiteAlert } from '@kite/react-kite'
import {
  formatDate,
  formatDateWithTime,
  formatStringToDate,
  recordsAfterPagingAndSorting,
} from '../../../utils/utils'
import CustomTable, { Spacing } from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchConfigChangeHistoryData,
  setChangeHistoryError,
  updateFilters,
  updatePagination,
  updateSort,
} from '../../../redux/BulkConfigurations/BulkConfigSlice'
import { IBulkChangeHistoryData } from '../../../redux/BulkConfigurations/BulkConfigModels'

interface ITableProps {
  spacing: Spacing
}

const BulkConfigTable = ({ spacing = Spacing.Default }: ITableProps) => {
  const isInitialMount = useRef(true)
  const [searchParams] = useSearchParams()
  const history = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const {
    changeHistoryData = [],
    changeHistoryError: error,
    changeHistoryStatus,
    tableFilters,
    tablePagination,
    tablePagination: { PageNumber, RecordsPerPage },
    tableSort,
    tableSort: { SortBy, SortOrder },
  } = useSelector((state: RootState) => state.bulkConfigData)

  const columns = [
    {
      label: 'Change Type',
      sortKey: 'ChangeType',
      accessorKey: 'ChangeType',
      width: '120px',
    },
    {
      label: 'Created On',
      sortKey: 'CreatedOn',
      accessorKey: 'CreatedOn',
      render: (row: IBulkChangeHistoryData) => <span>{formatDateWithTime(row?.CreatedOn)}</span>,
    },
    {
      label: 'Changed By',
      sortKey: 'CreatedByPID',
      accessorKey: 'CreatedByPID',
    },
    {
      label: 'Effective Date',
      sortKey: 'EffectiveDate',
      accessorKey: 'EffectiveDate',
    },
    {
      label: 'Items',
      sortKey: 'ItemCount',
      accessorKey: 'ItemCount',
      width: '50px',
    },
    {
      label: 'File Name',
      sortKey: 'FileName',
      accessorKey: 'FileName',
      width: '350px',
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    dispatch(updateSort({ SortBy: column, SortOrder: newSortDirection }))
  }

  useEffect(() => {
    // Default values
    const currentFiltersData: { [x: string]: any } = { ...tableFilters }
    const currentSortData: { [x: string]: any } = { ...tableSort }
    const currentPaginationData: { [x: string]: any } = { ...tablePagination }
    // Values From URl
    const paramEntries: any = searchParams.entries()
    for (const entry of paramEntries) {
      const [param, value] = entry
      if (value) {
        if (param === 'PageNumber' || param === 'RecordsPerPage') {
          currentPaginationData[param] = value
        } else if (param === 'SortBy' || param === 'SortOrder') {
          currentSortData[param] = value
        } else if (param === 'Effectivedate') {
          currentFiltersData[param] = value ? formatStringToDate(value) : null
        } else {
          currentFiltersData[param] = value
        }
      }
    }
    dispatch(updateFilters({ ...currentFiltersData }))
    dispatch(updateSort({ ...currentSortData }))
    dispatch(updatePagination({ ...currentPaginationData }))
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const reqPayload: { [x: string]: any } = {
      ...tableFilters,
      EffectiveDate: tableFilters?.EffectiveDate ? formatDate(tableFilters?.EffectiveDate) : null,
      ...tablePagination,
      ...tableSort,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() }, { replace: true })
    dispatch(
      fetchConfigChangeHistoryData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reqPayload,
      ),
    )
  }, [
    JSON.stringify({
      ...tableFilters,
    }),
    isInitialMount.current,
  ])

  useEffect(() => {
    const reqPayload: { [x: string]: any } = {
      ...tablePagination,
      ...tableSort,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() })
  }, [
    JSON.stringify({
      ...tableSort,
      ...tablePagination,
    }),
  ])

  const handleClearError = () => {
    dispatch(setChangeHistoryError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    dispatch(
      updatePagination({
        ...tablePagination,
        [name]: value,
        ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
      }),
    )
  }

  const totalItems = Number(changeHistoryData?.length)
  const isLoading = changeHistoryStatus === 'loading'
  const paginatedRecords = recordsAfterPagingAndSorting(
    changeHistoryData,
    SortOrder,
    SortBy,
    PageNumber,
    RecordsPerPage,
  )
  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={paginatedRecords || []}
        spacing={spacing}
        loading={isLoading}
        currentPage={PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={totalItems}
      />
    </>
  )
}

export default BulkConfigTable
