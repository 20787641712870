import React, { useEffect } from 'react'
import CustomTable from '../../../components/table/Table'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import { KiteAlert } from '@kite/react-kite'
import { fetchSourceAssociatedDetails, setError } from '../../../redux/Sources/SourceDetailSlice'
import { useSearchParams } from 'react-router-dom'
import { ISourceAssociatedPathsData } from '../../../redux/Sources/SourcesModels'

const SourceAssociatedPaths = () => {
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const sourceID = searchParams.get('SourceID')
  const fileType = searchParams.get('FileType')
  const columns = [
    {
      label: 'Path Type',
      sortKey: 'PathType',
      accessorKey: 'PathType',
      width: '130px',
    },
    {
      label: 'Source Title',
      sortKey: 'SourceTitle',
      accessorKey: 'SourceTitle',
      width: '150px',
    },
    {
      label: 'Total Configs',
      sortKey: 'Ctr',
      accessorKey: 'Ctr',
      width: '150px',
    },
    {
      label: 'Path Is Shared',
      sortKey: 'IsShared',
      accessorKey: 'IsShared',
      width: '150px',
      render: (row: ISourceAssociatedPathsData) => <div>{row?.IsShared ? 'True' : 'False'}</div>,
    },
  ]
  const { error, associatePathsData = [] } = useSelector((state: RootState) => state.sourceDetails)

  const handleClearError = () => {
    dispatch(setError(''))
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSourceAssociatedDetails({ SourceID: sourceID, FileType: fileType }))
  }, [sourceID, fileType])

  return (
    <div className='associated-table-container'>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <h3>Associated Paths</h3>
      <CustomTable columns={columns} data={associatePathsData || []} hidePagination />
    </div>
  )
}

export default SourceAssociatedPaths
