import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import BatchActivity from '../pages/BatchActivity/BatchActivity'
import Sources from '../pages/Sources/View/Sources'
import RequireAuth from './RequiredAuthRoute'
import Auth from '../pages/Auth/Auth'
import Configurations from '../pages/Configurations/View/Configurations'
import ConfigurationDetails from '../pages/Configurations/Add/ConfigurationDetails'
import AddEditSource from '../pages/Sources/AddEditSource/AddEditSource'
import ExportSchedules from '../pages/Schedules/Export/ExportSchedules'
import ImportSchedules from '../pages/Schedules/Import/ImportSchedules'
import DuplicateActivity from '../pages/Duplicates/Activity/DuplicateActivity'
import DuplicateFileSetup from '../pages/Duplicates/FileSetup/DuplicateFileSetup'
import DuplicateCreateSetup from '../pages/Duplicates/CreateSetup/DuplicateCreateSetup'
import DuplicateEditSetup from '../pages/Duplicates/EditSetup/DuplicateEditSetup'
import SourceDetailsContainer from '../pages/Sources/Details/SourceDetailsContainer'
import BulkConfiguration from '../pages/BulkConfigurations/BulkConfiguration'
import BulkConfigHistory from '../pages/BulkConfigurations/BulkConfigHistory/BulkConfigHistory'
import ExportScheduleContent from '../pages/Schedules/ContentDetails/ExportScheduleContent'
import ImportScheduleContent from '../pages/Schedules/ContentDetails/ImportScheduleContent'
import Help from '../pages/Help/Help'
import AuditLog from '../pages/AuditLog/AuditLog'
import AuditLogDetails from '../pages/AuditLog/AuditLogDetails'

export const PATHS = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password-confirmation',
  EMAIL_CONFIRMATION: '/email-confirmation',
  BATCH_ACTIVITY: '/batch-activity',
  SCHEDULES: '/schedules',
  SCHEDULES_EXPORT: '/schedules/export',
  SCHEDULES_EXPORT_CONTENT: '/schedules/export-content',
  SCHEDULES_IMPORT: '/schedules/import',
  SCHEDULES_IMPORT_CONTENT: '/schedules/import-content',
  SOURCES: '/sources',
  VIEW_SOURCES: '/sources/view-sources',
  SOURCE_DETAILS: '/sources/details',
  CREATE_SOURCE: '/sources/create-source',
  EDIT_SOURCE: '/sources/edit-source',
  DELETE_SOURCE: '/sources/delete-source',
  CONFIGURATIONS: '/configurations',
  VIEW_CONFIGURATIONS: '/configurations/view-configurations',
  CREATE_CONFIGURATIONS: '/configurations/create-configurations',
  EDIT_CONFIGURATIONS: '/configurations/edit-configurations',
  DELETE_CONFIGURATIONS: '/configurations/delete-configurations',
  BULK_CREATE_CONFIGURATIONS: '/configurations/bulk-create-configurations',
  BULK_EDIT_CONFIGURATIONS: '/configurations/bulk-edit-configurations',
  BULK_DELETE_CONFIGURATIONS: '/configurations/bulk-delete-configurations',
  BULK_CONFIGURATIONS_HISTORY: '/configurations/bulk-config-history',
  DUPLICATE_ACTIVITY: '/duplicates/activity',
  DUPLICATE_FILE_SETUP: '/duplicates/file-setup',
  DUPLICATE_CREATE_SETUP: '/duplicates/create-setup',
  DUPLICATE_EDIT_FILE_SETUP: '/duplicates/edit-setup',
  DUPLICATE_DELETE_FILE_SETUP: '/duplicates/delete-setup',
  HELP: '/help',
  AUDIT_LOG: '/audit-log',
  CREATE_AUDIT_LOG: '/audit-log/create-audit-log',
  EDIT_AUDIT_LOG: '/audit-log/edit-audit-log',
  DELETE_AUDIT_LOG: '/audit-log/delete-audit-log',
}

const PageRoutes = () => (
  <Routes>
    <Route path='/' element={<Navigate to={PATHS.BATCH_ACTIVITY} />}></Route>
    <Route path={PATHS.LOGIN} element={<Auth />}></Route>
    <Route
      path={PATHS.HELP}
      element={
        <RequireAuth path={PATHS.HELP} redirectTo={PATHS.LOGIN}>
          <Help />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.BATCH_ACTIVITY}
      element={
        <RequireAuth path={PATHS.BATCH_ACTIVITY} redirectTo={PATHS.LOGIN}>
          <BatchActivity />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.VIEW_CONFIGURATIONS}
      element={
        <RequireAuth path={PATHS.VIEW_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <Configurations />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.CREATE_CONFIGURATIONS}
      element={
        <RequireAuth path={PATHS.CREATE_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <ConfigurationDetails type='add' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.EDIT_CONFIGURATIONS + '/:configId'}
      element={
        <RequireAuth path={PATHS.EDIT_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <ConfigurationDetails type='edit' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DELETE_CONFIGURATIONS + '/:configId'}
      element={
        <RequireAuth path={PATHS.DELETE_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <ConfigurationDetails type='delete' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.BULK_CREATE_CONFIGURATIONS}
      element={
        <RequireAuth path={PATHS.BULK_CREATE_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <BulkConfiguration type='add' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.BULK_EDIT_CONFIGURATIONS}
      element={
        <RequireAuth path={PATHS.BULK_EDIT_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <BulkConfiguration type='edit' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.BULK_DELETE_CONFIGURATIONS}
      element={
        <RequireAuth path={PATHS.BULK_DELETE_CONFIGURATIONS} redirectTo={PATHS.LOGIN}>
          <BulkConfiguration type='delete' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.BULK_CONFIGURATIONS_HISTORY}
      element={
        <RequireAuth path={PATHS.BULK_CONFIGURATIONS_HISTORY} redirectTo={PATHS.LOGIN}>
          <BulkConfigHistory />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.SCHEDULES_EXPORT}
      element={
        <RequireAuth path={PATHS.SCHEDULES_EXPORT} redirectTo={PATHS.LOGIN}>
          <ExportSchedules />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.SCHEDULES_EXPORT_CONTENT}
      element={
        <RequireAuth path={PATHS.SCHEDULES_EXPORT_CONTENT} redirectTo={PATHS.LOGIN}>
          <ExportScheduleContent />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.SCHEDULES_IMPORT}
      element={
        <RequireAuth path={PATHS.SCHEDULES_IMPORT} redirectTo={PATHS.LOGIN}>
          <ImportSchedules />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.SCHEDULES_IMPORT_CONTENT}
      element={
        <RequireAuth path={PATHS.SCHEDULES_IMPORT_CONTENT} redirectTo={PATHS.LOGIN}>
          <ImportScheduleContent />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.VIEW_SOURCES}
      element={
        <RequireAuth path={PATHS.VIEW_SOURCES} redirectTo={PATHS.LOGIN}>
          <Sources />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.SOURCE_DETAILS}
      element={
        <RequireAuth path={PATHS.SOURCE_DETAILS} redirectTo={PATHS.LOGIN}>
          <SourceDetailsContainer />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.CREATE_SOURCE}
      element={
        <RequireAuth path={PATHS.CREATE_SOURCE} redirectTo={PATHS.LOGIN}>
          <AddEditSource type='add' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.EDIT_SOURCE + '/:sourceId'}
      element={
        <RequireAuth path={PATHS.EDIT_SOURCE} redirectTo={PATHS.LOGIN}>
          <AddEditSource type='edit' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DELETE_SOURCE + '/:sourceId'}
      element={
        <RequireAuth path={PATHS.DELETE_SOURCE} redirectTo={PATHS.LOGIN}>
          <AddEditSource type='delete' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DUPLICATE_ACTIVITY}
      element={
        <RequireAuth path={PATHS.DUPLICATE_ACTIVITY} redirectTo={PATHS.LOGIN}>
          <DuplicateActivity />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DUPLICATE_FILE_SETUP}
      element={
        <RequireAuth path={PATHS.DUPLICATE_FILE_SETUP} redirectTo={PATHS.LOGIN}>
          <DuplicateFileSetup />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DUPLICATE_CREATE_SETUP}
      element={
        <RequireAuth path={PATHS.DUPLICATE_FILE_SETUP} redirectTo={PATHS.LOGIN}>
          <DuplicateCreateSetup />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DUPLICATE_EDIT_FILE_SETUP + '/:setupId'}
      element={
        <RequireAuth path={PATHS.DUPLICATE_EDIT_FILE_SETUP} redirectTo={PATHS.LOGIN}>
          <DuplicateEditSetup type='edit' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DUPLICATE_DELETE_FILE_SETUP + '/:setupId'}
      element={
        <RequireAuth path={PATHS.DUPLICATE_EDIT_FILE_SETUP} redirectTo={PATHS.LOGIN}>
          <DuplicateEditSetup type='delete' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.AUDIT_LOG}
      element={
        <RequireAuth path={PATHS.AUDIT_LOG} redirectTo={PATHS.LOGIN}>
          <AuditLog />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.CREATE_AUDIT_LOG}
      element={
        <RequireAuth path={PATHS.CREATE_AUDIT_LOG} redirectTo={PATHS.LOGIN}>
          <AuditLogDetails type='add' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.EDIT_AUDIT_LOG + '/:auditLogId'}
      element={
        <RequireAuth path={PATHS.EDIT_AUDIT_LOG} redirectTo={PATHS.LOGIN}>
          <AuditLogDetails type='edit' />
        </RequireAuth>
      }
    />
    <Route
      path={PATHS.DELETE_AUDIT_LOG + '/:auditLogId'}
      element={
        <RequireAuth path={PATHS.DELETE_AUDIT_LOG} redirectTo={PATHS.LOGIN}>
          <AuditLogDetails type='delete' />
        </RequireAuth>
      }
    />
  </Routes>
)
export default PageRoutes
