export const loggedInUserKey = 'username';
class StorageHelper {
  static getItem(key: string) {
    const item = localStorage.getItem(key);
    return typeof item === 'string' ? JSON.parse(item) : item;
  }

  static setItem(key: string, data: any) {
    localStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data));
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
export default StorageHelper;
