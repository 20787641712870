import React, { useEffect } from 'react'
import { KiteGrid, KiteGridCell, KiteIcon } from '@kite/react-kite'
import { copyToClipBoard } from '../../../utils/utils'
import CustomToast from '../../../components/toast/CustomToast'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import { fetchSourceDetails } from '../../../redux/Sources/SourceDetailSlice'
import { useSearchParams } from 'react-router-dom'

const SourceDetails = () => {
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const sourceID = searchParams.get('SourceID')
  const fileType = searchParams.get('FileType')
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const { sourceDetails = [] } = useSelector((state: RootState) => state.sourceDetails)

  const handleCopy = () => {
    copyToClipBoard('')
    setToastStatus(true)
    setToastMsg('Copied Source Path')
  }
  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSourceDetails({ SourceID: sourceID, FileType: fileType }))
  }, [sourceID, fileType])

  const sourceDetailsData = sourceDetails[0]

  return (
    <div className='details-container'>
      <KiteGrid>
        <KiteGridCell col={6}>
          <div>
            <div className='details-label'>Source Title</div>
            <div>{sourceDetailsData?.SourceTitle}</div>
            <div className='details-path'>
              <span>{sourceDetailsData?.SourcePath}</span>
              <span className='cell-icon' onClick={handleCopy}>
                <KiteIcon icon='ki-copy' />
              </span>
            </div>
          </div>
        </KiteGridCell>
        <KiteGridCell col={4}>
          <div>
            <div className='details-label'>Today’s Importing MonthDay Schedule (MM/YYYY)</div>
            <div>{sourceDetailsData?.ImportFileFilter}</div>
          </div>
        </KiteGridCell>
        <KiteGridCell col={2}>
          <div>
            <div className='details-label'>Enabled</div>
            <div>{sourceDetailsData?.Enabled ? 'True' : 'False'}</div>
          </div>
        </KiteGridCell>
      </KiteGrid>
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </div>
  )
}
export default SourceDetails
