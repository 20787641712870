import React from 'react'
import { KiteButton } from '@kite/react-kite'
import { IButtonProps } from '@kite/react-kite/dist/button/KiteButton/KiteButton'

import './ButtonStyles.scss'

interface ICustomButton extends IButtonProps {
  label?: string
  rounded?: boolean
  iconOnly?: boolean
  btnType?: string
}

const CustomButton = ({
  size,
  variant = 'primary',
  label = '',
  rounded = false,
  iconOnly = false,
  btnType = '',
  ...rest
}: ICustomButton) => {
  return (
    <KiteButton
      className={`custom-form-control-button ${btnType} ${size} ${rounded && 'rounded'} ${
        iconOnly && 'iconOnly'
      }`}
      variant={variant}
      {...rest}
    >
      {label}
    </KiteButton>
  )
}
export default CustomButton
