import React, { useEffect } from 'react'
import PageHeader from '../../../components/pageHeader/PageHeader'
import { ILink, Sizes } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import CustomSelect from '../../../components/customFormControls/select/Select'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'
import { KiteAlert, KiteCheckbox, KiteDialog, KiteProgressIndicator } from '@kite/react-kite'
import CustomButton from '../../../components/customFormControls/button/Button'
import {
  createSource,
  setError,
  setCreateEditStatus,
  updateSource,
  deleteSource,
} from '../../../redux/Sources/SourceDetailSlice'
import { useLocation, useNavigate } from 'react-router-dom'

const FileTypeOptions = [
  {
    label: 'Schedules',
    value: 1,
  },
  {
    label: 'Verifications',
    value: 2,
  },
]

const AddEditSource = ({ type }: { type?: string }) => {
  const isEditMode = type === 'edit'
  const isDeleteMode = type === 'delete'
  const isCreateMode = type === 'add'
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { state: routeState } = useLocation()
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState('')
  const { filtersData, status: filtersStatus } = useSelector(
    (state: RootState) => state.filtersData,
  )
  const { createEditSourceStatus, error } = useSelector((state: RootState) => state.sourceDetails)
  const [sourceData, setSourceData] = React.useState({
    ApplyBBFix: 0,
    EclipseSystem: '',
    Enabled: 0,
    IngestDays: 0,
    MSMInstanceID: '20',
    ScheduleType: 1,
    SourcePath: '',
    SourceTitle: '',
    ...(!isCreateMode && { SourceID: null, ConfigurationCount: 0 }),
  })
  const [sourceDataError, setSourceDataError] = React.useState({
    EclipseSystem: '',
    IngestDays: '',
    SourcePath: '',
    SourceTitle: '',
  })
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Sources',
      link: PATHS.VIEW_SOURCES,
    },
    ...(isCreateMode
      ? [
          {
            label: 'Add New Source',
            link: PATHS.CREATE_SOURCE,
          },
        ]
      : isEditMode
      ? [
          {
            label: 'Edit Source',
            link: PATHS.EDIT_SOURCE,
          },
        ]
      : [
          {
            label: 'Delete Source',
            link: PATHS.DELETE_SOURCE,
          },
        ]),
  ]

  const handleChange = (event: any) => {
    const name = event?.target?.name
    let value = event?.target?.value || null
    if (name === 'ApplyBBFix' || name === 'Enabled') {
      value = event?.target?.checked ? 1 : 0
    }
    if (name === 'IngestDays' || name === 'ScheduleType') {
      value = Number(value)
    }
    setSourceData({ ...sourceData, [name]: value })
    setSourceDataError({ ...sourceDataError, [name]: '' })
  }

  const validateFields = () => {
    let valid = true
    const error = { ...sourceDataError }
    const { EclipseSystem, IngestDays, SourcePath, SourceTitle } = sourceData
    if (!EclipseSystem) {
      error.EclipseSystem = 'Please select XGL'
      valid = false
    }
    if (!IngestDays) {
      error.IngestDays = 'Please enter ingest days'
      valid = false
    }
    if (!SourcePath) {
      error.SourcePath = 'Please enter source path'
      valid = false
    }
    if (!SourceTitle) {
      error.SourceTitle = 'Please enter source title'
      valid = false
    }
    return { valid, error }
  }

  const handleCreateSource = () => {
    const { valid, error } = validateFields()
    if (valid) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(createSource(sourceData))
    } else {
      setSourceDataError({ ...error })
    }
  }

  const handleSaveSource = () => {
    const { valid, error } = validateFields()
    if (valid) {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateSource({
          ApplyBBFix: sourceData?.ApplyBBFix,
          EclipseSystem: sourceData?.EclipseSystem,
          Enabled: sourceData?.Enabled,
          IngestDays: sourceData?.IngestDays,
          SourceID: sourceData?.SourceID,
          SourceTitle: sourceData?.SourceTitle,
        }),
      )
    } else {
      setSourceDataError({ ...error })
    }
  }

  const handleDeleteSource = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(deleteSource({ SourceID: sourceData?.SourceID }))
  }

  const handleClearError = () => {
    dispatch(setError(''))
  }

  const handleDialogClose = () => {
    setDialogStatus(false)
    setDialogMessage('')
    setTimeout(()=>{
      navigate(PATHS.VIEW_SOURCES)
    })
  }

  useEffect(() => {
    if (!isCreateMode && !routeState) {
      navigate(PATHS.VIEW_SOURCES)
      return
    }
    if (!isCreateMode) {
      const {
        ApplyBBFix,
        EclipseSystem,
        Enabled,
        IngestDays,
        SourceID,
        SourceTitle,
        SourceType,
        SourcePath,
        ConfigurationCount,
      } = routeState
      setSourceData({
        MSMInstanceID: '',
        ScheduleType: SourceType === 'MSM Schedule Source' ? 1 : 2,
        SourcePath,
        ApplyBBFix,
        EclipseSystem,
        Enabled,
        IngestDays: IngestDays,
        SourceID,
        SourceTitle,
        ConfigurationCount,
      })
    }
  }, [])

  useEffect(() => {
    if (createEditSourceStatus === 'succeeded') {
      setDialogStatus(true)
      setDialogMessage(
        isCreateMode
          ? 'Created new source successfully'
          : isEditMode
          ? 'Updated source details successfully'
          : 'Deleted source successfully',
      )
    }
  }, [createEditSourceStatus])

  useEffect(() => {
    return () => {
      dispatch(setCreateEditStatus('idle'))
    }
  }, [])

  const isLoading = filtersStatus === 'loading'
  const isEligibleToEnable = !!sourceData?.ConfigurationCount

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader
          header={isCreateMode ? 'Add New Source' : isEditMode ? 'Edit Source' : 'Delete Source'}
          navLinks={navLinks}
        ></PageHeader>
        <div className='setup-container'>
          <div className='setup-content-container'>
            {!isCreateMode && (
              <div className='field'>
                <CustomTextInput
                  name='SourceId'
                  size={Sizes.Small}
                  label='Source Id'
                  onChange={handleChange}
                  value={sourceData?.SourceID || ''}
                  disabled
                />
              </div>
            )}
            {isCreateMode && (
              <div className='field'>
                <CustomSelect
                  id='ScheduleType'
                  name='ScheduleType'
                  size={Sizes.Small}
                  label='File Type'
                  value={sourceData?.ScheduleType}
                  onChange={handleChange}
                >
                  {(FileTypeOptions || []).map((option, index) => (
                    <option key={index} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </CustomSelect>
              </div>
            )}
            <div className='field'>
              <CustomSelect
                id='EclipseSystem'
                name='EclipseSystem'
                size={Sizes.Small}
                label='XGL'
                value={sourceData?.EclipseSystem}
                onChange={handleChange}
                errorMessage={sourceDataError?.EclipseSystem}
              >
                {(filtersData?.Eclipse || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className='field'>
              <CustomTextInput
                name='SourceTitle'
                size={Sizes.Small}
                label='SourceTitle'
                onChange={handleChange}
                value={sourceData?.SourceTitle || ''}
                errorMessage={sourceDataError?.SourceTitle}
              />
            </div>
            <div className='field'>
              <CustomTextInput
                name='SourcePath'
                size={Sizes.Small}
                label='SourcePath'
                onChange={handleChange}
                value={sourceData?.SourcePath || ''}
                errorMessage={sourceDataError?.SourcePath}
                disabled={isEditMode}
              />
            </div>
            <div className='field'>
              <CustomTextInput
                name='IngestDays'
                type='number'
                size={Sizes.Small}
                label='IngestDays'
                onChange={handleChange}
                value={sourceData?.IngestDays || ''}
                errorMessage={sourceDataError?.IngestDays}
              />
            </div>
            <div className='field'>
              <KiteCheckbox
                checked={!!sourceData?.ApplyBBFix}
                id='ApplyBBFix'
                label='Apply Bulletin Board Fix'
                name='ApplyBBFix'
                onChange={handleChange}
              />
            </div>
            <div className='field'>
              <KiteCheckbox
                checked={!!sourceData?.Enabled}
                id='enabled'
                label='Enabled'
                name='Enabled'
                onChange={handleChange}
                disabled={!isEligibleToEnable}
              />
            </div>
            {!isEligibleToEnable && (
              <div className='alert-element'>
                <KiteAlert
                  autoFocus={false}
                  level='page'
                  type='caution'
                  description='Creating a source config does not enable it. You will need to add configuration detail items.'
                />
              </div>
            )}
            {error && (
              <div className='alert-element'>
                <KiteAlert
                  onClose={handleClearError}
                  autoFocus={false}
                  level='page'
                  type='error'
                  description={error}
                  dismissible
                />
              </div>
            )}
            <div className='setup-actions'>
              {isCreateMode && (
                <CustomButton
                  loading={createEditSourceStatus === 'loading'}
                  label='Add Source'
                  onClick={handleCreateSource}
                ></CustomButton>
              )}
              {isEditMode && (
                <CustomButton
                  loading={createEditSourceStatus === 'loading'}
                  label='Save'
                  onClick={handleSaveSource}
                ></CustomButton>
              )}
              {isDeleteMode && (
                <CustomButton
                  loading={createEditSourceStatus === 'loading'}
                  label='Delete'
                  btnType='danger'
                  onClick={handleDeleteSource}
                ></CustomButton>
              )}
            </div>
          </div>
          {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
          <KiteDialog
            id='confirmDialog'
            className='confirm-dialog'
            open={dialogStatus}
            title="Success"
            onClose={handleDialogClose}
            primaryBtnLabel='Ok'
            onPrimaryBtnClick={handleDialogClose}
          >
            <div className='kite-dialog__content-group'>
              {dialogMessage}
            </div>
          </KiteDialog>
        </div>
      </div>
    </div>
  )
}
export default AddEditSource
