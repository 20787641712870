export enum Sizes {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}
export enum LabelPositions {
  Top = 'top',
  Left = 'left',
}
export type ILink = { link: string; label: string; disabled?: boolean; children?: Array<ILink> }
