export interface IAuditLogState {
  auditLogItems: IAuditLogItemsResponse
  status: string
  error: any
  auditLogDetailsError: any
  auditLogDetailsStatus: string
  auditLogModifyError: any
  auditLogModifyStatus: string
  selectedAuditLogDetails: IAuditLogPayload | null
  tableFilters: IAuditLogFilters
  tablePagination: {
    PageNumber: number
    RecordsPerPage: number
  }
  tableSort: {
    SortBy: any
    SortOrder: any
  }
}
export interface IAuditLogItemsResponse {
  AuditLogList: IAuditLogItem[]
  MaxCount: number
}
export interface IAuditLogItem {
  AuditLogId: string
  StartTime: string
  EndTime: string
  Status: string
  NetCodeCount: number
  NetCodes: string
  ZoneCodes: string
  MsmInstanceId: string
  TimeZoneID: string
  User: string
}
export interface IAuditLogFilters {
  ShowLastDays: number
  NetCode?: Array<string>
  ZoneCode?: Array<string>
  Status?: string | null
  StartTime?: string | null
  EndTime?: string | null
  TimeZoneID?: string | null
  MSMInstanceID?: string | null
  User?: string | null
  IsArchived?: number
}
export interface IAuditLogPayload {
  AuditLogId: string
  StartTime: any
  EndTime: any
  Status: string
  TimeZoneID: string
  Networks: IAuditLogNetwork[]
  Zones: IAuditLogZone[]
  MsmInstanceId: number | null
  Pid: string
  Login: string
  Links: IAuditLogLinkPayload[]
  Attachments: IAuditLogAttachmentPayload[]
  Notes: string
}
export interface IAuditLogDetails {
  AuditLogId: string
  StartTime: any
  EndTime: any
  Status: string
  TimeZoneID: string
  Networks: string[]
  Zones: string[]
  MsmInstanceId: number | null
  Pid: string
  Login: string
  Links: IAuditLogLink[]
  Attachments: IAuditLogAttachment[]
  Notes: string
  CreatedBy?: string
  IsArchived?: boolean
}

export interface IAuditLogNetwork {
  AuditLogNetworkId: string
  NetCode: string
  IsDeleted: number
}

export interface IAuditLogZone {
  AuditLogZoneId: string
  ZoneCode: string
  IsDeleted: number
}

export interface IAuditLogAttachmentPayload {
  AuditLogItemId: string
  DocName: string
  IsDeleted: number
}

export interface IAuditLogAttachment {
  AuditLogItemId: string
  DocName: string
  IsDeleted: number
  Status: string
  Error?: string
}

export interface IAuditLogLinkPayload {
  AuditLogItemId: string
  Address: string
  IsDeleted: number
}
export interface IAuditLogLink {
  AuditLogItemId: string
  Address: string
  IsDeleted: number
  Status: string
}
export interface IPresignedURLResponse {
  url: string
  fields: IPresignedURLResponseFields
}

export interface IPresignedURLResponseFields {
  key: string
  AWSAccessKeyId: string
  'x-amz-security-token': string
  policy: string
  signature: string
}
export enum FILE_PROGRESS_STATUS {
  NEW = 'NEW',
  OLD = 'OLD',
  PENDING = 'PENDING',
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
export enum RELATED_LINKS_STATUS {
  NEW = 'NEW',
  OLD = 'OLD',
}
