import React from 'react'
import { KiteSelect } from '@kite/react-kite'
import { LabelPositions, Sizes } from '../../../types'

import './SelectStyles.scss'

interface ICustomSelect {
  size: Sizes
  labelPos?: LabelPositions
  children: React.ReactNode | string
  className?: string
  disabled?: boolean
  errorMessage?: string
  id: string
  label: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any
  placeholder?: string
  tooltip?: React.ReactNode | string
  value: any
}

const CustomSelect = ({
  size = Sizes.Small,
  labelPos = LabelPositions.Top,
  children,
  label,
  ...rest
}: ICustomSelect) => {
  return (
    <div className={`custom-form-control-select ${labelPos} ${size}`}>
      {label && <label>{label}</label>}
      <KiteSelect {...rest} label=''>
        <>
          <option value=''>
            Select
          </option>
          {children}
        </>
      </KiteSelect>
    </div>
  )
}
export default CustomSelect
