import React from 'react'
import { KiteDynamicHint } from '@kite/react-kite'
import { LabelPositions, Sizes } from '../../../types'
import { Popover } from '@mui/material'
import CustomButton from '../button/Button'
import CustomAutoComplete from '../autoComplete/AutoComplete'
import CustomTextInput from '../textInput/TextInput'

import './CustomSelectInput.scss'

interface ICustomTextInput {
  children?:
    | React.ReactElement<typeof KiteDynamicHint>
    | React.ReactElement<typeof KiteDynamicHint>[]
  className?: string
  disabled?: boolean
  dynamicHintId?: string | number
  errorId?: string
  errorMessage?: string | number
  id?: string
  inputRef?: React.RefObject<any>
  label?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  onFocus?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  placeholder?: string
  tooltip?: string | string[] | React.ReactElement | React.ReactElement[]
  confirm?: boolean
  value?: any
  size: Sizes
  labelPos?: LabelPositions
  helpText?: string
  header?: string
  valueLength?: number | undefined
  type?: string
  limitTags?: number
}

const CustomSelectInput = ({
  size,
  label,
  labelPos = LabelPositions.Top,
  value,
  onChange,
  name,
  header,
  helpText,
  valueLength,
  type,
  limitTags,
}: ICustomTextInput) => {
  const [moreFiltersEl, setMoreFiltersEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(moreFiltersEl)
  const id = open ? 'select-values' : undefined

  const handleCloseSelect = () => {
    setMoreFiltersEl(null)
  }

  const handleOpenSelect = (event: React.MouseEvent<any>) => {
    setMoreFiltersEl(event.currentTarget)
  }

  const handleClear = () => {
    const customClearEvent: any = { target: { name: name, value: [] } }
    if (onChange) {
      onChange(customClearEvent)
    }
  }

  return (
    <>
      <div className={`custom-form-control-input ${labelPos} ${size}`}>
        {label && <label>{label}</label>}
        <div className='auto-complete-input' onClick={handleOpenSelect}>
          <CustomTextInput
            size={Sizes.Small}
            placeholder='Select'
            value={value?.length ? value?.length + ' selected' : 'Select'}
            readOnly
          />
        </div>
      </div>
      <Popover
        id={id}
        className='custom-select-popover'
        open={open}
        anchorEl={moreFiltersEl}
        onClose={handleCloseSelect}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {header && <div className='select-title'>{header}</div>}
        <div className='select-content'>
          <CustomAutoComplete
            name={name || ''}
            value={value}
            onChange={onChange}
            valueLength={valueLength}
            type={type}
            limitTags={limitTags}
          />
          {helpText && <div className='select-input-help'>{helpText}</div>}
        </div>
        <div className='footer-actions'>
          <div className='action'>
            <CustomButton
              size={Sizes.Medium}
              variant='primary'
              label='Close'
              onClick={handleCloseSelect}
            ></CustomButton>
          </div>
          <div className='action'>
            <CustomButton
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
              onClick={handleClear}
            ></CustomButton>
          </div>
        </div>
      </Popover>
    </>
  )
}
export default CustomSelectInput
