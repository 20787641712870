import { addDays, format, formatISO, parseISO, subDays } from 'date-fns'

export const getCustomMonth = (strDate: string) => {
  if (!strDate) {
    return
  }
  const date: any = strDate.match(/(\d+)/g)
  const mm = Number(date?.[1])
  if (mm < 10) {
    return mm
  } else if (mm === 10) {
    return 'A'
  } else if (mm === 11) {
    return 'B'
  } else {
    return 'C'
  }
}

export const getDate = (strDate: string) => {
  if (!strDate) {
    return ''
  }
  const date: any = strDate.match(/(\d+)/g)
  return date?.[2]
}
export const groupByKey = (input: any[], key: string) => {
  return input.reduce((acc, currentValue) => {
    const groupKey = currentValue[key]
    if (!acc[groupKey]) {
      acc[groupKey] = []
    }
    acc[groupKey].push(currentValue)
    return acc
  }, {})
}
export const formatTime = (time: number) => {
  let seconds = time || 0
  seconds = Number(seconds)
  seconds = Math.abs(seconds)
  const m = Math.floor(seconds / 60)
  const s = Math.floor(seconds % 60)
  const formattedSeconds = s > 9 ? s : '0' + s
  return m + ':' + formattedSeconds
}
export const formatDateWithTime = (date: string, isUTC = true) => {
  if (!date) {
    return ''
  }
  const utcDate = date + (isUTC ? ' UTC' : '')
  return format(new Date(utcDate), 'MM/dd/yyyy hh:mm:ss a')
}
export const formatDateWithTimeNoSecs = (date: string, isUTC = true) => {
  if (!date) {
    return ''
  }
  const utcDate = date + (isUTC ? ' UTC' : '')
  return format(new Date(utcDate), 'MM/dd/yyyy hh:mm a')
}
export const formatDateWithCustomFormat = (date: string, dateFormat: string) => {
  return format(new Date(date), dateFormat)
}
export const formatDate = (date: string) => {
  return format(new Date(date), 'yyyy-MM-dd')
}
export const formatStringToDate = (date: string) => {
  return parseISO(date)
}
export const formatStringToIso = (date: string) => {
  return formatISO(new Date(date))
}
export const addDaysToDate = (days: number) => {
  return formatISO(addDays(new Date(), days || 0))
}
export const subDaysFromDate = (days: number) => {
  return format(subDays(new Date(), days || 0), 'yyyy-MM-dd')
}
export const copyToClipBoard = (text: string) => {
  navigator.clipboard.writeText(text)
}
export const downloadURI = (uri: string, name?: string, target?: string) => {
  const link = document.createElement('a')
  if (name) {
    link.download = name
  }
  if (target) {
    link.target = target
  }
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const descendingComparator = (a: any, b: any, _orderBy: string) => {
  if (b[_orderBy] < a[_orderBy]) {
    return -1
  }
  if (b[_orderBy] > a[_orderBy]) {
    return 1
  }
  return 0
}
export const getComparator = (_order: any, _orderBy: string) => {
  return _order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, _orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, _orderBy)
}
export const stableSort = (array: Array<any>, comparator: any) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const _order = comparator(a[0], b[0])
    if (_order !== 0) return _order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
export const recordsAfterPagingAndSorting = (
  records: any = [],
  order: string,
  orderBy: string,
  page: number,
  rowsPerPage: number,
) =>
  stableSort(records, getComparator(order, orderBy)).slice(
    rowsPerPage * (page - 1),
    rowsPerPage * page,
  )
export const scrollToView = (selector: string) => {
  document.querySelector(selector)?.scrollIntoView()
}
