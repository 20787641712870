import React from 'react'
import BasicBreadcrumbs from '../breadcrumbs/BreadCrumbs'
import { ILink } from '../../types'

import './PageHeaderStyles.scss'

interface IPageHeaderProps {
  header: string
  children?: any
  navLinks?: ILink[]
}

const PageHeader = ({ header, navLinks = [], children }: IPageHeaderProps) => {
  return (
    <>
      {navLinks?.length > 0 && <BasicBreadcrumbs links={navLinks} />}
      <div className='header'>
        <h2>{header}</h2>
        <div className='actions'>{children}</div>
      </div>
    </>
  )
}
export default PageHeader
