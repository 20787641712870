import React from 'react'
import { LabelPositions, Sizes } from '../../../types'
import { KiteIcon } from '@kite/react-kite'

import './FileInputStyles.scss'

interface ICustomFileInput {
  className?: string
  disabled?: boolean
  errorMessage?: string | number
  id?: string
  label?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  onFocus?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  placeholder?: string
  tooltip?: string | string[] | React.ReactElement | React.ReactElement[]
  value?: any
  size?: Sizes
  labelPos?: LabelPositions
  multiple?: boolean
}

const CustomFileInput = ({
  size = Sizes.Small,
  label,
  labelPos = LabelPositions.Top,
  multiple = false,
  value,
  errorMessage,
  ...rest
}: ICustomFileInput) => {
  return (
    <div
      className={`custom-form-control-input ${labelPos} ${size} ${
        errorMessage ? 'error-control' : ''
      }`}
    >
      {label && <label>{label}</label>}
      <div className='file-input-container'>
        <div className={value ? 'file-placeholder filled' : 'file-placeholder'}>
          <KiteIcon className='file-icon' icon='ki-attachment' size='1rem' />
          {value ? value : 'Click to choose file'}
        </div>
        <input {...rest} type='file' multiple={multiple} />
      </div>
      {errorMessage && <div className="kite-form-control__error sm">{errorMessage}</div>}
    </div>
  )
}
export default CustomFileInput
