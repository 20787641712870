import React, { useEffect } from 'react'
import { ILink, Sizes } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import PageHeader from '../../../components/pageHeader/PageHeader'
import CustomSelect from '../../../components/customFormControls/select/Select'
import { IDuplicateCreateEditData } from '../../../redux/Duplicates/DuplicatesFileSetupModels'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useLocation, useNavigate } from 'react-router-dom'

import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'

import MultiSelect from '../../../components/customFormControls/multiSelect/MultiSelect'
import { KiteAlert, KiteCheckbox, KiteDialog, KiteProgressIndicator } from '@kite/react-kite'
import {
  createDuplicateSetup,
  fetchConfigData,
  resetCreateSetupStatus,
  updateConfigsData,
} from '../../../redux/Duplicates/DuplicatesFileSetupSlice'
import CustomButton from '../../../components/customFormControls/button/Button'
import { getUserName } from '../../Auth/AuthUtils'

const DuplicateCreateSetup = () => {
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Duplicate File Setup',
      link: PATHS.DUPLICATE_FILE_SETUP,
    },
    {
      label: 'Create Duplicate Setup',
      link: PATHS.DUPLICATE_CREATE_SETUP,
    },
  ]

  const { state: routeState } = useLocation()
  const dispatch: AppDispatch = useDispatch()
  const { user } = useAuthenticator()
  const navigate = useNavigate()
  const { selectedDuplicateFile, duplicateConfigData, createEditStatus, configStatus, error } =
    useSelector((state: RootState) => state.duplicatesFileSetupData)
  const { filtersData, status: filtersStatus } = useSelector(
    (state: RootState) => state.filtersData,
  )
  const [duplicatesFileSetupData, setDuplicatesFileSetupData] =
    React.useState<IDuplicateCreateEditData>(selectedDuplicateFile)

  const [createSetupError, setCreateSetupError] = React.useState({
    SelectedZoneCodes: '',
    SourceID: '',
    TargetPath: '',
  })
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState('')

  const handleChange = (event: any) => {
    const name = event?.target?.name
    let value = event?.target?.value || null
    const currentData = { ...duplicatesFileSetupData }
    if (name === 'ScheduleTypeID') {
      value = Number(value)
      currentData.SourceID = 0
      currentData.SelectedZoneCodes = ''
      dispatch(updateConfigsData([]))
    }
    if (name === 'SourceID') {
      currentData.SelectedZoneCodes = ''
      dispatch(updateConfigsData([]))
      fetchDuplicateConfigs({ FileType: currentData?.ScheduleTypeID, SourceID: value })
    }
    if (name === 'SelectedZoneCodes') {
      value = value?.join(',')
    }
    if (name === 'Enabled') {
      value = event?.target?.checked ? 1 : 0
    }
    setCreateSetupError({ ...createSetupError, [name]: '' })
    setDuplicatesFileSetupData({ ...currentData, [name]: value })
  }

  const fetchDuplicateConfigs = (payload: { FileType: number; SourceID: string }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchConfigData(payload))
  }

  const validateFields = () => {
    let valid = true
    const error = { ...createSetupError }
    const { SelectedZoneCodes, SourceID, TargetPath } = duplicatesFileSetupData
    if (!SelectedZoneCodes) {
      error.SelectedZoneCodes = 'Please select zones'
      valid = false
    }
    if (!SourceID) {
      error.SourceID = 'Please select source'
      valid = false
    }
    if (!TargetPath) {
      error.TargetPath = 'Please enter target path'
      valid = false
    }
    return { valid, error }
  }

  const createSetup = () => {
    const { Enabled, SelectedZoneCodes, SourceID, ScheduleTypeID, TargetPath } =
      duplicatesFileSetupData
    const { valid, error } = validateFields()
    if (valid) {
      const payload = {
        CreatedByPID: getUserName(user),
        Enabled,
        SelectedZoneCodes,
        SourceID,
        ScheduleTypeID,
        TargetPath,
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(createDuplicateSetup(payload))
    } else {
      setCreateSetupError({ ...error })
    }
  }

  const handleDialogClose = () => {
    setDialogStatus(false)
    setDialogMessage('')
    setTimeout(() => {
      navigate(PATHS.DUPLICATE_FILE_SETUP)
    })
  }

  useEffect(() => {
    return () => {
      dispatch(resetCreateSetupStatus('idle'))
    }
  }, [])

  useEffect(() => {
    if (createEditStatus === 'succeeded') {
      setDialogStatus(true)
      setDialogMessage('Created duplicate setup successfully')
    }
  }, [createEditStatus])

  const isLoading = filtersStatus === 'loading' || configStatus === 'loading'
  const isScheduleSource = duplicatesFileSetupData?.ScheduleTypeID === 1

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader header={'Create Duplicate Setup'} navLinks={navLinks}></PageHeader>
        <div className='setup-container'>
          <div className='setup-content-container'>
            <div className='field'>
              <CustomSelect
                id='scheduleTypeID'
                name='ScheduleTypeID'
                size={Sizes.Small}
                label='Source Type'
                value={duplicatesFileSetupData?.ScheduleTypeID}
                onChange={handleChange}
              >
                {(filtersData.ScheduleTypes || []).map((option, index) => (
                  <option key={index} value={option?.IndexValue}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className='field'>
              <CustomSelect
                id='sourceID'
                name='SourceID'
                size={Sizes.Small}
                label='Production Source Path'
                value={duplicatesFileSetupData?.SourceID}
                onChange={handleChange}
                errorMessage={createSetupError?.SourceID}
              >
                {(
                  (isScheduleSource ? filtersData?.SchedFromPath : filtersData?.VerifFromPath) || []
                ).map((option, index) => (
                  <option key={index} value={option?.ID}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className='field'>
              <MultiSelect
                id='selectedZoneCodes'
                name='SelectedZoneCodes'
                size='small'
                label='Zone Codes'
                value={
                  duplicatesFileSetupData?.SelectedZoneCodes
                    ? duplicatesFileSetupData?.SelectedZoneCodes?.split(',')
                    : []
                }
                onChange={handleChange}
                errorMessage={createSetupError?.SelectedZoneCodes}
              >
                {(duplicateConfigData || []).map((configData) => (
                  <MenuItem
                    key={isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode}
                    value={isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode}
                    disableRipple
                  >
                    <Checkbox
                      disableRipple
                      checked={
                        (duplicatesFileSetupData?.SelectedZoneCodes?.split(',') || []).indexOf(
                          isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode,
                        ) > -1
                      }
                    />
                    <span>
                      {isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode}
                    </span>
                  </MenuItem>
                ))}
              </MultiSelect>
            </div>
            <div className='field'>
              <CustomTextInput
                name='TargetPath'
                size={Sizes.Small}
                label='Target Path'
                onChange={handleChange}
                value={duplicatesFileSetupData?.TargetPath || ''}
                errorMessage={createSetupError?.TargetPath}
              />
            </div>
            <div className='field'>
              <KiteCheckbox
                checked={!!duplicatesFileSetupData?.Enabled}
                id='enabled'
                label='Enabled'
                name='Enabled'
                onChange={handleChange}
              />
            </div>
            {error && (
              <div className='alert-element'>
                <KiteAlert level='page' autoFocus={false} type='error' description={error} />
              </div>
            )}
            <div className='setup-actions'>
              <CustomButton
                loading={createEditStatus === 'loading'}
                label='Create Duplicate Setup'
                onClick={createSetup}
              ></CustomButton>
            </div>
          </div>
        </div>
        <KiteDialog
          id='confirmDialog'
          className='confirm-dialog'
          open={dialogStatus}
          title='Success'
          onClose={handleDialogClose}
          primaryBtnLabel='Ok'
          onPrimaryBtnClick={handleDialogClose}
        >
          <div className='kite-dialog__content-group'>{dialogMessage}</div>
        </KiteDialog>
        {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
      </div>
    </div>
  )
}

export default DuplicateCreateSetup
