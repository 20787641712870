import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IImportScheduleContentData,
  IImportScheduleData,
  IImportSchedulesDataState,
} from './ImportSchedulesModels'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import ApiService from '../../services/ApiService'

const initialState: IImportSchedulesDataState = {
  schedulesData: [],
  status: 'idle',
  error: null,
  contentsData: [],
  contentsError: null,
  contentsStatus: 'idle',
  tableFilters: {
    FileType: 'Schedules',
    DataCenter: 'North Carolina',
  },
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}

export const fetchData = createAsyncThunk('import/fetchSchedulesData', async (params) => {
  const response = await ApiService.getData(appApiEndPoints.importSchedulesDataURL, params)
  return response.data
})
export const fetchImportScheduleContentData = createAsyncThunk(
  'export/fetchImportScheduleContentData',
  async (params) => {
    const response = await ApiService.getData(appApiEndPoints.importScheduleContentDataURL, params)
    return response.data
  },
)

export const ImportSchedulesDataSlice = createSlice({
  name: 'importSchedulesData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    setContentsError: (state, action) => {
      state.contentsError = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.schedulesData = action?.payload as IImportScheduleData[]
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch schedules data'
      })
      .addCase(fetchImportScheduleContentData.pending, (state) => {
        state.contentsStatus = 'loading'
      })
      .addCase(fetchImportScheduleContentData.fulfilled, (state, action) => {
        state.contentsStatus = 'succeeded'
        state.contentsData = action?.payload as IImportScheduleContentData[]
      })
      .addCase(fetchImportScheduleContentData.rejected, (state, action) => {
        state.contentsStatus = 'failed'
        state.contentsError =
          action?.error?.message || 'Failed to fetch import schedule content data'
      })
  },
})
export const { setError, updateFilters, updatePagination, updateSort, setContentsError } =
  ImportSchedulesDataSlice.actions
export default ImportSchedulesDataSlice.reducer
