import React, { useEffect } from 'react'
import { ILink, Sizes } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import PageHeader from '../../../components/pageHeader/PageHeader'
import CustomSelect from '../../../components/customFormControls/select/Select'
import { IDuplicateCreateEditData } from '../../../redux/Duplicates/DuplicatesFileSetupModels'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useLocation, useNavigate } from 'react-router-dom'

import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'

import MultiSelect from '../../../components/customFormControls/multiSelect/MultiSelect'
import { KiteCheckbox, KiteDialog, KiteProgressIndicator } from '@kite/react-kite'
import {
  activeZoneCodeDetails,
  deleteDuplicateSetup,
  editDuplicateSetup,
  fetchConfigData,
  resetCreateSetupStatus,
  updateConfigsData,
} from '../../../redux/Duplicates/DuplicatesFileSetupSlice'
import CustomButton from '../../../components/customFormControls/button/Button'
import { formatDateWithTime } from '../../../utils/utils'
import { getUserName } from '../../Auth/AuthUtils'

const DuplicateEditSetup = ({ type }: { type: string }) => {
  const isEditMode = type === 'edit'
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Duplicate File Setup',
      link: PATHS.DUPLICATE_FILE_SETUP,
    },
    ...(isEditMode
      ? [
          {
            label: 'Edit Duplicate Setup',
            link: PATHS.DUPLICATE_EDIT_FILE_SETUP,
          },
        ]
      : [
          {
            label: 'Delete Duplicate Setup',
            link: PATHS.DUPLICATE_DELETE_FILE_SETUP,
          },
        ]),
  ]

  const { state: routeState } = useLocation()
  const dispatch: AppDispatch = useDispatch()
  const { user } = useAuthenticator()
  const navigate = useNavigate()
  const {
    selectedDuplicateFile,
    duplicateConfigData,
    createEditStatus,
    configStatus,
    activeZoneCodeStatus,
    activeZoneCodesData,
  } = useSelector((state: RootState) => state.duplicatesFileSetupData)
  const [duplicatesFileSetupData, setDuplicatesFileSetupData] =
    React.useState<IDuplicateCreateEditData>(selectedDuplicateFile)
  const { filtersData, status: filtersStatus } = useSelector(
    (state: RootState) => state.filtersData,
  )
  const [createSetupError, setCreateSetupError] = React.useState({
    SelectedZoneCodes: '',
    SourceID: '',
    TargetPath: '',
  })
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState('')

  const handleChange = (event: any) => {
    const name = event?.target?.name
    let value = event?.target?.value || null
    const currentData = { ...duplicatesFileSetupData }
    if (name === 'SourceType') {
      currentData.SourceID = 0
      currentData.SelectedZoneCodes = ''
      dispatch(updateConfigsData([]))
    }
    if (name === 'SourceID') {
      currentData.SelectedZoneCodes = ''
      dispatch(updateConfigsData([]))
      fetchDuplicateConfigs({ FileType: currentData?.ScheduleTypeID, SourceID: value })
    }
    if (name === 'SelectedZoneCodes') {
      value = value?.join(',')
    }
    if (name === 'Enabled') {
      value = event?.target?.checked ? 1 : 0
    }
    setCreateSetupError({ ...createSetupError, [name]: '' })
    setDuplicatesFileSetupData({ ...currentData, [name]: value })
  }

  const fetchDuplicateConfigs = (payload: { FileType: number; SourceID: string }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchConfigData(payload))
  }

  const validateFields = () => {
    let valid = true
    const error = { ...createSetupError }
    const { SelectedZoneCodes, SourceID, TargetPath } = duplicatesFileSetupData
    if (!SelectedZoneCodes) {
      error.SelectedZoneCodes = 'Please select zones'
      valid = false
    }
    if (!SourceID) {
      error.SourceID = 'Please select source'
      valid = false
    }
    if (!TargetPath) {
      error.TargetPath = 'Please enter target path'
      valid = false
    }
    return { valid, error }
  }

  const saveSetup = () => {
    const { Enabled, SelectedZoneCodes, TargetPath, SetupID } = duplicatesFileSetupData
    const { valid, error } = validateFields()
    if (valid) {
      const payload = {
        SetupID,
        UpdatedByPID: getUserName(user),
        Enabled,
        SelectedZoneCodes,
        TargetPath,
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(editDuplicateSetup(payload))
    } else {
      setCreateSetupError({ ...error })
    }
  }

  const deleteSetup = () => {
    const { SetupID } = duplicatesFileSetupData
    const payload = {
      SetupID,
      UpdatedByPID: getUserName(user),
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(deleteDuplicateSetup(payload))
  }

  const handleDialogClose = () => {
    setDialogStatus(false)
    setDialogMessage('')
    setTimeout(() => {
      navigate(PATHS.DUPLICATE_FILE_SETUP)
    })
  }

  useEffect(() => {
    // Check if its edit mode and row data exists or not
    if (!routeState) {
      navigate(PATHS.DUPLICATE_FILE_SETUP)
    }
    if (routeState) {
      const { SetupID, SourceID, ScheduleTypeID } = routeState
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(activeZoneCodeDetails({ SetupID, SourceID }))
      fetchDuplicateConfigs({ FileType: ScheduleTypeID, SourceID })
    }
  }, [])

  useEffect(() => {
    if (activeZoneCodeStatus === 'succeeded') {
      const {
        SetupID,
        SourceID,
        TargetPath,
        Enabled,
        ScheduleTypeID,
        CreatedByPID,
        CreatedOn,
        LastUpdatedByPID,
        LastUpdatedOn,
        LookupSource,
      } = routeState
      const selectedZones = (activeZoneCodesData || []).map((code) => code?.ZoneCode)?.join(',')
      setDuplicatesFileSetupData({
        ScheduleTypeID,
        SelectedZoneCodes: selectedZones,
        SourceID,
        TargetPath,
        Enabled,
        SetupID,
        CreatedByPID,
        CreatedOn,
        LastUpdatedByPID,
        LastUpdatedOn,
        LookupSource,
      })
    }
  }, [activeZoneCodeStatus])

  useEffect(() => {
    return () => {
      dispatch(resetCreateSetupStatus('idle'))
    }
  }, [])

  useEffect(() => {
    if (createEditStatus === 'succeeded') {
      setDialogStatus(true)
      setDialogMessage(
        isEditMode
          ? 'Updated duplicate setup details successfully'
          : 'Deleted duplicate setup successfully',
      )
    }
  }, [createEditStatus])

  const isLoading = filtersStatus === 'loading' || configStatus === 'loading'
  const isScheduleSource = duplicatesFileSetupData?.ScheduleTypeID === 1

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader
          header={isEditMode ? 'Edit Duplicate Setup' : 'Delete Duplicate Setup'}
          navLinks={navLinks}
        ></PageHeader>
        <div className='setup-container'>
          <div className='setup-content-container'>
            <div className='field'>
              <CustomTextInput
                name='SetupID'
                size={Sizes.Small}
                label='Setup ID'
                onChange={handleChange}
                value={duplicatesFileSetupData?.SetupID || ''}
                disabled
              />
            </div>
            <div className='field'>
              <CustomSelect
                id='scheduleTypeID'
                name='ScheduleTypeID'
                size={Sizes.Small}
                label='Source Type'
                value={duplicatesFileSetupData?.ScheduleTypeID}
                onChange={handleChange}
                disabled
              >
                {(filtersData.ScheduleTypes || []).map((option, index) => (
                  <option key={index} value={option?.IndexValue}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </div>
            <div className='field'>
              <CustomTextInput
                name='SourceID'
                size={Sizes.Small}
                label='Production Source Path'
                onChange={handleChange}
                value={duplicatesFileSetupData?.LookupSource || ''}
                disabled
              />
            </div>
            <div className='field'>
              <CustomTextInput
                name='CreatedByPID'
                size={Sizes.Small}
                label='Created By'
                onChange={handleChange}
                value={duplicatesFileSetupData?.CreatedByPID || ''}
                disabled
              />
            </div>
            <div className='field'>
              <CustomTextInput
                name='CreatedOn'
                size={Sizes.Small}
                label='Created On'
                onChange={handleChange}
                value={
                  duplicatesFileSetupData?.CreatedOn
                    ? formatDateWithTime(duplicatesFileSetupData?.CreatedOn)
                    : ''
                }
                disabled
              />
            </div>
            <div className='field'>
              <CustomTextInput
                name='LastUpdatedByPID'
                size={Sizes.Small}
                label='Last Updated By'
                onChange={handleChange}
                value={duplicatesFileSetupData?.LastUpdatedByPID || ''}
                disabled
              />
            </div>
            <div className='field'>
              <CustomTextInput
                name='LastUpdatedOn'
                size={Sizes.Small}
                label='Last Updated On'
                onChange={handleChange}
                value={
                  duplicatesFileSetupData?.LastUpdatedOn
                    ? formatDateWithTime(duplicatesFileSetupData?.LastUpdatedOn)
                    : ''
                }
                disabled
              />
            </div>
            <div className='field'>
              <MultiSelect
                id='selectedZoneCodes'
                name='SelectedZoneCodes'
                size='small'
                label='Zone Codes'
                value={
                  duplicatesFileSetupData?.SelectedZoneCodes
                    ? duplicatesFileSetupData?.SelectedZoneCodes?.split(',')
                    : []
                }
                onChange={handleChange}
                errorMessage={createSetupError?.SelectedZoneCodes}
              >
                {(duplicateConfigData || []).map((configData) => (
                  <MenuItem
                    key={isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode}
                    value={isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode}
                    disableRipple
                  >
                    <Checkbox
                      disableRipple
                      checked={
                        (duplicatesFileSetupData?.SelectedZoneCodes?.split(',') || []).indexOf(
                          isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode,
                        ) > -1
                      }
                    />
                    <span>
                      {isScheduleSource ? configData?.ToZoneCode : configData?.FromZoneCode}
                    </span>
                  </MenuItem>
                ))}
              </MultiSelect>
            </div>
            <div className='field'>
              <CustomTextInput
                name='TargetPath'
                size={Sizes.Small}
                label='Target Path'
                onChange={handleChange}
                value={duplicatesFileSetupData?.TargetPath || ''}
                errorMessage={createSetupError?.TargetPath}
              />
            </div>
            <div className='field'>
              <KiteCheckbox
                checked={!!duplicatesFileSetupData?.Enabled}
                id='enabled'
                label='Enabled'
                name='Enabled'
                onChange={handleChange}
              />
            </div>
            <div className='setup-actions'>
              {isEditMode ? (
                <CustomButton
                  loading={createEditStatus === 'loading'}
                  label={'Save'}
                  onClick={saveSetup}
                ></CustomButton>
              ) : (
                <CustomButton
                  loading={createEditStatus === 'loading'}
                  label='Delete'
                  btnType='danger'
                  onClick={deleteSetup}
                ></CustomButton>
              )}
            </div>
          </div>
          <KiteDialog
            id='confirmDialog'
            className='confirm-dialog'
            open={dialogStatus}
            title='Success'
            onClose={handleDialogClose}
            primaryBtnLabel='Ok'
            onPrimaryBtnClick={handleDialogClose}
          >
            <div className='kite-dialog__content-group'>{dialogMessage}</div>
          </KiteDialog>
          {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
        </div>
      </div>
    </div>
  )
}

export default DuplicateEditSetup
