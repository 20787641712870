import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LabelPositions, Sizes } from '../../../types'
import CustomSelect from '../../../components/customFormControls/select/Select'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/Store'
import CustomButton from '../../../components/customFormControls/button/Button'
import { PATHS } from '../../../routes/Routes'

const SourceDetailsFilters = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const sourceID = searchParams.get('SourceID')
  const fileType = searchParams.get('FileType')
  const { filtersData } = useSelector((state: RootState) => state.filtersData)
  const [filterValues, setFilterValues] = React.useState({
    FileType: fileType,
    SourceID: sourceID,
  })
  const handleChange = (event: any) => {
    const name = event?.target?.name
    const value = event?.target?.value || null
    if (name === 'FileType') {
      setFilterValues({ ...filterValues, [name]: value, SourceID: '' })
      return
    }
    setFilterValues({ ...filterValues, [name]: value })
  }

  const isScheduleSource = filterValues?.FileType === 'Schedules'

  const handleViewSource = () => {
    if (filterValues?.SourceID && filterValues?.FileType) {
      navigate(
        PATHS.SOURCE_DETAILS +
          '?SourceID=' +
          filterValues?.SourceID +
          '&FileType=' +
          filterValues?.FileType,
        { replace: true },
      )
    }
  }

  return (
    <div className='filters'>
      <div className='filter-fields'>
        <div className='filter-field'>
          <CustomSelect
            id='fileType'
            name='FileType'
            size={Sizes.Small}
            label='File Type'
            value={filterValues?.FileType}
            labelPos={LabelPositions.Left}
            onChange={handleChange}
          >
            {(filtersData?.FileTypes || []).map((option, index) => (
              <option key={index} value={option?.Value}>
                {option?.Value}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className='field'>
          <CustomSelect
            id='sourceID'
            name='SourceID'
            size={Sizes.Small}
            label='Schedule Source'
            value={filterValues?.SourceID}
            labelPos={LabelPositions.Left}
            onChange={handleChange}
            errorMessage={filterValues?.SourceID ? '' : 'please select source'}
          >
            {(
              (isScheduleSource ? filtersData?.SchedFromPath : filtersData?.VerifFromPath) || []
            ).map((option, index) => (
              <option key={index} value={option?.ID}>
                {option?.Value}
              </option>
            ))}
          </CustomSelect>
        </div>
      </div>
      <div className='actions'>
        <div className='action'>
          <CustomButton
            variant='primary'
            label='View Source'
            onClick={handleViewSource}
            size={Sizes.Medium}
          ></CustomButton>
        </div>
      </div>
    </div>
  )
}
export default SourceDetailsFilters
