import React from 'react'
import PageHeader from '../../../components/pageHeader/PageHeader'
import { ILink } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import SourceDetailsFilters from './SourceDetailsFilters'
import SourceDetails from './SourceDetails'
import SourceAssociatedPaths from './SourceAssociatedPaths'
import { KiteProgressIndicator } from '@kite/react-kite'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/Store'
import SourceDetailsActions from './Actions/SourceDetailsActions'

import './SourceDetailsStyles.scss'

const SourceDetailsContainer = () => {
  const { status, associatePathsStatus } = useSelector((state: RootState) => state.sourceDetails)
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Sources',
      link: PATHS.VIEW_SOURCES,
    },
    {
      label: 'Source Details',
      link: PATHS.SOURCE_DETAILS,
    },
  ]
  const isLoading = associatePathsStatus === 'loading' || status === 'loading'

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader header='Source Details' navLinks={navLinks}>
          <SourceDetailsActions />
        </PageHeader>
      </div>
      <SourceDetailsFilters />
      <SourceDetails />
      <SourceAssociatedPaths />
      {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
    </div>
  )
}
export default SourceDetailsContainer
