import React, { useEffect } from 'react'
import { KiteIcon } from '@kite/react-kite'
import { useDispatch, useSelector } from 'react-redux'
import CustomButton from '../../../components/customFormControls/button/Button'
import { LabelPositions, Sizes } from '../../../types'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { IBulkConfigChangeHistoryFilters } from '../../../redux/BulkConfigurations/BulkConfigModels'
import CustomDatePicker from '../../../components/customFormControls/datePicker/DatePicker'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'
import { updateFilters, updatePagination } from '../../../redux/BulkConfigurations/BulkConfigSlice'

const BulkConfigHistoryFilters = () => {
  const dispatch: AppDispatch = useDispatch()
  const [filterStatus, setFilterStatus] = React.useState(false)
  const { tableFilters, tablePagination } = useSelector((state: RootState) => state.bulkConfigData)
  const [filterValues, setFilterValues] = React.useState(tableFilters)

  const handleClearFilters = () => {
    setFilterStatus(false)
    const defaultFilterValues = {} as IBulkConfigChangeHistoryFilters
    setFilterValues(defaultFilterValues)
    dispatch(updateFilters(defaultFilterValues))
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }

  const handleChange = (event: any) => {
    const name = event?.target?.name
    const value = event?.target?.value || null
    setFilterValues({ ...filterValues, [name]: value })
  }

  const handleApplyFilters = () => {
    setFilterStatus(true)
    dispatch(
      updateFilters({
        ...filterValues,
      }),
    )
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }
  useEffect(() => {
    setFilterValues({ ...tableFilters })
  }, [JSON.stringify(tableFilters)])

  useEffect(() => {
    // clearing filters on unmount
    return () => handleClearFilters()
  }, [])

  return (
    <div className='filters'>
      <h6 className='filters-title'>
        <KiteIcon className='filter-icon' icon='ki-filter' />
        <span>Filters</span>
      </h6>
      <div className='filter-fields'>
        <div className='filter-field'>
          <CustomDatePicker
            onChange={handleChange}
            labelPos={LabelPositions.Left}
            label='Effective Date'
            name='EffectiveDate'
            value={filterValues?.EffectiveDate}
          />
        </div>
        <div className='filter-field'>
          <CustomTextInput
            name='OperatorID'
            size={Sizes.Small}
            label='Changed By'
            onChange={handleChange}
            labelPos={LabelPositions.Left}
            value={filterValues?.OperatorID || ''}
          />
        </div>
      </div>
      <div className='actions'>
        <div className='action'>
          <CustomButton
            onClick={handleApplyFilters}
            size={Sizes.Medium}
            variant='primary'
            label='Apply Filters'
          ></CustomButton>
        </div>
        {filterStatus && (
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        )}
      </div>
    </div>
  )
}
export default BulkConfigHistoryFilters
