import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IConfigurationsData,
  IConfigurationsDataState,
  IConfigurationVersion,
} from './ConfigurationsModels'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import { downloadURI } from '../../utils/utils'
import ApiService from '../../services/ApiService'

const successResultMessage = 'success'

const initialState: IConfigurationsDataState = {
  configurationsData: [],
  selectedConfigurationDetails: null,
  status: 'idle',
  error: null,
  exportStatus: 'idle',
  configModifyStatus: 'idle',
  configDetailsError: null,
  configDetailsStatus: 'idle',
  configVersionsStatus: 'idle',
  configurationVersions: [],
  tableFilters: {
    FileType: 'S',
    EffectiveOn: new Date(),
  },
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}

export const fetchData = createAsyncThunk('configurations/fetchData', async (data) => {
  const response = await ApiService.getData(appApiEndPoints.configurationsDataURL, data)
  return response.data
})
export const exportConfigurationsData = createAsyncThunk('configurations/exportData', async () => {
  const response = await ApiService.getData(appApiEndPoints.exportConfigurationsDataURL, {
    Environment: process.env.REACT_APP_ENV,
  })
  return response.data
})
export const createConfiguration = createAsyncThunk('configurations/create', async (data) => {
  const response = await ApiService.postData(appApiEndPoints.configurationsURL, {}, data)
  return response.data
})
export const deleteConfiguration = createAsyncThunk('configurations/delete', async (params) => {
  const response = await ApiService.deleteData(appApiEndPoints.configurationsURL, params)
  return response.data
})
export const fetchConfigVersions = createAsyncThunk(
  'configurations/configVersions',
  async (params) => {
    const response = await ApiService.getData(appApiEndPoints.configurationVersionsURL, params)
    return response.data
  },
)
export const fetchConfigurationDetails = createAsyncThunk(
  'configurations/configurationDetails',
  async (data) => {
    const response = await ApiService.getData(appApiEndPoints.configurationsDataURL, data)
    return response.data
  },
)
export const updateConfigurationDetails = createAsyncThunk(
  'configurations/updateConfigurationDetails',
  async (data) => {
    const response = await ApiService.putData(appApiEndPoints.configurationsURL, {}, data)
    return response.data
  },
)

export const ConfigurationsSlice = createSlice({
  name: 'configurationsData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
    resetConfigDetails: (state, action) => {
      state.configModifyStatus = 'idle'
      state.configVersionsStatus = 'idle'
      state.configDetailsError = ''
      state.configDetailsStatus = 'idle'
      state.configurationVersions = []
      state.selectedConfigurationDetails = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.configurationsData = action?.payload as IConfigurationsData[]
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch configurations data'
      })
      .addCase(fetchConfigurationDetails.pending, (state) => {
        state.configDetailsStatus = 'loading'
      })
      .addCase(fetchConfigurationDetails.fulfilled, (state, action) => {
        state.configDetailsStatus = 'succeeded'
        const result = action?.payload as IConfigurationsData[]
        if (result) {
          state.selectedConfigurationDetails = result[0]
        }
      })
      .addCase(fetchConfigurationDetails.rejected, (state, action) => {
        state.configDetailsStatus = 'failed'
        state.error = action?.error?.message || 'Failed to fetch configurations data'
      })
      .addCase(exportConfigurationsData.pending, (state) => {
        state.exportStatus = 'loading'
      })
      .addCase(exportConfigurationsData.fulfilled, (state, action) => {
        if (action?.payload) {
          state.exportStatus = 'succeeded'
          downloadURI(action?.payload)
        } else {
          state.exportStatus = 'failed'
          state.error = 'Failed to export configurations data'
        }
      })
      .addCase(exportConfigurationsData.rejected, (state, action) => {
        state.exportStatus = 'failed'
        state.error = action?.error?.message || 'Failed to export configurations data'
      })
      .addCase(createConfiguration.pending, (state) => {
        state.configDetailsError = ''
        state.configModifyStatus = 'loading'
      })
      .addCase(createConfiguration.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase().includes(successResultMessage)) {
          state.configModifyStatus = 'succeeded'
        } else {
          state.configModifyStatus = 'failed'
          state.configDetailsError = result || 'Failed to create configuration'
        }
      })
      .addCase(createConfiguration.rejected, (state, action) => {
        state.configModifyStatus = 'failed'
        state.configDetailsError = action?.error?.message || 'Failed to create configuration'
      })
      .addCase(updateConfigurationDetails.pending, (state) => {
        state.configDetailsError = ''
        state.configModifyStatus = 'loading'
      })
      .addCase(updateConfigurationDetails.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase().includes(successResultMessage)) {
          state.configModifyStatus = 'succeeded'
        } else {
          state.configModifyStatus = 'failed'
          state.configDetailsError = result || 'Failed to save configuration'
        }
      })
      .addCase(updateConfigurationDetails.rejected, (state, action) => {
        state.configModifyStatus = 'failed'
        state.configDetailsError = action?.error?.message || 'Failed to create configuration'
      })
      .addCase(deleteConfiguration.pending, (state) => {
        state.configDetailsError = ''
        state.configModifyStatus = 'loading'
      })
      .addCase(deleteConfiguration.fulfilled, (state, action) => {
        const result = action?.payload as string
        if (result?.toLowerCase().includes(successResultMessage)) {
          state.configModifyStatus = 'succeeded'
        } else {
          state.configModifyStatus = 'failed'
          state.configDetailsError = result || 'Failed to delete configuration'
        }
      })
      .addCase(deleteConfiguration.rejected, (state, action) => {
        state.configModifyStatus = 'failed'
        state.configDetailsError = action?.error?.message || 'Failed to delete configuration'
      })
      .addCase(fetchConfigVersions.pending, (state) => {
        state.configVersionsStatus = 'loading'
      })
      .addCase(fetchConfigVersions.fulfilled, (state, action) => {
        state.configVersionsStatus = 'succeeded'
        state.configurationVersions = action?.payload as IConfigurationVersion[]
      })
      .addCase(fetchConfigVersions.rejected, (state, action) => {
        state.configVersionsStatus = 'failed'
        state.configDetailsError =
          action?.error?.message || 'Failed to fetch configuration versions'
      })
  },
})
export const { setError, updateFilters, updatePagination, updateSort, resetConfigDetails } =
  ConfigurationsSlice.actions
export default ConfigurationsSlice.reducer
