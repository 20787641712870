import React, { useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  KiteAlert,
  KiteIcon,
  KiteLink,
  KiteMenu,
  KiteMenuItem,
  KiteTooltip,
} from '@kite/react-kite'
import {
  copyToClipBoard,
  formatDateWithCustomFormat,
  formatDateWithTime,
  formatStringToDate,
  formatTime,
  getCustomMonth,
  getDate,
} from '../../../utils/utils'
import CustomToast from '../../../components/toast/CustomToast'
import CustomTable, { Spacing } from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchData,
  setError,
  updateFilters,
  updatePagination,
  updateSort,
} from '../../../redux/BatchActivity/BatchActivitySlice'
import { PATHS } from '../../../routes/Routes'

import './BatchActivityTableStyles.scss'

interface IBatchActivityTableProps {
  spacing: Spacing
}

const BatchActivityTable = ({ spacing = Spacing.Default }: IBatchActivityTableProps) => {
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const history = useNavigate()
  const isInitialMount = useRef(true)
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const {
    batchActivityData = [],
    error,
    status,
    tableFilters,
    tablePagination,
    tablePagination: { PageNumber, RecordsPerPage },
    tableSort,
  } = useSelector((state: RootState) => state.batchActivityData)

  const columns = [
    {
      label: 'ID',
      sortKey: 'BatchID',
      accessorKey: 'BatchID',
      fixed: 'left',
      width: '30px',
    },
    {
      label: 'Sch',
      sortKey: 'SchedDate',
      accessorKey: 'SchedDate',
      width: '30px',
      render: (row: any) => (
        <div>{getCustomMonth(row?.SchedDate) + '' + getDate(row?.SchedDate)}</div>
      ),
    },
    {
      label: 'Source Title',
      sortKey: 'SourceTitle',
      accessorKey: 'SourceTitle',
      width: '200px',
    },
    {
      label: 'Status',
      sortKey: 'OverallStatus',
      accessorKey: 'OverallStatus',
      width: '100px',
      render: (row: any) => (
        <div className={'cell-status ' + row?.OverallStatus}>{row?.OverallStatus}</div>
      ),
    },
    {
      label: 'Imports',
      sortKey: 'ImportStarted',
      accessorKey: 'imports',
      width: '300px',
      className: 'import-cell',
      headerClassName: 'import-cell-header',
      render: (row: any) => (
        <div className='import-data-cell'>
          <span className='date'>
            <KiteIcon className='cell-icon' size='0.9rem' icon='ki-calendar' />
            <span>{formatDateWithTime(row?.ImportStarted)}</span>
            <span className='time'>({formatTime(row?.ImportDurationSeconds)})</span>
          </span>
          <span className='separator'>|</span>
          <span className='count'>
            <KiteLink
              href={
                PATHS.SCHEDULES_IMPORT +
                '?FileType=' +
                tableFilters?.FileType +
                '&SourceID=' +
                row?.SourceID +
                '&DataCenter=' +
                row?.DataCenter +
                '&BatchID=' +
                row?.BatchID
              }
              className='cell-link'
            >
              <span className='primary-count'>{row?.TotalImportedFiles}</span> of{' '}
              {row?.TotalImportFilesFiltered}
            </KiteLink>
          </span>
        </div>
      ),
    },
    {
      label: 'Exports',
      sortKey: 'DownloadStarted',
      accessorKey: 'exports',
      width: '300px',
      className: 'export-cell',
      headerClassName: 'export-cell-header',
      render: (row: any) => (
        <div className='export-data-cell'>
          {row?.DownloadStarted && (
            <>
              <span className='date'>
                <KiteIcon className='cell-icon' size='0.9rem' icon='ki-calendar' />
                <span>{formatDateWithTime(row?.DownloadStarted)}</span>
                <span className='time'>({formatTime(row?.DownloadDurationSeconds)})</span>
              </span>
              <span className='separator'>|</span>
              <span className='count'>
                <KiteLink
                  href={
                    PATHS.SCHEDULES_EXPORT +
                    '?FileType=' +
                    tableFilters?.FileType +
                    '&SourceID=' +
                    row?.SourceID +
                    '&DataCenter=' +
                    row?.DataCenter +
                    '&BatchID=' +
                    row?.BatchID
                  }
                  className='cell-link'
                >
                  <span className='primary-count'>{row?.TotalDownloadFiles}</span> of{' '}
                  {row?.TotalDownloadFilesFiltered}
                </KiteLink>
              </span>
              {Number(row?.NoConfigMatch) > 0 && (
                <KiteLink
                  href={
                    PATHS.SCHEDULES_EXPORT +
                    '?FileType=' +
                    tableFilters?.FileType +
                    '&SourceID=' +
                    row?.SourceID +
                    '&DataCenter=' +
                    row?.DataCenter +
                    '&BatchID=' +
                    row?.BatchID +
                    '&DidExport=0'
                  }
                  className='cell-error cell-link'
                >
                  <span className='cell-error'>
                    {row?.NoConfigMatch}
                    <KiteTooltip
                      text={row?.NoConfigMatch + ' missing configs'}
                      direction='bottom'
                      type='icon'
                      id='cellPopOver'
                    >
                      <KiteIcon className='error-icon' icon='ki-info-circle' />
                    </KiteTooltip>
                  </span>
                </KiteLink>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      label: 'DAA Count',
      sortKey: 'DAAFileCount',
      accessorKey: 'DAAFileCount',
      width: '80px',
    },
    {
      label: 'Total Time',
      sortKey: 'TotalTime',
      accessorKey: 'TotalTime',
      width: '100px',
      render: (row: any) => <div>{row?.TotalTime ? formatTime(row?.TotalTime) : ''}</div>,
    },
    {
      label: 'Instance',
      sortKey: 'DataCenter',
      accessorKey: 'DataCenter',
      width: '100px',
    },
    {
      label: 'Actions',
      sortKey: 'actions',
      fixed: 'right',
      accessorKey: 'actions',
      width: '20px',
      render: (row: any) => {
        const handleCopy = () => {
          copyToClipBoard(row?.BatchActivityID)
          setToastStatus(true)
          setToastMsg('Copied GUID')
        }
        return (
          <KiteMenu variant='flow'>
            <KiteMenuItem onSelect={handleCopy}>
              <span className='flex-center'>
                <KiteIcon className='copy-icon' icon='ki-copy' /> Copy GUID
              </span>
            </KiteMenuItem>
          </KiteMenu>
        )
      },
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    dispatch(updateSort({ SortBy: column, SortOrder: newSortDirection }))
  }

  useEffect(() => {
    // Default values
    const currentFiltersData: { [x: string]: any } = { ...tableFilters }
    const currentSortData: { [x: string]: any } = { ...tableSort }
    const currentPaginationData: { [x: string]: any } = { ...tablePagination }
    // Values From URl
    const paramEntries: any = searchParams.entries()
    for (const entry of paramEntries) {
      const [param, value] = entry
      if (value) {
        if (param === 'PageNumber' || param === 'RecordsPerPage') {
          currentPaginationData[param] = value
        } else if (param === 'SortBy' || param === 'SortOrder') {
          currentSortData[param] = value
        } else if (
          param === 'ScheduleFrom' ||
          param === 'ScheduleTo' ||
          param === 'ImportFrom' ||
          param === 'ImportTo'
        ) {
          currentFiltersData[param] = formatStringToDate(value)
        } else {
          currentFiltersData[param] = value
        }
      }
    }
    // update to store
    dispatch(updateFilters({ ...currentFiltersData }))
    dispatch(updateSort({ ...currentSortData }))
    dispatch(updatePagination({ ...currentPaginationData }))
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const reqPayload: { [x: string]: any } = {
      ...tableFilters,
      ScheduleFrom: tableFilters?.ScheduleFrom
        ? formatDateWithCustomFormat(tableFilters?.ScheduleFrom, 'yyyy-MM-dd HH:mm:ss')
        : null,
      ScheduleTo: tableFilters?.ScheduleTo
        ? formatDateWithCustomFormat(tableFilters?.ScheduleTo, 'yyyy-MM-dd HH:mm:ss')
        : null,
      ImportFrom: tableFilters?.ImportFrom
        ? formatDateWithCustomFormat(tableFilters?.ImportFrom, 'yyyy-MM-dd HH:mm:ss')
        : null,
      ImportTo: tableFilters?.ImportTo
        ? formatDateWithCustomFormat(tableFilters?.ImportTo, 'yyyy-MM-dd HH:mm:ss')
        : null,
      ...tablePagination,
      ...tableSort,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() }, { replace: true })
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fetchData(reqPayload),
    )
  }, [
    JSON.stringify({
      ...tableFilters,
      ...tablePagination,
      ...tableSort,
    }),
    isInitialMount.current,
  ])

  const handleClearError = () => {
    dispatch(setError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    dispatch(
      updatePagination({
        ...tablePagination,
        [name]: value,
        ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
      }),
    )
  }

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  const totalItems = Number(batchActivityData[0]?.MaxCount || 0)
  const isLoading = status === 'loading'

  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={batchActivityData || []}
        spacing={spacing}
        loading={isLoading}
        currentPage={PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={totalItems}
      />
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </>
  )
}

export default BatchActivityTable
