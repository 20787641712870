import { useAuthenticator } from '@aws-amplify/ui-react'
import { AmplifyUser } from '@aws-amplify/ui/dist/types/types/authenticator/user'

export enum AuthStatus {
  'authenticated' = 'authenticated',
  'configuring' = 'configuring',
  'unauthenticated' = 'unauthenticated',
}

export const getAuthStatus = () => {
  const { authStatus } = useAuthenticator()
  return authStatus
}
export const getCurrentUser = () => {
  const { user } = useAuthenticator()
  return user
}
export const isAuthenticated = () => {
  const { authStatus } = useAuthenticator()
  return authStatus === AuthStatus.authenticated
}
export const getUserName = (user: AmplifyUser) => {
  return user?.attributes?.email
}
