import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  IActiveCodeZoneDetails,
  IDuplicateConfigData,
  IDuplicateFileSetupData,
  IDuplicateFileSetupDataState,
} from './DuplicatesFileSetupModels'
import ApiService from '../../services/ApiService'
import { appApiEndPoints } from '../../constants/ApiEndPoints'

const initialState: IDuplicateFileSetupDataState = {
  duplicateFileSetupData: [],
  duplicateConfigData: [],
  selectedDuplicateFile: {
    ScheduleTypeID: 1,
    SourceID: 0,
    SelectedZoneCodes: '',
    TargetPath: '',
    Enabled: 0,
  },
  status: 'idle',
  createEditStatus: 'idle',
  configStatus: 'idle',
  activeZoneCodeStatus: 'idle',
  activeZoneCodesData: [],
  error: null,
  tableFilters: {
    Enabled: 1,
  },
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}

export const fetchData = createAsyncThunk('duplicates/fetchFileSetupData', async (data) => {
  const response = await ApiService.getData(appApiEndPoints.duplicateFileSetupDataURL, data)
  return response.data
})
export const fetchConfigData = createAsyncThunk('duplicates/fetchConfigData', async (data) => {
  const response = await ApiService.getData(appApiEndPoints.configurationsDataURL, data)
  return response.data
})
export const createDuplicateSetup = createAsyncThunk('duplicates/createSetup', async (data) => {
  const response = await ApiService.postData(appApiEndPoints.duplicateSetupURL, {}, data)
  return response.data
})
export const editDuplicateSetup = createAsyncThunk('duplicates/editSetup', async (data) => {
  const response = await ApiService.putData(appApiEndPoints.duplicateSetupURL, {}, data)
  return response.data
})
export const deleteDuplicateSetup = createAsyncThunk('duplicates/deleteSetup', async (params) => {
  const response = await ApiService.deleteData(appApiEndPoints.duplicateSetupURL, params)
  return response.data
})
export const activeZoneCodeDetails = createAsyncThunk(
  'duplicates/activeZoneCodeDetails',
  async (params) => {
    const response = await ApiService.getData(appApiEndPoints.activeZoneCodeDetailsURL, params)
    return response.data
  },
)

export const DuplicateFileSetupSlice = createSlice({
  name: 'duplicateFileSetupData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
    resetCreateSetupStatus: (state, action) => {
      state.createEditStatus = action?.payload
    },
    updateConfigsData: (state, action) => {
      state.duplicateConfigData = action?.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.duplicateFileSetupData = action?.payload as IDuplicateFileSetupData[]
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch duplicate file setup data'
      })
      .addCase(fetchConfigData.pending, (state) => {
        state.configStatus = 'loading'
      })
      .addCase(fetchConfigData.fulfilled, (state, action) => {
        state.configStatus = 'succeeded'
        state.duplicateConfigData = action?.payload as IDuplicateConfigData[]
      })
      .addCase(fetchConfigData.rejected, (state, action) => {
        state.configStatus = 'failed'
        state.error = action?.error?.message || 'Failed to fetch config data'
      })
      .addCase(createDuplicateSetup.pending, (state) => {
        state.error = null
        state.createEditStatus = 'loading'
      })
      .addCase(createDuplicateSetup.fulfilled, (state, action) => {
        state.error = null
        state.createEditStatus = 'succeeded'
      })
      .addCase(createDuplicateSetup.rejected, (state, action) => {
        state.createEditStatus = 'failed'
        state.error = action?.error?.message || 'Failed to create'
      })
      .addCase(editDuplicateSetup.pending, (state) => {
        state.createEditStatus = 'loading'
      })
      .addCase(editDuplicateSetup.fulfilled, (state, action) => {
        state.createEditStatus = 'succeeded'
      })
      .addCase(editDuplicateSetup.rejected, (state, action) => {
        state.createEditStatus = 'failed'
        state.error = action?.error?.message || 'Failed to save'
      })
      .addCase(deleteDuplicateSetup.pending, (state) => {
        state.createEditStatus = 'loading'
      })
      .addCase(deleteDuplicateSetup.fulfilled, (state, action) => {
        state.createEditStatus = 'succeeded'
      })
      .addCase(deleteDuplicateSetup.rejected, (state, action) => {
        state.createEditStatus = 'failed'
        state.error = action?.error?.message || 'Failed to delete'
      })
      .addCase(activeZoneCodeDetails.pending, (state) => {
        state.activeZoneCodeStatus = 'loading'
      })
      .addCase(activeZoneCodeDetails.fulfilled, (state, action) => {
        state.activeZoneCodeStatus = 'succeeded'
        state.activeZoneCodesData = action?.payload as IActiveCodeZoneDetails[]
      })
      .addCase(activeZoneCodeDetails.rejected, (state, action) => {
        state.activeZoneCodeStatus = 'failed'
        state.error = action?.error?.message || 'Failed to fetch active zone code details'
      })
  },
})
export const {
  setError,
  updateFilters,
  updatePagination,
  updateSort,
  resetCreateSetupStatus,
  updateConfigsData,
} = DuplicateFileSetupSlice.actions
export default DuplicateFileSetupSlice.reducer
