import React, { useState } from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import TableViewType from '../../components/tableViewType/TableViewType'
import { Spacing } from '../../components/table/Table'
import AuditLogFilters from './AuditLogFilters'
import AuditLogTable from './AuditLogTable'
import CustomButton from '../../components/customFormControls/button/Button'
import { Sizes } from '../../types'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/Routes'

const AuditLog = () => {
  const [viewType, setViewType] = useState<Spacing>(Spacing.Default)
  const navigate = useNavigate()
  const handleAddNew = () => {
    navigate(PATHS.CREATE_AUDIT_LOG)
  }
  return (
    <div className='page-container'>
      <PageHeader header='Audit Log'>
        <CustomButton
          onClick={handleAddNew}
          size={Sizes.Medium}
          variant='secondary'
          icon='ki-plus'
          label='Add New'
        ></CustomButton>
      </PageHeader>
      <div className='page-content'>
        <div className='table-filters'>
          <div className='filters-container'>
            <AuditLogFilters />
          </div>
          <div className='table-view-type'>
            <TableViewType viewType={viewType} setViewType={setViewType} />
          </div>
        </div>
        <AuditLogTable spacing={viewType} />
      </div>
    </div>
  )
}
export default AuditLog
