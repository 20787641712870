import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IFiltersData, IFiltersState } from './FiltersModels'
import ApiService from '../../services/ApiService'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import { groupByKey } from '../../utils/utils'

const initialState: IFiltersState = {
  filtersData: {},
  status: 'idle',
  error: null,
}

export const fetchFilterOptions = createAsyncThunk('filters/fetchOptions', async () => {
  const response = await ApiService.getData(appApiEndPoints.filterOptionsDataURL)
  return response.data
})

export const FiltersSlice = createSlice({
  name: 'filtersData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFilterOptions.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchFilterOptions.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const data = action?.payload as IFiltersData[]
        const categoryData = groupByKey(data || [], 'Category')
        // Sort Category Data In Alphabetical Order
        for (const category in categoryData) {
          categoryData[category].sort((a: IFiltersData, b: IFiltersData) =>
            a?.Value?.localeCompare(b?.Value),
          )
        }
        state.filtersData = categoryData
      })
      .addCase(fetchFilterOptions.rejected, (state) => {
        state.status = 'failed'
        state.error = 'Failed to fetch filter options'
      })
  },
})

export default FiltersSlice.reducer
