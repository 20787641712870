import React, { useEffect, useRef } from 'react'
import { KiteAlert, KiteIcon, KiteTooltip } from '@kite/react-kite'
import { copyToClipBoard, formatDateWithTime } from '../../../utils/utils'
import CustomTable, { Spacing } from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchData,
  setError,
  updateFilters,
  updatePagination,
  updateSort,
} from '../../../redux/Duplicates/DuplicatesSlice'
import CustomToast from '../../../components/toast/CustomToast'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface IConfigurationsTableProps {
  spacing: Spacing
}

const DuplicateActivityTable = ({ spacing = Spacing.Default }: IConfigurationsTableProps) => {
  const isInitialMount = useRef(true)
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const history = useNavigate()
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const {
    duplicateActivityData = [],
    error,
    status,
    tableFilters,
    tablePagination,
    tablePagination: { PageNumber, RecordsPerPage },
    tableSort,
  } = useSelector((state: RootState) => state.duplicatesData)

  const columns = [
    {
      label: 'File Name',
      sortKey: 'FileName',
      accessorKey: 'FileName',
      fixed: 'left',
      width: '80px',
    },
    {
      label: 'Downloaded',
      sortKey: 'Downloaded',
      accessorKey: 'Downloaded',
      width: '160px',
      render: (row: any) => {
        return <span>{formatDateWithTime(row?.Downloaded)}</span>
      },
    },
    {
      label: 'Title',
      sortKey: 'SourceTitle',
      accessorKey: 'SourceTitle',
      width: '150px',
    },
    {
      label: 'Destination Path',
      sortKey: 'ExportFolderPath',
      accessorKey: 'ExportFolderPath',
      width: '500px',
      render: (row: any) => {
        const handleCopy = () => {
          copyToClipBoard(row?.ExportFolderPath)
          setToastStatus(true)
          setToastMsg('Copied Destination Path')
        }
        return (
          <KiteTooltip
            className='cell-file-path'
            type='truncation'
            id='kt1'
            text='Click to copy'
            direction='bottom'
          >
            <span onClick={handleCopy}>
              <span>{row?.ExportFolderPath}</span>
              <span className='cell-icon'>
                <KiteIcon icon='ki-copy' />
              </span>
            </span>
          </KiteTooltip>
        )
      },
    },
    {
      label: 'Copy To Path',
      sortKey: 'DuplicateFolderPath',
      accessorKey: 'DuplicateFolderPath',
      width: '420px',
      render: (row: any) => {
        const handleCopy = () => {
          copyToClipBoard(row?.DuplicateFolderPath)
          setToastStatus(true)
          setToastMsg('Copied Copy To Path')
        }
        return (
          <KiteTooltip
            className='cell-file-path'
            type='truncation'
            id='kt1'
            text='Click to copy'
            direction='bottom'
          >
            <span onClick={handleCopy}>
              <span>{row?.DuplicateFolderPath}</span>
              <span className='cell-icon'>
                <KiteIcon icon='ki-copy' />
              </span>
            </span>
          </KiteTooltip>
        )
      },
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    dispatch(updateSort({ SortBy: column, SortOrder: newSortDirection }))
  }

  useEffect(() => {
    // Default values
    const currentFiltersData: { [x: string]: any } = {
      ...tableFilters,
    }
    const currentSortData: { [x: string]: any } = { ...tableSort }
    const currentPaginationData: { [x: string]: any } = { ...tablePagination }
    // Values From URl
    const paramEntries: any = searchParams.entries()
    for (const entry of paramEntries) {
      const [param, value] = entry
      if (value) {
        if (param === 'PageNumber' || param === 'RecordsPerPage') {
          currentPaginationData[param] = value
        } else if (param === 'SortBy' || param === 'SortOrder') {
          currentSortData[param] = value
        } else {
          currentFiltersData[param] = value
        }
      }
    }
    dispatch(
      updateFilters({
        ...currentFiltersData,
      }),
    )
    dispatch(updateSort({ ...currentSortData }))
    dispatch(updatePagination({ ...currentPaginationData }))
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const reqPayload: { [x: string]: any } = {
      ...tableFilters,
      ...tableSort,
      ...tablePagination,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() }, { replace: true })
    dispatch(
      fetchData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reqPayload,
      ),
    )
  }, [
    JSON.stringify({
      ...tableFilters,
      ...tablePagination,
      ...tableSort,
    }),
    isInitialMount.current,
  ])

  const handleClearError = () => {
    dispatch(setError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    dispatch(
      updatePagination({
        ...tablePagination,
        [name]: value,
        ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
      }),
    )
  }

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  const totalItems = Number(duplicateActivityData[0]?.MaxCount || 0)
  const isLoading = status === 'loading'

  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={duplicateActivityData || []}
        spacing={spacing}
        loading={isLoading}
        currentPage={PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={totalItems}
        paginationVariant='label'
      />
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </>
  )
}

export default DuplicateActivityTable
