import React, { useState } from 'react'
import DuplicateFileSetupFilters from './DuplicateFileSetupFilters'
import DuplicateFileSetupTable from './DuplicateFileSetupTable'
import { Spacing } from '../../../components/table/Table'
import TableViewType from '../../../components/tableViewType/TableViewType'
import { ILink } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import PageHeader from '../../../components/pageHeader/PageHeader'
import AddDuplicateFileSetup from './AddFileSetup/AddDuplicateFileSetup'

const DuplicateFileSetup = () => {
  const [viewType, setViewType] = useState<Spacing>(Spacing.Default)
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Duplicate File Setup',
      link: PATHS.DUPLICATE_FILE_SETUP,
    },
  ]
  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader header='Duplicate File Setup' navLinks={navLinks}>
          <AddDuplicateFileSetup />
        </PageHeader>
        <div className='table-filters'>
          <div className='filters-container'>
            <DuplicateFileSetupFilters />
          </div>
          <div className='table-view-type'>
            <TableViewType viewType={viewType} setViewType={setViewType} />
          </div>
        </div>
        <DuplicateFileSetupTable spacing={viewType} />
      </div>
    </div>
  )
}
export default DuplicateFileSetup
