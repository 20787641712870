import React, { useEffect } from 'react'
import { AppDispatch, RootState } from '../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import { IAuditLogFilters } from '../../redux/AuditLog/AuditLogModels'
import { updateFilters, updatePagination } from '../../redux/AuditLog/AuditLogSlice'
import { KiteGrid, KiteGridCell, KiteIcon } from '@kite/react-kite'
import { LabelPositions, Sizes } from '../../types'
import CustomButton from '../../components/customFormControls/button/Button'
import { Popover } from '@mui/material'
import CustomSelect from '../../components/customFormControls/select/Select'
import CustomTextInput from '../../components/customFormControls/textInput/TextInput'
import CustomDatePicker from '../../components/customFormControls/datePicker/DatePicker'
import CustomSelectInput from '../../components/customFormControls/CustomSelectInput/CustomSelectInput'

const ShowArchiveOptions = [
  {
    Name: 'Yes',
    Value: 1,
  },
  {
    Name: 'No',
    Value: 0,
  },
]

const AuditLogFilters = () => {
  const dispatch: AppDispatch = useDispatch()
  const [filterStatus, setFilterStatus] = React.useState(false)
  const { filtersData } = useSelector((state: RootState) => state.filtersData)
  const { tableFilters, tablePagination } = useSelector((state: RootState) => state.auditLogData)
  const [filterValues, setFilterValues] = React.useState(tableFilters)
  const [moreFiltersEl, setMoreFiltersEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(moreFiltersEl)
  const id = open ? 'more-filters' : undefined
  const handleCloseMoreFilters = () => {
    setMoreFiltersEl(null)
  }
  const handleOpenMoreFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreFiltersEl(event.currentTarget)
  }
  const handleClearFilters = () => {
    setFilterStatus(false)
    handleCloseMoreFilters()
    const defaultFilterValues = { ShowLastDays: 30 } as IAuditLogFilters
    setFilterValues(defaultFilterValues)
    dispatch(updateFilters(defaultFilterValues))
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }

  const handleChange = (event: any) => {
    const name = event?.target?.name
    const value = event?.target?.value || null
    setFilterValues({ ...filterValues, [name]: value })
  }

  const handleApplyFilters = () => {
    handleCloseMoreFilters()
    setFilterStatus(true)
    dispatch(
      updateFilters({
        ...filterValues,
      }),
    )
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }

  useEffect(() => {
    setFilterValues({ ...tableFilters })
  }, [JSON.stringify(tableFilters)])

  useEffect(() => {
    // clearing filters on unmount
    return () => handleClearFilters()
  }, [])

  return (
    <div className='filters'>
      <h6 className='filters-title'>
        <KiteIcon className='filter-icon' icon='ki-filter' />
        <span>Filters</span>
      </h6>
      <div className='filter-fields'>
        <div className='filter-field'>
          <CustomSelect
            id='ShowLastDays'
            name='ShowLastDays'
            size={Sizes.Small}
            label='Showing'
            value={filterValues?.ShowLastDays}
            onChange={handleChange}
            labelPos={LabelPositions.Left}
          >
            {(filtersData?.AuditShowLastDays || []).map((option, index) => (
              <option key={index} value={option?.IndexValue}>
                {option?.Value}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className='filter-field'>
          <CustomSelectInput
            id='NetCode'
            name='NetCode'
            type='text'
            size={Sizes.Small}
            label='Network'
            value={filterValues?.NetCode || []}
            onChange={handleChange}
            labelPos={LabelPositions.Left}
            header='Select Network Codes'
            valueLength={2}
            limitTags={15}
            helpText='You can select up to 15 network codes at the same time'
          ></CustomSelectInput>
        </div>
        <div className='filter-field'>
          <CustomSelectInput
            id='ZoneCode'
            name='ZoneCode'
            type='number'
            size={Sizes.Small}
            label='Zone'
            value={filterValues?.ZoneCode || []}
            onChange={handleChange}
            labelPos={LabelPositions.Left}
            valueLength={3}
            limitTags={2}
            header='Select Zones'
            helpText='You can select up to 2 zones at the same time'
          ></CustomSelectInput>
        </div>
        <div className='filter-field'>
          <CustomSelect
            id='status'
            name='Status'
            size={Sizes.Small}
            label='Status'
            value={filterValues?.Status}
            onChange={handleChange}
            labelPos={LabelPositions.Left}
          >
            {(filtersData?.AuditStatus || []).map((option, index) => (
              <option key={index} value={option?.Value}>
                {option?.Value}
              </option>
            ))}
          </CustomSelect>
        </div>
      </div>
      <div className='actions'>
        <div className='action'>
          <CustomButton
            variant='secondary'
            label='More Filters'
            onClick={handleOpenMoreFilters}
            size={Sizes.Medium}
          ></CustomButton>
        </div>
        <div className='action'>
          <CustomButton
            onClick={handleApplyFilters}
            size={Sizes.Medium}
            variant='primary'
            label='Apply Filters'
          ></CustomButton>
        </div>
        {filterStatus && (
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        )}
      </div>
      <Popover
        id={id}
        className='more-filters-popover'
        open={open}
        anchorEl={moreFiltersEl}
        onClose={handleCloseMoreFilters}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <h6>More Filters</h6>
        <div className='filters-content'>
          <KiteGrid>
            <KiteGridCell col={6}>
              <CustomDatePicker
                onChange={handleChange}
                labelPos={LabelPositions.Top}
                label='Start Time'
                name='StartTime'
                value={filterValues.StartTime}
                dateFormat='MM/dd/yyyy h:mm aa'
                timeInputLabel='Time:'
                showTimeInput
              />
            </KiteGridCell>
            <KiteGridCell col={6}>
              <CustomDatePicker
                onChange={handleChange}
                labelPos={LabelPositions.Top}
                label='End Time'
                name='EndTime'
                value={filterValues.EndTime}
                dateFormat='MM/dd/yyyy h:mm aa'
                timeInputLabel='Time:'
                showTimeInput
              />
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='TimeZoneID'
                name='TimeZoneID'
                size={Sizes.Small}
                label='Time Zone'
                value={filterValues?.TimeZoneID}
                onChange={handleChange}
              >
                {(filtersData?.AuditTimeZones || []).map((option, index) => (
                  <option key={index} value={option?.IndexValue}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='MSMInstanceID'
                name='MSMInstanceID'
                size={Sizes.Small}
                label='MSM Instance'
                value={filterValues?.MSMInstanceID}
                onChange={handleChange}
              >
                {(filtersData?.MSMInstance || []).map((option, index) => (
                  <option key={index} value={option?.IndexValue}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomTextInput
                name='User'
                size={Sizes.Small}
                label='User'
                onChange={handleChange}
                value={filterValues?.User || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='IsArchived'
                name='IsArchived'
                size={Sizes.Small}
                label='Show Archived'
                value={filterValues?.IsArchived}
                onChange={handleChange}
              >
                {(ShowArchiveOptions || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Name}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
          </KiteGrid>
        </div>
        <div className='footer-actions'>
          <div className='action'>
            <CustomButton
              onClick={handleApplyFilters}
              size={Sizes.Medium}
              variant='primary'
              label='Apply Filters'
            ></CustomButton>
          </div>
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        </div>
      </Popover>
    </div>
  )
}
export default AuditLogFilters
