import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { ILink } from '../../types'
import { useNavigate } from 'react-router-dom'

import './BreadCrumbsStyles.scss'

interface IBreadcrumbsProps {
  links: Array<ILink>
}

const BasicBreadcrumbs = ({ links }: IBreadcrumbsProps) => {
  const navigate = useNavigate()
  const handleLinkClick = (link: string) => (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault()
    navigate(link)
  }
  return (
    <div role='presentation'>
      <Breadcrumbs aria-label='breadcrumb'>
        {links.map((link, i) => {
          if (i < links.length - 1) {
            return (
              <Link onClick={handleLinkClick(link?.link)} key={i} underline='hover' color='inherit'>
                {link?.label}
              </Link>
            )
          } else {
            return (
              <Typography key={i} color='text.primary'>
                {link?.label}
              </Typography>
            )
          }
        })}
      </Breadcrumbs>
    </div>
  )
}
export default BasicBreadcrumbs
