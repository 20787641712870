import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ISourcesData, ISourcesDataState } from './SourcesModels'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import ApiService from '../../services/ApiService'

const initialState: ISourcesDataState = {
  sourcesData: [],
  status: 'idle',
  error: null,
  updateStatus: 'idle',
  updateError: null,
  exportStatus: 'idle',
  tableFilters: {
    FileType: 'Schedules',
    Enabled: 1,
  },
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}

export const fetchData = createAsyncThunk('sources/fetchData', async (params) => {
  const response = await ApiService.getData(appApiEndPoints.sourcesDataURL, params)
  return response.data
})
export const updateSourceIngestDays = createAsyncThunk('sources/updateIngestDays', async (data) => {
  const response = await ApiService.putData(appApiEndPoints.sourcesUpdateIngestDaysURL, {}, data)
  return response.data
})

export const SourcesSlice = createSlice({
  name: 'sourcesData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
      state.updateError = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.sourcesData = action?.payload as ISourcesData[]
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch sources data'
      })
      .addCase(updateSourceIngestDays.pending, (state) => {
        state.updateStatus = 'loading'
        state.updateError = ''
      })
      .addCase(updateSourceIngestDays.fulfilled, (state, action) => {
        if (action.payload?.toLowerCase().includes('success')) {
          state.updateStatus = 'succeeded'
        } else {
          state.updateStatus = 'failed'
          state.updateError = action?.payload
        }
      })
      .addCase(updateSourceIngestDays.rejected, (state, action) => {
        state.updateStatus = 'failed'
        state.updateError = action?.error?.message || 'Failed to update ingest days'
      })
  },
})
export const { setError, updateFilters, updatePagination, updateSort } = SourcesSlice.actions
export default SourcesSlice.reducer
