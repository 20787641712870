import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { KiteMenu, KiteMenuItem } from '@kite/react-kite'
import { ReactComponent as SpectrumLogo } from '../../assets/images/specttrum_logo_white.svg'
import { ReactComponent as MSMLogo } from '../../assets/images/msm_logo.svg'
import { ILink } from '../../types'
import { AmplifyUser } from '@aws-amplify/ui/dist/types/types/authenticator/user'

import './HeaderStyles.scss'

interface IHeaderProps {
  links: Array<ILink>
  authStatus: string
  onSignOut: () => void
  user: AmplifyUser
}

const Header = ({ links, authStatus, onSignOut, user }: IHeaderProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeClassName = 'link link-active'
  const handleChildClick = (link: ILink) => {
    navigate(link?.link)
  }
  return (
    <header>
      <div className='logo-container left'>
        <div className='spectrum-logo' role='presentation'>
          <SpectrumLogo />
        </div>
        <div className='msm-logo' role='presentation'>
          <MSMLogo />
        </div>
      </div>
      {authStatus === 'authenticated' && (
        <>
          <div className='links-container center'>
            {links.map((link, index) => {
              if (link?.children) {
                return (
                  <span
                    key={index}
                    className={
                      pathname?.includes(link?.link)
                        ? activeClassName + ' link dropdown-link'
                        : ' link dropdown-link'
                    }
                  >
                    <KiteMenu variant='flow' label={link?.label}>
                      {(link.children || []).map((childLink, j) => {
                        return (
                          <KiteMenuItem
                            onClick={() => handleChildClick(childLink)}
                            key={j}
                            disabled={childLink?.disabled}
                          >
                            {childLink?.label}
                          </KiteMenuItem>
                        )
                      })}
                    </KiteMenu>
                  </span>
                )
              }
              return (
                <NavLink
                  key={index}
                  className={({ isActive }) => (isActive ? activeClassName : 'link')}
                  to={link.link}
                >
                  {link?.label}
                </NavLink>
              )
            })}
          </div>
          <div className='right'>
            <div className='user-menu'>
              <KiteMenu variant='flow' label={user?.attributes?.email}>
                <KiteMenuItem onClick={onSignOut}>Sign Out</KiteMenuItem>
              </KiteMenu>
            </div>
          </div>
        </>
      )}
    </header>
  )
}

export default Header
