import React from 'react'
import {
  FILE_PROGRESS_STATUS,
  IAuditLogAttachment,
  IPresignedURLResponse,
} from '../../../redux/AuditLog/AuditLogModels'
import CustomFileInput from '../../../components/customFormControls/fileInput/FileInput'
import ApiService from '../../../services/ApiService'
import { appApiEndPoints } from '../../../constants/ApiEndPoints'

import '../AuditLogStyles.scss'
import Progressbar from '../../../components/progressbar/Progressbar'
import { KiteIcon } from '@kite/react-kite'

interface IAuditLogAttachmentProps {
  auditLogId: string
  attachment: IAuditLogAttachment
  onChange: (data: any) => void
}

const EachAttachment = ({ auditLogId, attachment, onChange }: IAuditLogAttachmentProps) => {
  const isLoading =
    attachment.Status === FILE_PROGRESS_STATUS.PENDING ||
    attachment.Status === FILE_PROGRESS_STATUS.UPLOADING
  const isError = attachment.Status === FILE_PROGRESS_STATUS.FAILED
  const isSuccess = attachment.Status === FILE_PROGRESS_STATUS.SUCCESS

  const handleChange = (fileEvent: any) => {
    const file = fileEvent.target.files[0]
    onChange({
      ...attachment,
      Status: FILE_PROGRESS_STATUS.PENDING,
      DocName: file?.name,
      Error: '',
    })
    getPresignedURL({ AuditLogId: auditLogId, DocName: file?.name })
      .then((response: any) => {
        onChange({
          ...attachment,
          Status: FILE_PROGRESS_STATUS.UPLOADING,
          DocName: file?.name,
          Error: '',
        })
        uploadFile(file, response.data)
          .then((response) => {
            onChange({
              ...attachment,
              Status: FILE_PROGRESS_STATUS.SUCCESS,
              DocName: file?.name,
              Error: '',
            })
          })
          .catch((error) => {
            onChange({
              ...attachment,
              Status: FILE_PROGRESS_STATUS.FAILED,
              DocName: file?.name,
              Error: error,
            })
          })
      })
      .catch((error) => {
        onChange({
          ...attachment,
          Status: FILE_PROGRESS_STATUS.FAILED,
          DocName: file?.name,
          Error: error,
        })
      })
  }

  const uploadFile = (file: File, data: IPresignedURLResponse) => {
    const url: string = data?.url
    const payload = new FormData()
    payload.append('key', data?.fields?.key)
    payload.append('AWSAccessKeyId', data?.fields?.AWSAccessKeyId)
    payload.append('x-amz-security-token', data?.fields['x-amz-security-token'])
    payload.append('policy', data?.fields?.policy)
    payload.append('signature', data?.fields?.signature)
    payload.append('file', file)
    return new Promise((resolve, reject) => {
      return ApiService.postData(url, {}, payload)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          return resolve(error)
        })
    })
  }

  const getPresignedURL = (params: any) => {
    return new Promise((resolve, reject) => {
      return ApiService.getData(appApiEndPoints.getPresignedURL, params)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return (
    <div
      className={
        'each-attachment-container ' +
        (attachment?.Status === FILE_PROGRESS_STATUS.OLD ? 'existing' : '')
      }
    >
      <CustomFileInput
        value={attachment?.DocName}
        name={attachment?.AuditLogItemId}
        onChange={handleChange}
        errorMessage={attachment?.Error}
      />
      {isLoading && (
        <div className='progress-container'>
          <Progressbar label='uploading...' />
        </div>
      )}
      <div className='file-status-icon'>
        {isSuccess && (
          <KiteIcon className='success-icon' icon='ki-checkmark-circle-f' size='1rem' />
        )}
        {isError && (
          <KiteIcon
            className='error-icon'
            icon='ki-info-circle-f'
            title='Failed to upload'
            size='1rem'
          />
        )}
      </div>
    </div>
  )
}

export default EachAttachment
