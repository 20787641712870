import React, { useEffect } from 'react'
import PageHeader from '../../../components/pageHeader/PageHeader'
import { ILink } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import { KiteGrid, KiteGridCell, KiteIcon, KiteTooltip } from '@kite/react-kite'
import { useLocation, useNavigate } from 'react-router-dom'
import ScheduleContentRemarks from './ScheduleContentRemarks'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/Store'
import ImportScheduleContentsTable from './ImportScheduleContentsTable'
import { copyToClipBoard } from '../../../utils/utils'
import CustomToast from '../../../components/toast/CustomToast'
import './ScheduleContentStyles.scss'

const ImportScheduleContent = () => {
  const navigate = useNavigate()
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const { state: scheduleData } = useLocation()
  const { contentsData = [] } = useSelector((state: RootState) => state.importSchedulesData)
  const remarksData = (contentsData || []).filter((contentData) => contentData?.EventType == 'REM')

  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Import Schedules',
      link: PATHS.SCHEDULES_IMPORT,
    },
    {
      label: 'Import Content',
      link: PATHS.SCHEDULES_IMPORT_CONTENT,
    },
  ]
  useEffect(() => {
    // Check if its edit mode and row data exists or not
    if (!scheduleData) {
      navigate(PATHS.SCHEDULES_EXPORT)
    }
  }, [])

  const handleCopy = (data: string) => () => {
    copyToClipBoard(data)
    setToastStatus(true)
    setToastMsg('Copied')
  }

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader header='Import Content' navLinks={navLinks}></PageHeader>
        <div className='setup-container'>
          <div className='grid-container'>
            <div className='each-row'>
              <KiteGrid>
                <KiteGridCell col={1}>
                  <div className='field'>
                    <div className='label'>Batch</div>
                    <div className='value'>{scheduleData?.BatchID}</div>
                  </div>
                </KiteGridCell>
                <KiteGridCell col={7}>
                  <div className='field'>
                    <div className='label'>Source</div>
                    <div className='value'>
                      <div>{scheduleData?.SourceTitle}</div>
                      <KiteTooltip
                        className='cell-file-path'
                        type='truncation'
                        id='kt1'
                        text='Click to copy'
                        direction='bottom'
                      >
                        <span onClick={handleCopy(scheduleData?.SourcePath)}>
                          <span>{scheduleData?.SourcePath}</span>
                          <span className='cell-icon'>
                            <KiteIcon icon='ki-copy' />
                          </span>
                        </span>
                      </KiteTooltip>
                    </div>
                  </div>
                </KiteGridCell>
                <KiteGridCell col={2}>
                  <div className='field'>
                    <div className='label'>File In</div>
                    <div className='value'>{scheduleData?.FileName}</div>
                  </div>
                </KiteGridCell>
                <KiteGridCell col={1}>
                  <div className='field'>
                    <div className='label'>LOI</div>
                    <div className='value'>{scheduleData?.LOICount}</div>
                  </div>
                </KiteGridCell>
                <KiteGridCell col={1}>
                  <div className='field'>
                    <div className='label'>Imported</div>
                    <div className='value'>
                      <span
                        className={
                          scheduleData?.DidImport == '1'
                            ? 'import-export-status Completed'
                            : 'import-export-status Incomplete'
                        }
                      >
                        {scheduleData?.DidImport == '1' ? 'True' : 'False'}
                      </span>
                    </div>
                  </div>
                </KiteGridCell>
              </KiteGrid>
            </div>
            <div className='each-row'>
              <KiteGrid>
                <KiteGridCell col={6}>
                  <div className='field'>
                    <div className='label'>Upload Start Time</div>
                    <div className='value'>{scheduleData?.ImportStarted}</div>
                  </div>
                </KiteGridCell>
                <KiteGridCell col={6}>
                  <div className='field'>
                    <div className='label'>Upload End Time</div>
                    <div className='value'>{scheduleData?.Imported}</div>
                  </div>
                </KiteGridCell>
              </KiteGrid>
            </div>
            <ScheduleContentRemarks remarksData={remarksData} />
          </div>
        </div>
        <div className='setup-container secondary-container'>
          <h5>Content</h5>
          <ImportScheduleContentsTable />
        </div>
      </div>
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </div>
  )
}
export default ImportScheduleContent
