import React from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'

import './HelpStyles.scss'
import { KiteGrid, KiteGridCell, KiteIcon, KiteLink } from '@kite/react-kite'

const Help = () => {
  return (
    <div>
      <div className='page-container'>
        <div className='page-content'>
          <PageHeader header='Help' navLinks={[]}></PageHeader>
          <KiteGrid>
            <KiteGridCell col={8}>
              <div className='setup-container section-container'>
                <div className='section-heading'>Knowledge Docs</div>
                <h3>Text File Specs CCMS</h3>
                <div>
                  <div className='text-link'>
                    <KiteIcon icon='ki-network-alt' size='1rem' />
                    <KiteLink href='https://msm.chartercom.com/static/media/TextFileSpecs_ECLIPSE-CCMS-Schedules.66750914.jpg'>Schedules</KiteLink>
                  </div>
                  <div className='text-link'>
                    <KiteIcon icon='ki-network-alt' size='1rem' />
                    <KiteLink href='https://msm.chartercom.com/static/media/TextFileSpecs_ECLIPSE-CCMS-Verifications.36ec07f4.jpg'>Verifications</KiteLink>
                  </div>
                  <div className='text-link'>
                    <KiteIcon icon='ki-network-alt' size='1rem' />
                    <KiteLink href='https://msm.chartercom.com/static/media/FWSchedsVerifs-40kview.3ea1b2c4.jpg'>MSM Design</KiteLink>
                  </div>
                </div>
              </div>
              <div className='setup-container section-container gray-container'>
                <div className='section-heading'>Related Apps</div>
                <KiteGrid>
                  <KiteGridCell col={6}>
                    <h6>Spectrum React Eclipse</h6>
                    <KiteGrid>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='https://xgmo.spectrumreach.com/xGLinear/login.html'>XGMO</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='https://pwapp010xgca.corp.chartercom.com/xGLinear/login.html'>XGCA</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='https://pwapp005xgtc.corp.chartercom.com/xGLinear/login.html'>XGTC</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='https://pwapp010xgfl.corp.chartercom.com/xGLinear/login.html'>XGFL</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='https://xgoh.spectrumreach.com/xGLinear/login.html'>XGOH</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                    </KiteGrid>
                  </KiteGridCell>
                  <KiteGridCell col={6}>
                    <h6>Visible World Gateway</h6>
                    <KiteGrid>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='http://23.85.241.37/'>XGMO</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='http://23.85.128.165/'>XGNC</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='http://23.85.241.36/'>XGWI</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='http://23.85.128.166/'>XGFL</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                      <KiteGridCell col={6}>
                        <div className='button-link'>
                          <KiteLink href='http://23.85.128.167/'>XGNY</KiteLink>
                          <KiteIcon icon='ki-export' size='1rem' />
                        </div>
                      </KiteGridCell>
                    </KiteGrid>
                  </KiteGridCell>
                </KiteGrid>
              </div>
            </KiteGridCell>
            <KiteGridCell col={4}>
              <div className='section-container'>
                <div className='section-heading'>Need Help ?</div>
                <div className="help-link-section">
                  <h6>General Inquiries</h6>
                  <div className='text-link help-link'>
                    <KiteIcon icon='ki-mail' size='1rem' />
                    <KiteLink href='mailto:reachme@charter.com'>reachme@charter.com</KiteLink>
                  </div>
                </div>
                <div className="help-link-section">
                  <h6>St Louis MNOC</h6>
                  <div className='text-link help-link'>
                    <KiteIcon icon='ki-phone' size='1rem' />
                    <span>1-877-480-3031</span>
                  </div>
                  <div className='text-link help-link'>
                    <KiteIcon icon='ki-mail' size='1rem' />
                    <KiteLink href='mailto:dl-dmnoc@charter.com'>mnoc@charter.com</KiteLink>
                  </div>
                </div>
                <div className="help-link-section">
                  <h6>Dallas MNOC</h6>
                  <div className='text-link help-link'>
                    <KiteIcon icon='ki-phone' size='1rem' />
                    <span>1-469-791-0528</span>
                  </div>
                  <div className='text-link help-link'>
                    <KiteIcon icon='ki-mail' size='1rem' />
                    <KiteLink href='mailto:dl-dmnoc@charter.com'>dl-dmnoc@charter.com</KiteLink>
                  </div>
                </div>
              </div>
            </KiteGridCell>
          </KiteGrid>
        </div>
      </div>
    </div>
  )
}

export default Help
