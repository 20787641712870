import React, { useEffect } from 'react'
import { KiteGrid, KiteGridCell, KiteIcon } from '@kite/react-kite'
import { useDispatch, useSelector } from 'react-redux'
import { Popover } from '@mui/material'
import CustomButton from '../../../components/customFormControls/button/Button'
import { LabelPositions, Sizes } from '../../../types'
import CustomSelect from '../../../components/customFormControls/select/Select'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { updateFilters, updatePagination } from '../../../redux/ImportSchedules/ImportSchedulesSlice'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'
import { IImportSchedulesFilters } from '../../../redux/ImportSchedules/ImportSchedulesModels'

const ImportSchedulesFilters = () => {
  const dispatch: AppDispatch = useDispatch()
  const [filterStatus, setFilterStatus] = React.useState(false)
  const { filtersData } = useSelector((state: RootState) => state.filtersData)
  const { tableFilters, tablePagination } = useSelector((state: RootState) => state.importSchedulesData)
  const [filterValues, setFilterValues] = React.useState(tableFilters)
  const [moreFiltersEl, setMoreFiltersEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(moreFiltersEl)
  const id = open ? 'more-filters' : undefined
  const handleCloseMoreFilters = () => {
    setMoreFiltersEl(null)
  }
  const handleOpenMoreFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreFiltersEl(event.currentTarget)
  }
  const handleClearFilters = () => {
    setFilterStatus(false)
    handleCloseMoreFilters()
    const defaultFilterValues = {
      FileType: 'Schedules',
      DataCenter: 'North Carolina',
    } as IImportSchedulesFilters
    setFilterValues(defaultFilterValues)
    dispatch(updateFilters(defaultFilterValues))
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }

  const handleChange = (event: any) => {
    const name = event?.target?.name
    const value = event?.target?.value || null
    setFilterValues({ ...filterValues, [name]: value })
  }

  const handleApplyFilters = () => {
    handleCloseMoreFilters()
    setFilterStatus(true)
    dispatch(
      updateFilters({
        ...filterValues,
      }),
    )
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }
  useEffect(() => {
    setFilterValues({ ...tableFilters })
  }, [JSON.stringify(tableFilters)])

  useEffect(() => {
    // clearing filters on unmount
    return () => handleClearFilters()
  }, [])

  const isScheduleSource = filterValues?.FileType === 'Schedules'

  return (
    <div className='filters'>
      <h6 className='filters-title'>
        <KiteIcon className='filter-icon' icon='ki-filter' />
        <span>Filters</span>
      </h6>
      <div className='filter-fields'>
        <div className='filter-field'>
          <CustomSelect
            id='fileType'
            name='FileType'
            size={Sizes.Small}
            label='File Type'
            value={filterValues?.FileType}
            labelPos={LabelPositions.Left}
            onChange={handleChange}
          >
            {(filtersData?.FileTypes || [] || []).map((option, index) => (
              <option key={index} value={option?.Value}>
                {option?.Value}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className='filter-field'>
          <CustomSelect
            id='DataCenter'
            name='DataCenter'
            size={Sizes.Small}
            label='MSM Instance'
            value={filterValues?.DataCenter}
            labelPos={LabelPositions.Left}
            onChange={handleChange}
          >
            {(filtersData?.DataCenter || []).map((option, index) => (
              <option key={index} value={option?.Value}>
                {option?.Value}
              </option>
            ))}
          </CustomSelect>
        </div>
      </div>
      <div className='actions'>
        <div className='action'>
          <CustomButton
            variant='secondary'
            label='More Filters'
            onClick={handleOpenMoreFilters}
            size={Sizes.Medium}
          ></CustomButton>
        </div>
        <div className='action'>
          <CustomButton
            onClick={handleApplyFilters}
            size={Sizes.Medium}
            variant='primary'
            label='Apply Filters'
          ></CustomButton>
        </div>
        {filterStatus && (
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        )}
      </div>
      <Popover
        id={id}
        className='more-filters-popover'
        open={open}
        anchorEl={moreFiltersEl}
        onClose={handleCloseMoreFilters}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <h6>More Filters</h6>
        <div className='filters-content'>
          <KiteGrid>
            <KiteGridCell col={6}>
              <CustomSelect
                id='scheduledSource'
                name='SourceID'
                size={Sizes.Small}
                label='Scheduled Source'
                value={filterValues?.SourceID}
                onChange={handleChange}
              >
                {(
                  (isScheduleSource ? filtersData?.SchedFromPath : filtersData?.VerifFromPath) || []
                ).map((option, index) => (
                  <option key={index} value={option?.ID}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={6}>
              <CustomTextInput
                name='BatchID'
                size={Sizes.Small}
                label='Batch ID'
                onChange={handleChange}
                value={filterValues?.BatchID || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={6}>
              <CustomTextInput
                name='FileName'
                size={Sizes.Small}
                label='File In'
                onChange={handleChange}
                value={filterValues?.FileName || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={6}>
              <CustomTextInput
                name='FromNetCode'
                size={Sizes.Small}
                label='From Net'
                onChange={handleChange}
                value={filterValues?.FromNetCode || ''}
              />
            </KiteGridCell>
          </KiteGrid>
        </div>
        <div className='footer-actions'>
          <div className='action'>
            <CustomButton
              onClick={handleApplyFilters}
              size={Sizes.Medium}
              variant='primary'
              label='Apply Filters'
            ></CustomButton>
          </div>
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        </div>
      </Popover>
    </div>
  )
}
export default ImportSchedulesFilters
