import React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { LabelPositions, Sizes } from '../../../types'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePickerStyles.scss'

interface ICustomDatePicker extends ReactDatePickerProps {
  size?: Sizes
  labelPos: LabelPositions
  label: string
  value: any
  onChange: any
  errorMessage?: any
}

const CustomDatePicker = ({
  size = Sizes.Small,
  labelPos = LabelPositions.Top,
  label,
  value,
  onChange,
  name,
  errorMessage,
  ...rest
}: ICustomDatePicker) => {
  const handleChange = (date: Date | null) => {
    onChange({ target: { name: name, value: date } })
  }
  return (
    <div
      className={`custom-form-control-date ${labelPos} ${size} ${
        errorMessage ? 'error-control' : ''
      }`}
    >
      {label && <label>{label}</label>}
      <DatePicker
        {...rest}
        selected={value}
        onChange={handleChange}
      />
      {errorMessage && <span className='kite-form-control__error'>{errorMessage}</span>}
    </div>
  )
}

export default CustomDatePicker
