import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ApiService from '../../services/ApiService'
import { appApiEndPoints } from '../../constants/ApiEndPoints'
import { IAuditLogItemsResponse, IAuditLogState } from './AuditLogModels'

const initialState: IAuditLogState = {
  auditLogItems: { AuditLogList: [], MaxCount: 0 },
  status: 'idle',
  error: null,
  auditLogDetailsError: null,
  auditLogModifyError: null,
  auditLogDetailsStatus: 'idle',
  auditLogModifyStatus: 'idle',
  selectedAuditLogDetails: null,
  tableFilters: {
    ShowLastDays: 30,
    NetCode: [],
    ZoneCode: [],
  },
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}

export const fetchData = createAsyncThunk('auditLog/fetchData', async (params) => {
  const response = await ApiService.getData(appApiEndPoints.auditLogItemsURL, params)
  return response.data
})

export const createAuditLogItem = createAsyncThunk('auditLog/createAuditLogItem', async (data) => {
  const response = await ApiService.postData(appApiEndPoints.auditLogItemURL, {}, data)
  return response.data
})
export const updateAuditLogItem = createAsyncThunk('auditLog/updateAuditLogItem', async (data) => {
  const response = await ApiService.putData(appApiEndPoints.auditLogItemURL, {}, data)
  return response.data
})
export const deleteAuditLogItem = createAsyncThunk(
  'auditLog/deleteAuditLogItem',
  async (params) => {
    const response = await ApiService.deleteData(appApiEndPoints.auditLogItemURL, params)
    return response.data
  },
)

export const getAuditLogItemDetails = createAsyncThunk(
  'auditLog/getAuditLogItemDetails',
  async (params) => {
    const response = await ApiService.getData(appApiEndPoints.auditLogItemURL, params)
    return response.data
  },
)

export const AuditLogDataSlice = createSlice({
  name: 'auditLogData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
    resetAuditLogDetails: (state, action) => {
      state.auditLogModifyStatus = 'idle'
      state.auditLogDetailsError = null
      state.auditLogDetailsStatus = 'idle'
      state.selectedAuditLogDetails = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.auditLogItems = action?.payload as IAuditLogItemsResponse
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch audit log data'
      })
      .addCase(createAuditLogItem.pending, (state) => {
        state.auditLogModifyStatus = 'loading'
      })
      .addCase(createAuditLogItem.fulfilled, (state, action) => {
        state.auditLogModifyStatus = 'succeeded'
      })
      .addCase(createAuditLogItem.rejected, (state, action) => {
        state.auditLogModifyStatus = 'failed'
        state.auditLogModifyError = action?.error?.message || 'Failed to create audit log entry'
      })
      .addCase(updateAuditLogItem.pending, (state) => {
        state.auditLogModifyStatus = 'loading'
      })
      .addCase(updateAuditLogItem.fulfilled, (state, action) => {
        state.auditLogModifyStatus = 'succeeded'
      })
      .addCase(updateAuditLogItem.rejected, (state, action) => {
        state.auditLogModifyStatus = 'failed'
        state.auditLogModifyError = action?.error?.message || 'Failed to update audit log entry'
      })
      .addCase(deleteAuditLogItem.pending, (state) => {
        state.auditLogModifyStatus = 'loading'
      })
      .addCase(deleteAuditLogItem.fulfilled, (state, action) => {
        state.auditLogModifyStatus = 'succeeded'
      })
      .addCase(deleteAuditLogItem.rejected, (state, action) => {
        state.auditLogModifyStatus = 'failed'
        state.auditLogModifyError = action?.error?.message || 'Failed to delete audit log entry'
      })
      .addCase(getAuditLogItemDetails.pending, (state) => {
        state.auditLogDetailsStatus = 'loading'
      })
      .addCase(getAuditLogItemDetails.fulfilled, (state, action) => {
        state.auditLogDetailsStatus = 'succeeded'
        state.selectedAuditLogDetails = action?.payload
      })
      .addCase(getAuditLogItemDetails.rejected, (state, action) => {
        state.auditLogDetailsStatus = 'failed'
        state.auditLogDetailsError = action?.error?.message || 'Failed to get details'
      })
  },
})
export const { setError, updateFilters, updatePagination, updateSort, resetAuditLogDetails } =
  AuditLogDataSlice.actions
export default AuditLogDataSlice.reducer
