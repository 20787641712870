import React, { useEffect } from 'react'
import {
  KiteAlert,
  KiteGrid,
  KiteGridCell,
  KiteIcon,
  KiteProgressIndicator,
} from '@kite/react-kite'
import './AuditLogDetailsModel.scss'
import { Sizes } from '../../../types'
import CustomButton from '../../../components/customFormControls/button/Button'
import {
  IAuditLogLink,
  IAuditLogAttachment,
  IAuditLogPayload,
  FILE_PROGRESS_STATUS,
  RELATED_LINKS_STATUS,
  IAuditLogDetails,
} from '../../../redux/AuditLog/AuditLogModels'
import ApiService from '../../../services/ApiService'
import { appApiEndPoints } from '../../../constants/ApiEndPoints'
import { downloadURI, formatDateWithTimeNoSecs, scrollToView } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../redux/Store'
import {
  getAuditLogItemDetails,
  resetAuditLogDetails,
  updateAuditLogItem,
} from '../../../redux/AuditLog/AuditLogSlice'
import { v4 as uuidv4 } from 'uuid'
import { PATHS } from '../../../routes/Routes'
import { useNavigate } from 'react-router-dom'

interface AuditLogDetailsModelProps {
  auditLogId: string
}

const AuditLogDetailsModel = ({ auditLogId }: AuditLogDetailsModelProps) => {
  const history = useNavigate()
  const dispatch: AppDispatch = useDispatch()
  const [auditLogData, setAuditLogData] = React.useState<IAuditLogDetails>({} as IAuditLogDetails)
  const [error, setError] = React.useState('')
  const {
    auditLogDetailsStatus,
    auditLogDetailsError,
    selectedAuditLogDetails,
    auditLogModifyStatus,
  } = useSelector((state: RootState) => state.auditLogData)

  const handleEditDetails = () => {
    history(PATHS.EDIT_AUDIT_LOG + '/' + auditLogData?.AuditLogId)
  }
  const handleArchive = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(updateAuditLogItem({ ...selectedAuditLogDetails, IsArchived: true }))
  }

  const downloadAttachment = (params: any) => {
    return new Promise((resolve, reject) => {
      return ApiService.getData(appApiEndPoints.downloadAttachmentURL, params)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handleAttachmentView = (attachment: IAuditLogAttachment) => () => {
    const params = {
      AuditLogId: auditLogData?.AuditLogId,
      DocName: attachment?.DocName,
    }
    downloadAttachment(params)
      .then((response: any) => {
        downloadURI(response.data, '', '_blank')
      })
      .catch((error) => {
        setError(error)
        scrollToView('.alert-element')
      })
  }

  const handleClearError = () => {
    setError('')
  }

  useEffect(() => {
    if (auditLogDetailsError) {
      setError(auditLogDetailsError)
    }
  }, [auditLogDetailsError])

  useEffect(() => {
    if (selectedAuditLogDetails) {
      const data: IAuditLogPayload = selectedAuditLogDetails
      setAuditLogData({
        ...data,
        AuditLogId: data?.AuditLogId || uuidv4(),
        Networks: (data?.Networks || []).map((network) => network?.NetCode),
        Zones: (data?.Zones || []).map((zone) => zone?.ZoneCode),
        Attachments: (data?.Attachments || []).map((attachment) => {
          return {
            ...attachment,
            Status: FILE_PROGRESS_STATUS.OLD,
          }
        }),
        Links: (data?.Links || []).map((link) => {
          return {
            ...link,
            Status: RELATED_LINKS_STATUS.OLD,
          }
        }),
      })
    }
  }, [selectedAuditLogDetails])

  useEffect(() => {
    if (auditLogModifyStatus === 'succeeded') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(getAuditLogItemDetails({ AuditLogId: auditLogId }))
    }
  }, [auditLogModifyStatus])

  useEffect(() => {
    if (auditLogId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(getAuditLogItemDetails({ AuditLogId: auditLogId }))
    }
  }, [auditLogId])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(resetAuditLogDetails())
    }
  }, [])

  const isLoading = auditLogDetailsStatus === 'loading'
  const isSuccess = auditLogDetailsStatus === 'succeeded'

  return (
    <div>
      {auditLogData?.IsArchived && (
        <div className='alert-element'>
          <KiteAlert
            autoFocus={false}
            level='page'
            type='info'
            description='This audit log item archived'
          />
        </div>
      )}
      {isLoading && <KiteProgressIndicator id='kp1' message='' title='Loading' />}
      <div className='modal-content-container'>
        {isSuccess && (
          <>
            <KiteGrid className='field-row'>
              <KiteGridCell col={6}>
                <div className='row-field'>
                  <label>Incident Start:</label>
                  <div>
                    {auditLogData?.StartTime
                      ? formatDateWithTimeNoSecs(auditLogData?.StartTime)
                      : ''}
                  </div>
                </div>
              </KiteGridCell>
              <KiteGridCell col={6}>
                <div className='row-field'>
                  <label>Incident End:</label>
                  <div className={'cell-status ' + auditLogData?.Status}>
                    {auditLogData?.Status}
                  </div>
                </div>
              </KiteGridCell>
            </KiteGrid>
            <KiteGrid className='field-row'>
              <KiteGridCell col={6}>
                <div className='row-field block'>
                  <label>Timezone</label>
                  <div>{auditLogData?.TimeZoneID}</div>
                </div>
              </KiteGridCell>
              <KiteGridCell col={6}>
                <div className='row-field block'>
                  <label>MSM Instance</label>
                  <div>{auditLogData?.MsmInstanceId}</div>
                </div>
              </KiteGridCell>
            </KiteGrid>
            <KiteGrid className='field-row'>
              <KiteGridCell col={3}>
                <label>Zone</label>
                <div>{auditLogData?.Zones?.join(',')}</div>
              </KiteGridCell>
              <KiteGridCell col={9}>
                <label>Network</label>
                <div>{auditLogData?.Networks?.join(',')}</div>
              </KiteGridCell>
            </KiteGrid>
            <KiteGrid className='field-row no-divider'>
              <div className='row-field block'>
                <label>Related Links</label>
                <div className='related-links-container'>
                  {(auditLogData.Links || []).map((link: IAuditLogLink) => (
                    <span className='related-link' key={link?.AuditLogItemId}>
                      <a href={link?.Address} target='_blank' rel='noreferrer'>
                        {link?.Address}
                      </a>
                      <KiteIcon className='link-icon' icon='ki-linkout' size='1rem' />
                    </span>
                  ))}
                </div>
              </div>
            </KiteGrid>
            <KiteGrid className='field-row no-divider'>
              <div className='row-field block'>
                <label>Attachments</label>
                <div className='attachments-container'>
                  {(auditLogData.Attachments || []).map((attachment: IAuditLogAttachment) => (
                    <div
                      className='attachment-link'
                      key={attachment?.AuditLogItemId}
                      onClick={handleAttachmentView(attachment)}
                    >
                      <KiteIcon className='link-icon' icon='ki-attachment' size='1rem' />
                      <span>{attachment?.DocName}</span>
                    </div>
                  ))}
                </div>
              </div>
            </KiteGrid>
            <KiteGrid className='field-row no-divider'>
              <div className='row-field block'>
                <label>Notes</label>
                <div className='notes-container'>{auditLogData?.Notes}</div>
              </div>
            </KiteGrid>
            <KiteGrid className='field-row no-divider'>
              <div className='row-field block'>
                <label>Entered By</label>
                <div className='value-bold'>{auditLogData?.CreatedBy}</div>
              </div>
            </KiteGrid>
          </>
        )}
        {error && (
          <div className='alert-element'>
            <KiteAlert
              onClose={handleClearError}
              autoFocus={false}
              level='page'
              type='error'
              description={error}
              dismissible
            />
          </div>
        )}
      </div>
      {isSuccess && !auditLogData?.IsArchived && (
        <div className='modal-actions'>
          <CustomButton
            className='primary-btn'
            onClick={handleEditDetails}
            size={Sizes.Large}
            variant='primary'
            icon='ki-edit'
            label='Edit Details'
          ></CustomButton>
          <CustomButton
            onClick={handleArchive}
            size={Sizes.Large}
            loading={auditLogModifyStatus === 'loading'}
            variant='secondary'
            icon='ki-clock'
            label='Archive Incident'
          ></CustomButton>
        </div>
      )}
    </div>
  )
}
export default AuditLogDetailsModel
