import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { KiteAlert } from '@kite/react-kite'
import { recordsAfterPagingAndSorting } from '../../../utils/utils'
import CustomTable from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchImportScheduleContentData,
  setContentsError,
} from '../../../redux/ImportSchedules/ImportSchedulesSlice'

const ImportScheduleContentsTable = () => {
  const { state: scheduleData } = useLocation()
  const dispatch: AppDispatch = useDispatch()
  const [tablePagination, setTablePagination] = useState<any>({
    PageNumber: 1,
    RecordsPerPage: 25,
  })
  const [tableSort, setTableSort] = useState<any>({
    SortBy: 'SeqNbr',
    SortOrder: 'desc',
  })
  const {
    contentsData = [],
    contentsError,
    contentsStatus,
  } = useSelector((state: RootState) => state.importSchedulesData)

  const columns = [
    {
      label: 'ET',
      sortKey: 'EventType',
      accessorKey: 'EventType',
      width: '20px',
      fixed: 'left',
    },
    {
      label: 'SD',
      sortKey: 'ScheduledDate',
      accessorKey: 'ScheduledDate',
      width: '50px',
    },
    {
      label: 'ST',
      sortKey: 'ScheduledTime',
      accessorKey: 'ScheduledTime',
      width: '50px',
    },
    {
      label: 'WST',
      sortKey: 'WindowStartTime',
      accessorKey: 'WindowStartTime',
      width: '50px',
    },
    {
      label: 'WDT',
      sortKey: 'WindowDurationTime',
      accessorKey: 'WindowDurationTime',
      width: '50px',
    },
    {
      label: 'BN',
      sortKey: 'BreakNbr',
      accessorKey: 'BreakNbr',
      width: '50px',
    },
    {
      label: 'PN',
      sortKey: 'PositionNbr',
      accessorKey: 'PositionNbr',
      width: '50px',
    },
    {
      label: 'SSL',
      sortKey: 'ScheduledSpotLen',
      accessorKey: 'ScheduledSpotLen',
      width: '50px',
    },
    {
      label: 'ABT',
      sortKey: 'VerActualAiredBreakTime',
      accessorKey: 'VerActualAiredBreakTime',
      width: '50px',
    },
    {
      label: 'ABL',
      sortKey: 'VerActualAiredBreakLen',
      accessorKey: 'VerActualAiredBreakLen',
      width: '50px',
    },
    {
      label: 'ABP',
      sortKey: 'VerActualAiredPosition',
      accessorKey: 'VerActualAiredPosition',
      width: '30px',
    },
    {
      label: 'VSC',
      sortKey: 'VerStatusCode',
      accessorKey: 'VerStatusCode',
      width: '30px',
    },
    {
      label: 'AD',
      sortKey: 'AdvertiserNbr',
      accessorKey: 'AdvertiserNbr',
      width: '60px',
    },
    {
      label: 'AN',
      sortKey: 'AdvertiserName',
      accessorKey: 'AdvertiserName',
      width: '100px',
    },
    {
      label: 'CT',
      sortKey: 'CommercialTitle',
      accessorKey: 'CommercialTitle',
      width: '150px',
    },
    {
      label: 'Bill',
      sortKey: 'Bill',
      accessorKey: 'Bill',
      width: '20px',
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    setTableSort({ SortBy: column, SortOrder: newSortDirection })
  }

  useEffect(() => {
    const reqPayload: { [x: string]: any } = {
      ...tablePagination,
      ...tableSort,
      FileImportedActivityID: scheduleData?.FileImportedActivityID,
      FileType: scheduleData?.FileType,
    }
    dispatch(
      fetchImportScheduleContentData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reqPayload,
      ),
    )
  }, [])

  const handleClearError = () => {
    dispatch(setContentsError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    setTablePagination({
      ...tablePagination,
      [name]: value,
      ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
    })
  }
  const filteredData = (contentsData || []).filter(
    (contentData) => contentData?.EventType !== 'REM',
  )
  const totalItems = Number(filteredData?.length || 0)
  const isLoading = contentsStatus === 'loading'
  const paginatedRecords = recordsAfterPagingAndSorting(
    filteredData,
    tableSort?.SortOrder,
    tableSort?.SortBy,
    tablePagination?.PageNumber,
    tablePagination?.RecordsPerPage,
  )
  return (
    <>
      {contentsError && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={contentsError}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={paginatedRecords || []}
        loading={isLoading}
        currentPage={tablePagination?.PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={tablePagination?.RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={totalItems}
      />
    </>
  )
}

export default ImportScheduleContentsTable
