import React, { useEffect } from 'react'
import { KiteGrid, KiteGridCell, KiteIcon } from '@kite/react-kite'
import { useDispatch, useSelector } from 'react-redux'
import { Popover } from '@mui/material'
import CustomButton from '../../../components/customFormControls/button/Button'
import { LabelPositions, Sizes } from '../../../types'
import CustomSelect from '../../../components/customFormControls/select/Select'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { updateFilters, updatePagination } from '../../../redux/Configurations/ConfigurationsSlice'
import { IConfigurationsFilters } from '../../../redux/Configurations/ConfigurationsModels'
import CustomDatePicker from '../../../components/customFormControls/datePicker/DatePicker'
import CustomTextInput from '../../../components/customFormControls/textInput/TextInput'

const IsCloneOptions = [
  {
    label: 'True',
    value: 1,
  },
  {
    label: 'False',
    value: 0,
  },
]
const FileTypeOptions = [
  {
    label: 'Schedules',
    value: 'S',
  },
  {
    label: 'Verifications',
    value: 'V',
  },
]

const ConfigurationsFilters = () => {
  const dispatch: AppDispatch = useDispatch()
  const [filterStatus, setFilterStatus] = React.useState(false)
  const { filtersData } = useSelector((state: RootState) => state.filtersData)
  const { tableFilters, tablePagination } = useSelector(
    (state: RootState) => state.configurationsData,
  )
  const [filterValues, setFilterValues] = React.useState(tableFilters)
  const [moreFiltersEl, setMoreFiltersEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(moreFiltersEl)
  const id = open ? 'more-filters' : undefined
  const handleCloseMoreFilters = () => {
    setMoreFiltersEl(null)
  }
  const handleOpenMoreFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreFiltersEl(event.currentTarget)
  }
  const handleClearFilters = () => {
    setFilterStatus(false)
    handleCloseMoreFilters()
    const defaultFilterValues = { FileType: 'S', EffectiveOn: new Date() } as IConfigurationsFilters
    setFilterValues(defaultFilterValues)
    dispatch(updateFilters(defaultFilterValues))
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }

  const handleChange = (event: any) => {
    const name = event?.target?.name
    const value = event?.target?.value || null
    setFilterValues({ ...filterValues, [name]: value })
  }

  const handleApplyFilters = () => {
    handleCloseMoreFilters()
    setFilterStatus(true)
    dispatch(
      updateFilters({
        ...filterValues,
      }),
    )
    dispatch(updatePagination({ ...tablePagination, PageNumber: 1 }))
  }
  useEffect(() => {
    setFilterValues({ ...tableFilters })
  }, [JSON.stringify(tableFilters)])

  useEffect(() => {
    // clearing filters on unmount
    return () => handleClearFilters()
  }, [])

  const isScheduleSource = filterValues?.FileType === 'S'

  return (
    <div className='filters'>
      <h6 className='filters-title'>
        <KiteIcon className='filter-icon' icon='ki-filter' />
        <span>Filters</span>
      </h6>
      <div className='filter-fields'>
        <div className='filter-field'>
          <CustomSelect
            id='fileType'
            name='FileType'
            size={Sizes.Small}
            label='File Type'
            value={filterValues?.FileType}
            labelPos={LabelPositions.Left}
            onChange={handleChange}
          >
            {(FileTypeOptions || []).map((option, index) => (
              <option key={index} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </CustomSelect>
        </div>
        <div className='filter-field'>
          <CustomDatePicker
            onChange={handleChange}
            labelPos={LabelPositions.Left}
            label='Effective On'
            name='EffectiveOn'
            value={filterValues?.EffectiveOn}
          />
        </div>
      </div>
      <div className='actions'>
        <div className='action'>
          <CustomButton
            variant='secondary'
            label='More Filters'
            onClick={handleOpenMoreFilters}
            size={Sizes.Medium}
          ></CustomButton>
        </div>
        <div className='action'>
          <CustomButton
            onClick={handleApplyFilters}
            size={Sizes.Medium}
            variant='primary'
            label='Apply Filters'
          ></CustomButton>
        </div>
        {filterStatus && (
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        )}
      </div>
      <Popover
        id={id}
        className='more-filters-popover large'
        open={open}
        anchorEl={moreFiltersEl}
        onClose={handleCloseMoreFilters}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <h6>More Filters</h6>
        <div className='filters-content'>
          <KiteGrid>
            <KiteGridCell col={3}>
              <CustomSelect
                id='scheduledSource'
                name='SourceID'
                size={Sizes.Small}
                label='Scheduled Source'
                value={filterValues?.SourceID}
                onChange={handleChange}
              >
                {(
                  (isScheduleSource ? filtersData?.SchedFromPath : filtersData?.VerifFromPath) || []
                ).map((option, index) => (
                  <option key={index} value={option?.ID}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='headEnd'
                name='HeadEnd'
                size={Sizes.Small}
                label='HeadEnd'
                value={filterValues?.HeadEnd}
                onChange={handleChange}
              >
                {(filtersData?.MCLocation || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='provider'
                name='Provider'
                size={Sizes.Small}
                label='Provider'
                value={filterValues?.Provider}
                onChange={handleChange}
              >
                {(filtersData?.Provider || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='isClone'
                name='IsClone'
                size={Sizes.Small}
                label='IsClone'
                value={filterValues?.IsClone}
                onChange={handleChange}
              >
                {(IsCloneOptions || []).map((option, index) => (
                  <option key={index} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='network'
                name='Network'
                size={Sizes.Small}
                label='Network'
                value={filterValues?.Network}
                onChange={handleChange}
              >
                {(filtersData?.Network || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='videoType'
                name='VideoType'
                size={Sizes.Small}
                label='VideoType'
                value={filterValues?.VideoType}
                onChange={handleChange}
              >
                {(filtersData?.VideoType || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomSelect
                id='VideoDef'
                name='VideoDef'
                size={Sizes.Small}
                label='videoDef'
                value={filterValues?.VideoDef}
                onChange={handleChange}
              >
                {(filtersData?.VideoDef || []).map((option, index) => (
                  <option key={index} value={option?.Value}>
                    {option?.Value}
                  </option>
                ))}
              </CustomSelect>
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomTextInput
                name='ConfigurationID'
                size={Sizes.Small}
                label='Config ID'
                onChange={handleChange}
                value={filterValues?.ConfigurationID || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomTextInput
                name='FromNetCode'
                size={Sizes.Small}
                label='From Net'
                onChange={handleChange}
                value={filterValues?.FromNetCode || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomTextInput
                name='ToNetCode'
                size={Sizes.Small}
                label='To Net'
                onChange={handleChange}
                value={filterValues?.ToNetCode || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomTextInput
                name='FromZoneCode'
                size={Sizes.Small}
                label='From Zone'
                onChange={handleChange}
                value={filterValues?.FromZoneCode || ''}
              />
            </KiteGridCell>
            <KiteGridCell col={3}>
              <CustomTextInput
                name='ToZoneCode'
                size={Sizes.Small}
                label='To Zone'
                onChange={handleChange}
                value={filterValues?.ToZoneCode || ''}
              />
            </KiteGridCell>
          </KiteGrid>
        </div>
        <div className='footer-actions'>
          <div className='action'>
            <CustomButton
              onClick={handleApplyFilters}
              size={Sizes.Medium}
              variant='primary'
              label='Apply Filters'
            ></CustomButton>
          </div>
          <div className='action'>
            <CustomButton
              onClick={handleClearFilters}
              size={Sizes.Medium}
              variant='borderless'
              label='Clear'
              icon='ki-x'
            ></CustomButton>
          </div>
        </div>
      </Popover>
    </div>
  )
}
export default ConfigurationsFilters
