import React from 'react'
import './App.scss'
import MainLayout from './components/mainLayout/MainLayout'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes/Routes'
import { Authenticator } from '@aws-amplify/ui-react'

// Configuring Amplify Cognito Auth
import { Amplify } from 'aws-amplify'
import awsExports from './pages/Auth/AuthConfig'
Amplify.configure(awsExports)

function App() {
  return (
    <BrowserRouter>
      <MainLayout>
        {/* Unfortunately, this appears to be a known bug with <Authenticator.Provider> and <Authenticator/>.*/}
        {/* Until the bug is fixed, this is work around*/}
        <Authenticator className='hidden-authenticator'></Authenticator>
        <Routes />
      </MainLayout>
    </BrowserRouter>
  )
}

export default App
