import React, { useEffect, useRef } from 'react'
import { KiteAlert, KiteIcon, KiteMenu, KiteMenuItem } from '@kite/react-kite'
import { copyToClipBoard, formatDate, formatStringToDate } from '../../../utils/utils'
import CustomTable, { Spacing } from '../../../components/table/Table'
import type { AppDispatch, RootState } from '../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchData,
  setError,
  updateFilters,
  updatePagination,
  updateSort,
} from '../../../redux/Configurations/ConfigurationsSlice'
import CustomToast from '../../../components/toast/CustomToast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PATHS } from '../../../routes/Routes'

interface IConfigurationsTableProps {
  spacing: Spacing
}

const ConfigurationsTable = ({ spacing = Spacing.Default }: IConfigurationsTableProps) => {
  const isInitialMount = useRef(true)
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const history = useNavigate()
  const [toastStatus, setToastStatus] = React.useState(false)
  const [toastMsg, setToastMsg] = React.useState('')
  const {
    configurationsData = [],
    error,
    status,
    tableFilters,
    tablePagination,
    tablePagination: { PageNumber, RecordsPerPage },
    tableSort,
  } = useSelector((state: RootState) => state.configurationsData)

  const columns = [
    {
      label: 'Head End',
      sortKey: 'HeadEnd',
      accessorKey: 'HeadEnd',
      width: '80px',
    },
    {
      label: 'Provider',
      sortKey: 'Provider',
      accessorKey: 'Provider',
      width: '100px',
    },
    {
      label: 'Net Description',
      sortKey: 'NetDescription',
      accessorKey: 'NetDescription',
      width: '200px',
    },
    {
      label: 'From Net',
      sortKey: 'FromNetCode',
      accessorKey: 'FromNetCode',
      width: '100px',
    },
    {
      label: 'To Net',
      sortKey: 'ToNetCode',
      accessorKey: 'ToNetCode',
      width: '100px',
    },
    {
      label: 'From Zone',
      sortKey: 'FromZoneCode',
      accessorKey: 'FromZoneCode',
      width: '100px',
    },
    {
      label: 'To Zone',
      sortKey: 'ToZoneCode',
      accessorKey: 'ToZoneCode',
      width: '100px',
    },
    {
      label: 'VideoType',
      sortKey: 'VideoType',
      accessorKey: 'VideoType',
      width: '120px',
    },
    {
      label: 'Prefix',
      sortKey: 'Prefix',
      accessorKey: 'Prefix',
      width: '60px',
    },
    {
      label: 'Suffix',
      sortKey: 'Suffix',
      accessorKey: 'Suffix',
      width: '60px',
    },
    {
      label: 'Actions',
      sortKey: 'actions',
      fixed: 'right',
      accessorKey: 'actions',
      width: '20px',
      render: (row: any) => {
        const handleCopy = (name: string, key: string) => () => {
          copyToClipBoard(row?.[key])
          setToastStatus(true)
          setToastMsg('Copied  ' + name)
        }
        const handleEdit = () => {
          history(
            PATHS.EDIT_CONFIGURATIONS +
              '/' +
              row?.ConfigurationID +
              '?LogConfigurationID=' +
              row?.LogConfigurationId,
          )
        }
        const handleDelete = () => {
          history(
            PATHS.DELETE_CONFIGURATIONS +
              '/' +
              row?.ConfigurationID +
              '?LogConfigurationID=' +
              row?.LogConfigurationId,
          )
        }
        return (
          <div>
            <KiteMenu variant='flow'>
              <KiteMenuItem onSelect={handleEdit}>
                <span className='flex-center'>
                  {' '}
                  <KiteIcon className='copy-icon' icon='ki-compose' />
                  Edit
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleDelete}>
                <span className='flex-center'>
                  {' '}
                  <KiteIcon className='copy-icon' icon='ki-trash' />
                  Delete
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleCopy('Schedule Source Path', 'SchedFromPath')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-copy' />
                  Schedule Source Path
                </span>
              </KiteMenuItem>
              <KiteMenuItem onSelect={handleCopy('Schedule Export Path', 'SchedToPath')}>
                <span className='flex-center'>
                  <KiteIcon className='copy-icon' icon='ki-copy' />
                  Schedule Export Path
                </span>
              </KiteMenuItem>
            </KiteMenu>
          </div>
        )
      },
    },
  ]

  const handleSort = (column: string) => {
    const currentSortKey = tableSort?.SortBy
    const sortDirection = tableSort?.SortOrder
    let newSortDirection: any = 'asc'
    if (currentSortKey === column) {
      if (!sortDirection) {
        newSortDirection = 'asc'
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc'
      } else if (sortDirection === 'desc') {
        newSortDirection = 'asc'
      }
    } else {
      newSortDirection = 'asc'
    }
    dispatch(updateSort({ SortBy: column, SortOrder: newSortDirection }))
  }

  useEffect(() => {
    // Default values
    const currentFiltersData: { [x: string]: any } = {
      ...tableFilters,
    }
    const currentSortData: { [x: string]: any } = { ...tableSort }
    const currentPaginationData: { [x: string]: any } = { ...tablePagination }
    // Values From URl
    const paramEntries: any = searchParams.entries()
    for (const entry of paramEntries) {
      const [param, value] = entry
      if (value) {
        if (param === 'PageNumber' || param === 'RecordsPerPage') {
          currentPaginationData[param] = value
        } else if (param === 'SortBy' || param === 'SortOrder') {
          currentSortData[param] = value
        } else if (param === 'EffectiveOn') {
          currentFiltersData[param] = formatStringToDate(value)
        } else if (param === 'IsClone') {
          currentFiltersData[param] = Number(value)
        } else {
          currentFiltersData[param] = value
        }
      }
    }
    dispatch(
      updateFilters({
        ...currentFiltersData,
        EffectiveOn: currentFiltersData?.EffectiveOn
          ? new Date(currentFiltersData?.EffectiveOn)
          : null,
      }),
    )
    dispatch(updateSort({ ...currentSortData }))
    dispatch(updatePagination({ ...currentPaginationData }))
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }
    const reqPayload: { [x: string]: any } = {
      ...tableFilters,
      EffectiveOn: tableFilters?.EffectiveOn ? formatDate(tableFilters?.EffectiveOn) : null,
      IsClone: tableFilters?.IsClone ? Number(tableFilters?.IsClone) : null,
      ...tableSort,
      ...tablePagination,
    }
    // Setting applied values to URL
    const params = new URLSearchParams()
    Object.keys(reqPayload).forEach((filterKey) => {
      if (reqPayload[filterKey]) {
        params.append(filterKey, reqPayload[filterKey])
      }
    })
    history({ search: params.toString() }, { replace: true })
    dispatch(
      fetchData(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reqPayload,
      ),
    )
  }, [
    JSON.stringify({
      ...tableFilters,
      ...tablePagination,
      ...tableSort,
    }),
    isInitialMount.current,
  ])

  const handleClearError = () => {
    dispatch(setError(''))
  }

  const handlePagination = (name: string) => (value: number) => {
    dispatch(
      updatePagination({
        ...tablePagination,
        [name]: value,
        ...(name === 'RecordsPerPage' && { PageNumber: 1 }),
      }),
    )
  }

  const handleToastExpire = () => {
    setToastStatus(false)
    setToastMsg('')
  }

  const totalItems = Number(configurationsData[0]?.MaxCount || 0)
  const isLoading = status === 'loading'

  return (
    <>
      {error && (
        <div className='alert-element'>
          <KiteAlert
            onClose={handleClearError}
            autoFocus={false}
            level='page'
            type='error'
            description={error}
            dismissible
          />
        </div>
      )}
      <CustomTable
        columns={columns}
        currentSortKey={tableSort?.SortBy}
        sortDirection={tableSort?.SortOrder}
        onSort={handleSort}
        data={configurationsData || []}
        spacing={spacing}
        loading={isLoading}
        currentPage={PageNumber}
        onPageChange={handlePagination('PageNumber')}
        itemsPerPage={RecordsPerPage}
        onItemsPerPageChange={handlePagination('RecordsPerPage')}
        totalItems={totalItems}
      />
      <CustomToast status={toastStatus} message={toastMsg} onExpire={handleToastExpire} />
    </>
  )
}

export default ConfigurationsTable
