import React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { LabelPositions, Sizes } from '../../../types'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePickerStyles.scss'

interface ICustomDatePicker extends ReactDatePickerProps {
  size?: Sizes
  labelPos: LabelPositions
  label: string
  value: any
  onChange: any
}

const CustomDateRangePicker = ({
  size = Sizes.Small,
  labelPos = LabelPositions.Top,
  label,
  value,
  onChange,
  name,
  ...rest
}: ICustomDatePicker) => {
  const [startDate = null, endDate = null] = value || []
  const handleChange = (date: [Date | null, Date | null]) => {
    onChange({ target: { name: name, value: date } })
  }
  const handleCalendarClose = () => {
    if (startDate && !endDate) {
      onChange({ target: { name: name, value: [startDate, startDate] } })
    }
  }
  return (
    <div className={`custom-form-control-date ${labelPos} ${size}`}>
      {label && <label>{label}</label>}
      <DatePicker
        {...rest}
        selected={startDate}
        onChange={handleChange}
        onCalendarClose={handleCalendarClose}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        monthsShown={2}
      />
    </div>
  )
}

export default CustomDateRangePicker
