export const appApiEndPoints: any = {
  // Filters API urls
  filterOptionsDataURL:
    `${process.env.REACT_APP_API_URL}/ui-utility-lookups-list`,
  // Batch Activity
  batchActivityDataURL:
    `${process.env.REACT_APP_API_URL}/ui-batch-activity-list`,
  // Audit Log
  auditLogItemsURL: `${process.env.REACT_APP_API_URL}/ui-audit-log-list`,
  auditLogItemURL: `${process.env.REACT_APP_API_URL}/ui-audit-log-item`,
  getPresignedURL:
    `${process.env.REACT_APP_API_URL}/ui-audit-log-document-upload`,
  downloadAttachmentURL:
    `${process.env.REACT_APP_API_URL}/ui-audit-log-document-download`,
  // Configurations
  configurationsDataURL:
    `${process.env.REACT_APP_API_URL}/ui-config-list`,
  configurationsURL: `${process.env.REACT_APP_API_URL}/ui-config-item`,
  exportConfigurationsDataURL:
    `${process.env.REACT_APP_API_URL}/ui-config-csv-download`,
  configurationVersionsURL:
    `${process.env.REACT_APP_API_URL}/ui-config-version-list`,
  configurationFilePresignedUrl: `${process.env.REACT_APP_API_URL}/ui-bulk-upload-file-with-presignedUrl`,
  // Bulk Config
  bulkConfigurationsValidateOrSubmitURL:
    `${process.env.REACT_APP_API_URL}/ui-config-bulk-upload-validate-submit`,
  bulkConfigEditChangeHistoryURL:
    `${process.env.REACT_APP_API_URL}/ui-bulk-upload-change-history-list`,
  // Source Details
  sourcesDataURL: `${process.env.REACT_APP_API_URL}/ui-sources-list`,
  sourcesUpdateIngestDaysURL:
    `${process.env.REACT_APP_API_URL}/ui-item-sources-overlay`,
  sourceAssociatedPathsURL:
    `${process.env.REACT_APP_API_URL}/ui-sources-list-associated-paths`,
  createEditSourceDetailsURL:
    `${process.env.REACT_APP_API_URL}/ui-sources-item`,
  // Schedules
  exportSchedulesDataURL:
    `${process.env.REACT_APP_API_URL}/ui-file-activity-export-list-headnet`,
  importSchedulesDataURL:
    `${process.env.REACT_APP_API_URL}/ui-file-activity-import-list-headnet`,
  exportScheduleContentDataURL:
    `${process.env.REACT_APP_API_URL}/ui-file-activity-export-list-spots`,
  importScheduleContentDataURL:
    `${process.env.REACT_APP_API_URL}/ui-file-activity-import-list-spots`,
  // Duplicate
  duplicateActivityDataURL:
    `${process.env.REACT_APP_API_URL}/ui-duplicate-list-file-activity`,
  duplicateSetupURL:
    `${process.env.REACT_APP_API_URL}/ui-duplicate-item`,
  activeZoneCodeDetailsURL:
    `${process.env.REACT_APP_API_URL}/ui-duplicate-list-active-zone-code`,
  duplicateFileSetupDataURL:
    `${process.env.REACT_APP_API_URL}/ui-duplicate-list-setup`,
}
