import React from 'react'
import { KiteDynamicHint, KiteTextInput } from '@kite/react-kite'
import { RKTextInputType } from '@kite/react-kite/dist/text-input/KiteTextInput/KiteTextInput'
import { LabelPositions, Sizes } from '../../../types'

import './TextInputStyles.scss'

interface ICustomTextInput {
  children?:
    | React.ReactElement<typeof KiteDynamicHint>
    | React.ReactElement<typeof KiteDynamicHint>[]
  className?: string
  disabled?: boolean
  dynamicHintId?: string | number
  errorId?: string
  errorMessage?: string | number
  id?: string
  inputRef?: React.RefObject<any>
  label?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  onFocus?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any
  placeholder?: string
  tooltip?: string | string[] | React.ReactElement | React.ReactElement[]
  type?: RKTextInputType
  confirm?: boolean
  value?: any
  size: Sizes
  labelPos?: LabelPositions
  maxLength?: number
  readOnly?: boolean
}

const CustomTextInput = ({
  size,
  label,
  labelPos = LabelPositions.Top,
  value,
  maxLength,
  readOnly,
  ...rest
}: ICustomTextInput) => {
  return (
    <div className={`custom-form-control-input ${labelPos} ${size}`}>
      {label && (
        <label className={`${maxLength && 'show-max-length'}`}>
          <span>{label}</span>
          {maxLength && (
            <span className='max-char'>
              {value?.length}/{maxLength}
            </span>
          )}
        </label>
      )}
      <KiteTextInput className='input' readOnly={readOnly} {...rest} label='' value={value} />
    </div>
  )
}
export default CustomTextInput
