import React from 'react'

import './ProgressStyles.scss'

interface IProgressbarProps {
  label: string
}

const Progressbar = ({ label = '' }: IProgressbarProps) => {
  return (
    <div className='progress progress-striped active'>
      <div role='progressbar progress-striped' className='progress-bar'>
        <span>{label}</span>
      </div>
    </div>
  )
}
export default Progressbar
