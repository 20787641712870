import React, { useState } from 'react'
import { Spacing } from '../../../components/table/Table'
import { ILink } from '../../../types'
import { PATHS } from '../../../routes/Routes'
import PageHeader from '../../../components/pageHeader/PageHeader'
import TableViewType from '../../../components/tableViewType/TableViewType'
import ImportSchedulesFilters from './ImportSchedulesFilters'
import ImportSchedulesTable from './ImportSchedulesTable'

const ImportSchedules = () => {
  const [viewType, setViewType] = useState<Spacing>(Spacing.Default)
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Import Schedules',
      link: PATHS.SCHEDULES_EXPORT,
    },
  ]
  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader header='Import Schedules' navLinks={navLinks}></PageHeader>
        <div className='table-filters'>
          <div className='filters-container'>
            <ImportSchedulesFilters />
          </div>
          <div className='table-view-type'>
            <TableViewType viewType={viewType} setViewType={setViewType} />
          </div>
        </div>
        <ImportSchedulesTable spacing={viewType} />
      </div>
    </div>
  )
}
export default ImportSchedules
