import React, { useEffect } from 'react'
import { KiteAlert, KiteDialog } from '@kite/react-kite'
import { LabelPositions, Sizes } from '../../../../types'
import CustomDatePicker from '../../../../components/customFormControls/datePicker/DatePicker'
import { ISourcesData } from '../../../../redux/Sources/SourcesModels'
import { addDays, differenceInCalendarDays } from 'date-fns'
import CustomButton from '../../../../components/customFormControls/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setError, updateSourceIngestDays } from '../../../../redux/Sources/SourcesSlice'
import { RootState } from '../../../../redux/Store'

const MAX_INGEST_DAYS = 7

interface IExtendIngestDaysProps {
  data: ISourcesData | null
  onClose: () => void
  status: boolean
}

const ExtendIngestDays = ({ status, data, onClose }: IExtendIngestDaysProps) => {
  const dispatch = useDispatch()
  const [days, setDays] = React.useState<any>(null)

  const { updateError, updateStatus } = useSelector((state: RootState) => state.sourcesData)

  const ingestDays = data?.IngestDays || 0
  const fileType = data?.FileType
  const handleDialogClose = () => {
    onClose()
  }
  const handleExtendIngestDays = () => {
    const diffDays = differenceInCalendarDays(days, new Date())
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateSourceIngestDays({
        SourceID: data?.SourceID,
        Overlaydays: fileType === 'Schedules' ? diffDays + 1 : diffDays,
      }),
    )
  }
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event
    setDays(value)
  }
  let maxDate = null
  let minDate = null
  if (fileType === 'Schedules') {
    maxDate = addDays(new Date(), MAX_INGEST_DAYS - 1)
    minDate = addDays(new Date(), ingestDays)
  } else {
    minDate = addDays(new Date(), -MAX_INGEST_DAYS + 1)
    maxDate = addDays(new Date(), ingestDays - 1)
  }
  const isEligible = differenceInCalendarDays(maxDate, minDate)

  const handleClearError = () => {
    dispatch(setError(''))
  }

  useEffect(() => {
    setDays(null)
  }, [status])

  useEffect(() => {
    if (updateStatus === 'succeeded') {
      onClose()
    }
  }, [updateStatus])

  return (
    <div>
      <KiteDialog
        id='ingestDaysDialog'
        className='custom-dialog'
        open={status}
        title='Extend Ingest Days'
        onClose={handleDialogClose}
        onPrimaryBtnClick={handleExtendIngestDays}
        primaryBtnLabel=''
      >
        {isEligible <= 0 ? (
          <KiteAlert type='info' level='page' description='Not eligible to extend ingest days' />
        ) : (
          <>
            <h6 className='kite-type-style--body'>Select days to extend ingest days</h6>
            <CustomDatePicker
              onChange={handleChange}
              labelPos={LabelPositions.Left}
              label=''
              name='ingestDays'
              value={days}
              size={Sizes.Large}
              inline={true}
              maxDate={maxDate}
              minDate={minDate}
            />
            {updateError && (
              <div className='alert-element'>
                <KiteAlert
                  onClose={handleClearError}
                  autoFocus={false}
                  level='page'
                  type='error'
                  description={updateError}
                  dismissible
                />
              </div>
            )}
            <div className='dialog-actions'>
              <CustomButton
                onClick={handleExtendIngestDays}
                size={Sizes.Large}
                label='Extend'
                disabled={isEligible <= 0}
                loading={updateStatus === 'loading'}
              ></CustomButton>
            </div>
          </>
        )}
      </KiteDialog>
    </div>
  )
}
export default ExtendIngestDays
