import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IBatchActivityData, IIBatchActivityDataState } from './BatchActivityModels'
import ApiService from '../../services/ApiService'
import { appApiEndPoints } from '../../constants/ApiEndPoints'

const initialState: IIBatchActivityDataState = {
  batchActivityData: [],
  status: 'idle',
  error: null,
  tableFilters: {
    FileType: 'Schedules',
  },
  tablePagination: {
    PageNumber: 1,
    RecordsPerPage: 25,
  },
  tableSort: {
    SortBy: null,
    SortOrder: null,
  },
}

export const fetchData = createAsyncThunk('batchActivity/fetchData', async (params) => {
  const response = await ApiService.getData(appApiEndPoints.batchActivityDataURL, params)
  return response.data
})

export const BatchActivitySlice = createSlice({
  name: 'batchActivityData',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action?.payload
    },
    updateFilters: (state, action) => {
      state.tableFilters = action?.payload
    },
    updatePagination: (state, action) => {
      state.tablePagination = action?.payload
    },
    updateSort: (state, action) => {
      state.tableSort = action?.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.batchActivityData = action?.payload as IBatchActivityData[]
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action?.error?.message || 'Failed to fetch batch activity data'
      })
  },
})
export const { setError, updateFilters, updatePagination, updateSort } = BatchActivitySlice.actions
export default BatchActivitySlice.reducer
