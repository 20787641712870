import CustomButton from '../../../../components/customFormControls/button/Button'
import { Sizes } from '../../../../types'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PATHS } from '../../../../routes/Routes'

const SourceDetailsActions = () => {
  const [searchParams] = useSearchParams()
  const sourceID = searchParams.get('SourceID')
  const fileType = searchParams.get('FileType')
  const navigate = useNavigate()

  const handleBatchActivity = () => {
    navigate(PATHS.BATCH_ACTIVITY + '?SourceID=' + sourceID + '&FileType=' + fileType)
  }

  return (
    <>
      <div className='action'>
        <CustomButton
          onClick={handleBatchActivity}
          size={Sizes.Medium}
          variant='secondary'
          icon='ki-dashboard'
          label='Batch Activity'
        ></CustomButton>
      </div>
    </>
  )
}
export default SourceDetailsActions
