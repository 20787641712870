import React from 'react'
import { Sizes } from '../../../../types'
import CustomButton from '../../../../components/customFormControls/button/Button'
import { AppDispatch, RootState } from '../../../../redux/Store'
import { useDispatch, useSelector } from 'react-redux'
import { exportConfigurationsData } from '../../../../redux/Configurations/ConfigurationsSlice'

const ConfigurationExport = () => {
  const dispatch: AppDispatch = useDispatch()
  const { exportStatus } = useSelector((state: RootState) => state.configurationsData)

  const handleExport = () => {
    dispatch(exportConfigurationsData())
  }

  return (
    <CustomButton
      onClick={handleExport}
      size={Sizes.Medium}
      variant='secondary'
      icon='ki-export'
      label='Export'
      loading={exportStatus === 'loading'}
    ></CustomButton>
  )
}
export default ConfigurationExport
