import React from 'react'
import { Navigate } from 'react-router-dom'
import { AuthStatus } from '../pages/Auth/AuthUtils'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { KiteProgressIndicator } from '@kite/react-kite'

const RequireAuth = ({ children, redirectTo, path }: any) => {
  const { authStatus } = useAuthenticator()
  if (authStatus === AuthStatus.configuring) {
    return <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />
  }
  if (authStatus === AuthStatus.authenticated) {
    return children
  }
  return <Navigate state={{ prevURL: path }} to={redirectTo} />
}

export default RequireAuth
