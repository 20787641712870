import React, { useEffect } from 'react'
import PageHeader from '../../components/pageHeader/PageHeader'
import { ILink, LabelPositions, Sizes } from '../../types'
import { PATHS } from '../../routes/Routes'
import CustomSelect from '../../components/customFormControls/select/Select'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../redux/Store'
import CustomTextInput from '../../components/customFormControls/textInput/TextInput'
import {
  KiteAlert,
  KiteDialog,
  KiteGrid,
  KiteGridCell,
  KiteProgressIndicator,
} from '@kite/react-kite'
import CustomDatePicker from '../../components/customFormControls/datePicker/DatePicker'
import CustomButton from '../../components/customFormControls/button/Button'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  FILE_PROGRESS_STATUS,
  IAuditLogAttachment,
  IAuditLogDetails,
  IAuditLogNetwork,
  IAuditLogPayload,
  IAuditLogZone,
  RELATED_LINKS_STATUS,
} from '../../redux/AuditLog/AuditLogModels'
import {
  createAuditLogItem,
  deleteAuditLogItem,
  getAuditLogItemDetails,
  resetAuditLogDetails,
  updateAuditLogItem,
} from '../../redux/AuditLog/AuditLogSlice'
import CustomAutoComplete from '../../components/customFormControls/autoComplete/AutoComplete'
import { v4 as uuidv4 } from 'uuid'

import './AuditLogStyles.scss'
import AuditLogRelatedLinks from './AuditLogLinks'
import AuditLogAttachments from './Attachments/AuditLogAttachments'
import { formatStringToIso } from '../../utils/utils'

const auditLogReqFields: Array<{ label: string; key: keyof IAuditLogPayload }> = [
  {
    label: 'Start Time',
    key: 'StartTime',
  },
  {
    label: 'TimeZone',
    key: 'TimeZoneID',
  },
  {
    label: 'Networks',
    key: 'Networks',
  },
  {
    label: 'Zones',
    key: 'Zones',
  },
  {
    label: 'MSM Instance',
    key: 'MsmInstanceId',
  },
  {
    label: 'PID',
    key: 'Pid',
  },
]

const AuditLogDetails = ({ type }: { type: string }) => {
  const isCreateMode = type === 'add'
  const isEditMode = type === 'edit'
  const isDeleteMode = type === 'delete'
  const navLinks: ILink[] = [
    {
      label: 'Batch Activity',
      link: PATHS.BATCH_ACTIVITY,
    },
    {
      label: 'Audit Log',
      link: PATHS.AUDIT_LOG,
    },
    ...(isCreateMode
      ? [
          {
            label: 'Add Audit Log',
            link: PATHS.CREATE_AUDIT_LOG,
          },
        ]
      : []),
    ...(isEditMode
      ? [
          {
            label: 'Edit Audit Log',
            link: PATHS.EDIT_AUDIT_LOG,
          },
        ]
      : []),
    ...(isDeleteMode
      ? [
          {
            label: 'Delete Audit Log',
            link: PATHS.DELETE_AUDIT_LOG,
          },
        ]
      : []),
  ]
  const { auditLogId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params: any = {}
  const paramEntries: any = searchParams.entries()
  for (const entry of paramEntries) {
    const [param, value] = entry
    params[param] = value
  }
  const dispatch: AppDispatch = useDispatch()
  const { filtersData, status: filtersStatus } = useSelector(
    (state: RootState) => state.filtersData,
  )
  const auditLogFieldsObject = auditLogReqFields.reduce(
    (obj: any, item) => ((obj[item.key] = ''), obj),
    {},
  )
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [dialogMessage, setDialogMessage] = React.useState('')
  const [auditLogData, setAuditLogData] = React.useState<IAuditLogDetails>({} as IAuditLogDetails)
  const [auditLogDataError, setAuditLogDataError] = React.useState(auditLogFieldsObject)

  const {
    auditLogModifyStatus,
    auditLogDetailsStatus,
    auditLogDetailsError,
    selectedAuditLogDetails,
  } = useSelector((state: RootState) => state.auditLogData)

  const handleChange = (event: any) => {
    const name = event?.target?.name
    let value = event?.target?.value || ''
    if (name === 'MsmInstanceId' || name === 'TimeZoneID') {
      value = value ? Number(value) : null
    }
    if (name == 'Notes' && value.length > 10000) {
      value = value.slice(0, 10000)
    }
    setAuditLogData({ ...auditLogData, [name]: value })
    setAuditLogDataError({ ...auditLogDataError, [name]: '' })
  }
  const handleAttachmentChange = (attachments: IAuditLogAttachment[]) => {
    setAuditLogData({ ...auditLogData, Attachments: attachments })
    setAuditLogDataError({ ...auditLogDataError, Attachments: '' })
  }

  const validateFields = () => {
    let valid = true
    const error = { ...auditLogDataError }
    auditLogReqFields.map((field) => {
      if (!auditLogData[field?.key]) {
        error[field?.key] = 'Please enter ' + field?.label
        valid = false
      }
      if (auditLogData?.Networks && !auditLogData?.Networks?.length) {
        error.Networks = 'Please enter Networks'
        valid = false
      }
      if (auditLogData?.Zones && !auditLogData?.Zones?.length) {
        error.Zones = 'Please enter Zones'
        valid = false
      }
      if (auditLogData?.Links?.length) {
        let linksValid = true
        ;(auditLogData.Links || []).forEach((link) => {
          linksValid = !!link?.Address
        })
        if (!linksValid) {
          valid = false
          error.Links = 'Please enter link address or remove link'
        }
      }
      if (auditLogData?.Attachments?.length) {
        let attachmentsValid = true
        ;(auditLogData.Attachments || []).forEach((attachment) => {
          if (
            !(
              attachment?.Status === FILE_PROGRESS_STATUS.SUCCESS ||
              attachment?.Status === FILE_PROGRESS_STATUS.OLD
            )
          ) {
            attachmentsValid = false
          }
        })
        if (!attachmentsValid) {
          valid = false
          error.Attachments = 'Please wait for attachment to finish upload or remove attachment'
        }
      }
    })
    return { valid, error }
  }

  const handleCreateOrUpdateAuditLog = () => {
    const { valid, error } = validateFields()
    if (valid) {
      const networksData: IAuditLogNetwork[] = constructNetworksData(
        auditLogData,
        selectedAuditLogDetails,
      )
      const zonesData: IAuditLogZone[] = constructZonesData(auditLogData, selectedAuditLogDetails)
      const auditLogPayload: IAuditLogPayload = {
        ...auditLogData,
        Status: auditLogData?.EndTime ? 'Resolved' : 'Active',
        StartTime: auditLogData.StartTime ? formatStringToIso(auditLogData.StartTime) : null,
        EndTime: auditLogData.EndTime ? formatStringToIso(auditLogData.EndTime) : null,
        Attachments: (auditLogData?.Attachments || []).map((attachment) => {
          return {
            AuditLogItemId: attachment?.AuditLogItemId,
            IsDeleted: attachment?.IsDeleted,
            DocName: attachment?.DocName,
          }
        }),
        Links: (auditLogData?.Links || []).map((attachment) => {
          return {
            AuditLogItemId: attachment?.AuditLogItemId,
            IsDeleted: attachment?.IsDeleted,
            Address: attachment?.Address,
          }
        }),
        Networks: networksData,
        Zones: zonesData,
      }
      if (isCreateMode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(createAuditLogItem(auditLogPayload))
      }
      if (isEditMode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updateAuditLogItem(auditLogPayload))
      }
    } else {
      setAuditLogDataError({ ...error })
    }
  }
  const constructNetworksData = (
    auditLogData: IAuditLogDetails,
    selectedAuditLogDetails: IAuditLogPayload | null,
  ) => {
    const existingNetworks = selectedAuditLogDetails?.Networks
      ? [...selectedAuditLogDetails.Networks]
      : []
    const newNetworks = auditLogData.Networks.map((network) => {
      const existedNetworkIndex = existingNetworks.findIndex(
        (existNetwork) => existNetwork.NetCode === network,
      )
      if (existedNetworkIndex > -1) {
        const existedNetwork = existingNetworks[existedNetworkIndex]
        existingNetworks.splice(existedNetworkIndex, 1)
        return {
          AuditLogNetworkId: existedNetwork?.AuditLogNetworkId,
          NetCode: network,
          IsDeleted: 0,
        }
      } else {
        return {
          AuditLogNetworkId: uuidv4(),
          NetCode: network,
          IsDeleted: 0,
        }
      }
    })
    existingNetworks.forEach((existNetwork) => {
      newNetworks.push({ ...existNetwork, IsDeleted: 1 })
    })
    return newNetworks
  }
  const constructZonesData = (
    auditLogData: IAuditLogDetails,
    selectedAuditLogDetails: IAuditLogPayload | null,
  ) => {
    const existingZones = selectedAuditLogDetails?.Zones ? [...selectedAuditLogDetails.Zones] : []
    const newZones: IAuditLogZone[] = auditLogData.Zones.map((zone) => {
      const existedZoneIndex = existingZones.findIndex((existZone) => existZone.ZoneCode === zone)
      if (existedZoneIndex > -1) {
        const existedZone = existingZones[existedZoneIndex]
        existingZones.splice(existedZoneIndex, 1)
        return {
          AuditLogZoneId: existedZone?.AuditLogZoneId,
          ZoneCode: zone,
          IsDeleted: 0,
        }
      } else {
        return {
          AuditLogZoneId: uuidv4(),
          ZoneCode: zone,
          IsDeleted: 0,
        }
      }
    })
    existingZones.forEach((existZone) => {
      newZones.push({ ...existZone, IsDeleted: 1 })
    })
    return newZones
  }
  const handleDeleteAuditLog = () => {
    const params = {
      AuditLogId: auditLogId,
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(deleteAuditLogItem(params))
  }

  const handleDialogClose = () => {
    setDialogStatus(false)
    setDialogMessage('')
    setTimeout(() => {
      navigate(PATHS.AUDIT_LOG)
    })
  }

  useEffect(() => {
    if (selectedAuditLogDetails) {
      const data: IAuditLogPayload = selectedAuditLogDetails
      setAuditLogData({
        ...data,
        AuditLogId: data?.AuditLogId || uuidv4(),
        StartTime: data?.StartTime ? new Date(data?.StartTime + ' UTC') : null,
        EndTime: data?.EndTime ? new Date(data?.EndTime + ' UTC') : null,
        Networks: (data?.Networks || []).map((network) => network?.NetCode),
        Zones: (data?.Zones || []).map((zone) => zone?.ZoneCode),
        Attachments: (data?.Attachments || []).map((attachment) => {
          return {
            ...attachment,
            Status: FILE_PROGRESS_STATUS.OLD,
          }
        }),
        Links: (data?.Links || []).map((link) => {
          return {
            ...link,
            Status: RELATED_LINKS_STATUS.OLD,
          }
        }),
      })
    } else {
      setAuditLogData({ ...auditLogData, AuditLogId: uuidv4() })
    }
  }, [selectedAuditLogDetails])

  useEffect(() => {
    if (auditLogId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(getAuditLogItemDetails({ AuditLogId: auditLogId }))
    }
  }, [auditLogId])

  useEffect(() => {
    if (auditLogModifyStatus === 'succeeded') {
      setDialogStatus(true)
      let message = 'Created new audit log successfully'
      if (isEditMode) {
        message = 'Updated audit log details successfully'
      }
      if (isDeleteMode) {
        message = 'Deleted audit log successfully'
      }
      setDialogMessage(message)
    }
  }, [auditLogModifyStatus])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(resetAuditLogDetails())
    }
  }, [])

  const isLoading =
    filtersStatus === 'loading' ||
    auditLogDetailsStatus === 'loading' ||
    auditLogModifyStatus === 'loading'

  const isArchived = auditLogData?.IsArchived

  return (
    <div className='page-container'>
      <div className='page-content'>
        <PageHeader
          header={
            isCreateMode ? 'Add Audit Log' : isEditMode ? 'Edit Audit Log' : 'Delete Audit Log'
          }
          navLinks={navLinks}
        ></PageHeader>
        <div className='setup-container'>
          <div className='setup-content-container'>
            <div className='section'>
              <KiteGrid>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='Pid'
                    size={Sizes.Small}
                    label='User PID'
                    onChange={handleChange}
                    value={auditLogData?.Pid || ''}
                    errorMessage={auditLogDataError?.Pid}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomDatePicker
                    onChange={handleChange}
                    labelPos={LabelPositions.Top}
                    label='Start Time'
                    name='StartTime'
                    value={auditLogData.StartTime}
                    dateFormat='MM/dd/yyyy h:mm aa'
                    timeInputLabel='Time:'
                    showTimeInput
                    errorMessage={auditLogDataError?.StartTime}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomDatePicker
                    onChange={handleChange}
                    labelPos={LabelPositions.Top}
                    label='End Time'
                    name='EndTime'
                    minDate={auditLogData.StartTime}
                    value={auditLogData.EndTime}
                    dateFormat='MM/dd/yyyy h:mm aa'
                    timeInputLabel='Time:'
                    showTimeInput
                    errorMessage={auditLogDataError?.EndTime}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='TimeZoneID'
                    name='TimeZoneID'
                    size={Sizes.Small}
                    label='Time Zone'
                    value={auditLogData?.TimeZoneID}
                    onChange={handleChange}
                    errorMessage={auditLogDataError?.TimeZoneID}
                  >
                    {(filtersData?.AuditTimeZones || []).map((option, index) => (
                      <option key={index} value={option?.IndexValue}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomSelect
                    id='MsmInstanceId'
                    name='MsmInstanceId'
                    size={Sizes.Small}
                    label='MSM Instance'
                    value={auditLogData?.MsmInstanceId}
                    onChange={handleChange}
                    errorMessage={auditLogDataError?.MsmInstanceId}
                  >
                    {(filtersData?.MSMInstance || []).map((option, index) => (
                      <option key={index} value={option?.IndexValue}>
                        {option?.Value}
                      </option>
                    ))}
                  </CustomSelect>
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomAutoComplete
                    name='Networks'
                    label={
                      <div>
                        Networks
                        <div className='help-text'>
                          (You can select up to 15 networks at the same time)
                        </div>
                      </div>
                    }
                    value={auditLogData?.Networks || []}
                    onChange={handleChange}
                    valueLength={2}
                    limitTags={15}
                    type='text'
                    errorMessage={auditLogDataError?.Networks}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomAutoComplete
                    name='Zones'
                    label={
                      <div>
                        Zones
                        <div className='help-text'>
                          ( You can select up to 2 zones at the same time)
                        </div>
                      </div>
                    }
                    value={auditLogData?.Zones || []}
                    onChange={handleChange}
                    valueLength={3}
                    limitTags={2}
                    type='text'
                    errorMessage={auditLogDataError?.Zones}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <AuditLogRelatedLinks
                    links={auditLogData?.Links || []}
                    onChange={handleChange}
                    error={auditLogDataError?.Links}
                    name='Links'
                    enableAdd={!isArchived}
                    enableRemove={!isArchived}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <AuditLogAttachments
                    auditLogId={auditLogData.AuditLogId}
                    attachments={auditLogData?.Attachments || []}
                    onChange={handleAttachmentChange}
                    error={auditLogDataError?.Attachments}
                    enableAdd={!isArchived}
                    enableRemove={!isArchived}
                  />
                </KiteGridCell>
                <KiteGridCell col={12}>
                  <CustomTextInput
                    name='Notes'
                    size={Sizes.Small}
                    label='Notes'
                    type='multiline'
                    onChange={handleChange}
                    maxLength={10000}
                    value={auditLogData?.Notes || ''}
                    errorMessage={auditLogDataError?.Notes}
                  />
                </KiteGridCell>
              </KiteGrid>
            </div>
            {auditLogDetailsError && (
              <div className='alert-element'>
                <KiteAlert
                  autoFocus={false}
                  level='page'
                  type='error'
                  description={auditLogDetailsError}
                />
              </div>
            )}
            {isArchived ? (
              <div className='alert-element'>
                <KiteAlert
                  autoFocus={false}
                  level='page'
                  type='info'
                  description=' This audit log item archived, modifications not allowed'
                />
              </div>
            ) : (
              <div className='setup-actions'>
                {isEditMode && (
                  <CustomButton
                    label='Save Audit Log'
                    onClick={handleCreateOrUpdateAuditLog}
                    loading={isLoading}
                  ></CustomButton>
                )}
                {isCreateMode && (
                  <CustomButton
                    label='Create Audit Log'
                    onClick={handleCreateOrUpdateAuditLog}
                    loading={isLoading}
                  ></CustomButton>
                )}
                {isDeleteMode && (
                  <CustomButton
                    label='Delete'
                    btnType='danger'
                    onClick={handleDeleteAuditLog}
                    loading={isLoading}
                  ></CustomButton>
                )}
              </div>
            )}
          </div>
        </div>
        <KiteDialog
          id='confirmDialog'
          className='confirm-dialog'
          open={dialogStatus}
          title='Success'
          onClose={handleDialogClose}
          primaryBtnLabel='Ok'
          onPrimaryBtnClick={handleDialogClose}
        >
          <div className='kite-dialog__content-group'>{dialogMessage}</div>
        </KiteDialog>
        {isLoading && <KiteProgressIndicator useOverlay id='kp1' message='' title='Loading' />}
      </div>
    </div>
  )
}
export default AuditLogDetails
